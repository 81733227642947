import { createStructuredSelector } from 'reselect'

import {
  ARTICLE_LIST,
  ARTICLE_LIST_SUCCESS,
  ARTICLE_LIST_ERROR,
  ARTICLE_PAGE_SET,
  ARTICLE_SEARCH_SET,
  ARTICLE_SEARCH_CHANGE,
  ARTICLE_SEARCH_ARTICLE_LIST,
  ARTICLE_SEARCH_ARTICLE_LIST_SUCCESS,
  ARTICLE_SEARCH_ARTICLE_LIST_ERROR,
  ARTICLE_SEARCH_PROCESS_LIST,
  ARTICLE_SEARCH_PROCESS_LIST_SUCCESS,
  ARTICLE_SEARCH_PROCESS_LIST_ERROR,
  ARTICLE_SEARCH_MEDICAMENT_LIST,
  ARTICLE_SEARCH_MEDICAMENT_LIST_SUCCESS,
  ARTICLE_SEARCH_MEDICAMENT_LIST_ERROR,
  ARTICLE_SEARCH_GEO_REGION_LIST,
  ARTICLE_SEARCH_GEO_REGION_LIST_SUCCESS,
  ARTICLE_SEARCH_GEO_REGION_LIST_ERROR,
  ARTICLE_ADD_SET,
  ARTICLE_ADD_MODAL_SET,
  ARTICLE_ADD,
  ARTICLE_ADD_SUCCESS,
  ARTICLE_ADD_ERROR,
  ARTICLE_ADD_CHANGE,
  ARTICLE_DETAIL,
  ARTICLE_DETAIL_SUCCESS,
  ARTICLE_DETAIL_ERROR,
  ARTICLE_VIEW_MODAL_SET,
  ARTICLE_EDIT_CHANGE,
  ARTICLE_EDIT_MODAL_SET,
  ARTICLE_UPDATE,
  ARTICLE_UPDATE_SUCCESS,
  ARTICLE_UPDATE_ERROR,
  ARTICLE_VIEW_TAB_SET,
  ARTICLE_SEARCH_ARTICLE_SET
} from '../../actions';

export const NAME = 'article';

export const ArticleList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: ARTICLE_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const ArticleListSuccess = (data) => ({
  type: ARTICLE_LIST_SUCCESS,
  payload: data
});

export const ArticleListError = (error) => ({
  type: ARTICLE_LIST_ERROR,
  payload: error
});

export const ArticleSearchArticleSet = (data) => ({
  type: ARTICLE_SEARCH_ARTICLE_SET,
  payload: data
});

export const ArticlePageSet = (data) => ({
  type: ARTICLE_PAGE_SET,
  payload: data
});

export const ArticleSearchSet = (data) => ({
  type: ARTICLE_SEARCH_SET,
  payload: data
});

export const ArticleSearchChangeSet = (data) => ({
  type: ARTICLE_SEARCH_CHANGE,
  payload: data
});

export const ArticleSearchArticleList = (data) => ({
  type: ARTICLE_SEARCH_ARTICLE_LIST,
  payload: data
});

export const ArticleSearchArticleListSuccess = (message) => ({
  type: ARTICLE_SEARCH_ARTICLE_LIST_SUCCESS,
  payload: message
});

export const ArticleSearchArticleListError = (error) => ({
  type: ARTICLE_SEARCH_ARTICLE_LIST_ERROR,
  payload: error
}); 

export const ArticleSearchProcessList = (data) => ({
  type: ARTICLE_SEARCH_PROCESS_LIST,
  payload: data
});

export const ArticleSearchProcessListSuccess = (message) => ({
  type: ARTICLE_SEARCH_PROCESS_LIST_SUCCESS,
  payload: message
});

export const ArticleSearchProcessListError = (error) => ({
  type: ARTICLE_SEARCH_PROCESS_LIST_ERROR,
  payload: error
}); 

export const ArticleSearchMedicamentList = (data) => ({
  type: ARTICLE_SEARCH_MEDICAMENT_LIST,
  payload: data
});

export const ArticleSearchMedicamentListSuccess = (message) => ({
  type: ARTICLE_SEARCH_MEDICAMENT_LIST_SUCCESS,
  payload: message
});

export const ArticleSearchMedicamentListError = (error) => ({
  type: ARTICLE_SEARCH_MEDICAMENT_LIST_ERROR,
  payload: error
}); 
export const ArticleSearchGeoRegionList = (data) => ({
  type: ARTICLE_SEARCH_GEO_REGION_LIST,
  payload: data
});

export const ArticleSearchGeoRegionListSuccess = (message) => ({
  type: ARTICLE_SEARCH_GEO_REGION_LIST_SUCCESS,
  payload: message
});

export const ArticleSearchGeoRegionListError = (error) => ({
  type: ARTICLE_SEARCH_GEO_REGION_LIST_ERROR,
  payload: error
}); 


export const ArticleAddChangeSet = (data) => ({
  type: ARTICLE_ADD_CHANGE,
  payload: data
});

export const ArticleAddSet = (data) => ({
  type: ARTICLE_ADD_SET,
  payload: data
});

export const ArticleAddModalSet = (data) => ({
  type: ARTICLE_ADD_MODAL_SET,
  payload: data
});

export const ArticleAdd = (data) => ({
  type: ARTICLE_ADD,
  payload: data
});

export const ArticleAddSuccess = (message) => ({
  type: ARTICLE_ADD_SUCCESS,
  payload: message
});

export const ArticleAddError = (error) => ({
  type: ARTICLE_ADD_ERROR,
  payload: error
});

export const ArticleDetail = (data) => ({
  type: ARTICLE_DETAIL,
  payload: data
});

export const ArticleDetailSuccess = (data) => ({
  type: ARTICLE_DETAIL_SUCCESS,
  payload: data
});

export const ArticleDetailError = (error) => ({
  type: ARTICLE_DETAIL_ERROR,
  payload: error
});

export const ArticleViewModalSet = (data) => ({
  type: ARTICLE_VIEW_MODAL_SET,
  payload: data
});

export const ArticleEditModalSet = (data) => ({
  type: ARTICLE_EDIT_MODAL_SET,
  payload: data
});

export const ArticleEditChangeSet = (data) => ({
  type: ARTICLE_EDIT_CHANGE,
  payload: data
});

export const ArticleUpdate = (data) => ({
  type: ARTICLE_UPDATE,
  payload: data
});

export const ArticleUpdateSuccess = (message) => ({
  type: ARTICLE_UPDATE_SUCCESS,
  payload: message.message
});

export const ArticleUpdateError = (error) => ({
  type: ARTICLE_UPDATE_ERROR,
  payload: error
});

export const ArticleViewTabSet = (data) => ({
  type: ARTICLE_VIEW_TAB_SET,
  payload: data
});

const article = (state) => state[NAME];

export const ArticleSelector = createStructuredSelector({
  article
})
