import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  QUALITY_SOURCE_LIST,
  QUALITY_SOURCE_SEARCH_REQUEST_LIST,
  QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST,
  QUALITY_SOURCE_ADD,
  QUALITY_SOURCE_DETAIL,
  QUALITY_SOURCE_UPDATE,
} from '../actions';
import {
  QualitySourceListSuccess,
  QualitySourceListError,
  QualitySourceSearchRequestListSuccess,
  QualitySourceSearchRequestListError,
  QualitySourceSearchGeoCountryListSuccess,
  QualitySourceSearchGeoCountryListError,
  QualitySourceAddSuccess,
  QualitySourceAddError,
  QualitySourceDetailSuccess,
  QualitySourceDetailError,
  QualitySourceUpdateSuccess,
  QualitySourceUpdateError
} from '../actions';
 
export function* watchQualitySourceList() {
  yield takeEvery(QUALITY_SOURCE_LIST, onQualitySourceList);
}

export function* watchQualitySourceSearchRequestList() {
  yield takeEvery(QUALITY_SOURCE_SEARCH_REQUEST_LIST, onQualitySourceSearchRequestList);
}

export function* watchQualitySourceSearchGeoCountryList() {
  yield takeEvery(QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST, onQualitySourceSearchGeoCountryList);
}

export function* watchQualitySourceAdd() {
  yield takeEvery(QUALITY_SOURCE_ADD, onQualitySourceAdd);
}

export function* watchQualitySourceDetail() {
  yield takeEvery(QUALITY_SOURCE_DETAIL, onQualitySourceDetail);
}

export function* watchQualitySourceUpdate() {
  yield takeEvery(QUALITY_SOURCE_UPDATE, onQualitySourceUpdate);
}

const onQualitySourceListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_source/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onQualitySourceUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_source/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onQualitySourceSearchRequestListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onQualitySourceSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onQualitySourceAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_source/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onQualitySourceDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_source/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onQualitySourceList(data) {
  try {
    const response = yield call(onQualitySourceListAsync, data.payload);
    yield put(QualitySourceListSuccess(response.data));
  } catch (error) {
    yield put(QualitySourceListError(error));
  }
}

function* onQualitySourceSearchRequestList(data) {
  try {
    console.log('onQualitySourceSearchRequestList',data.payload)
    const response = yield call(onQualitySourceSearchRequestListAsync, data.payload);
    yield put(QualitySourceSearchRequestListSuccess(response.data));
  } catch (error) {
    yield put(QualitySourceSearchRequestListError(error));
  }
}

function* onQualitySourceSearchGeoCountryList(data) {
  try {
    const response = yield call(onQualitySourceSearchGeoCountryListAsync, data.payload);
    yield put(QualitySourceSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(QualitySourceSearchGeoCountryListError(error));
  }
}

function* onQualitySourceAdd(data) {
  try {
      const response = yield call(onQualitySourceAddAsync, data);
      console.log(response)
      if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(QualitySourceAddSuccess(response.data));
  } catch (error) {
      yield put(QualitySourceAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onQualitySourceDetail(data) {
  try {
    const response = yield call(onQualitySourceDetailAsync, data.payload);
    yield put(QualitySourceDetailSuccess(response.data.data));
  } catch (error) {
    yield put(QualitySourceDetailError(error));
  }
}

function* onQualitySourceUpdate(data) {
  try {
      const response = yield call(onQualitySourceUpdateAsync, data.payload);
      if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(QualitySourceUpdateSuccess(response.data));
  } catch (error) {
      yield put(QualitySourceUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchQualitySourceList),
    fork(watchQualitySourceSearchRequestList),
    fork(watchQualitySourceSearchGeoCountryList),
    fork(watchQualitySourceAdd),
    fork(watchQualitySourceDetail),
    fork(watchQualitySourceUpdate),
  ]);
} 