import { createStructuredSelector } from 'reselect'

import {
  LOCATION_LIST,
  LOCATION_LIST_SUCCESS,
  LOCATION_LIST_ERROR,
  LOCATION_PAGE_SET,
  LOCATION_SEARCH_SET,
  LOCATION_SEARCH_CHANGE,
  LOCATION_SEARCH_LOCATION_LIST,
  LOCATION_SEARCH_LOCATION_LIST_SUCCESS,
  LOCATION_SEARCH_LOCATION_LIST_ERROR,
  LOCATION_SEARCH_PROCESS_LIST,
  LOCATION_SEARCH_PROCESS_LIST_SUCCESS,
  LOCATION_SEARCH_PROCESS_LIST_ERROR,
  LOCATION_SEARCH_MEDICAMENT_LIST,
  LOCATION_SEARCH_MEDICAMENT_LIST_SUCCESS,
  LOCATION_SEARCH_MEDICAMENT_LIST_ERROR,
  LOCATION_SEARCH_GEO_REGION_LIST,
  LOCATION_SEARCH_GEO_REGION_LIST_SUCCESS,
  LOCATION_SEARCH_GEO_REGION_LIST_ERROR,
  LOCATION_ADD_SET,
  LOCATION_ADD_MODAL_SET,
  LOCATION_ADD,
  LOCATION_ADD_SUCCESS,
  LOCATION_ADD_ERROR,
  LOCATION_ADD_CHANGE,
  LOCATION_DETAIL,
  LOCATION_DETAIL_SUCCESS,
  LOCATION_DETAIL_ERROR,
  LOCATION_VIEW_MODAL_SET,
  LOCATION_EDIT_CHANGE,
  LOCATION_EDIT_MODAL_SET,
  LOCATION_UPDATE,
  LOCATION_UPDATE_SUCCESS,
  LOCATION_UPDATE_ERROR,
  LOCATION_VIEW_TAB_SET,
  LOCATION_SEARCH_LOCATION_SET
} from '../../actions';

export const NAME = 'location';

export const LocationList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: LOCATION_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const LocationListSuccess = (data) => ({
  type: LOCATION_LIST_SUCCESS,
  payload: data
});

export const LocationListError = (error) => ({
  type: LOCATION_LIST_ERROR,
  payload: error
});

export const LocationSearchLocationSet = (data) => ({
  type: LOCATION_SEARCH_LOCATION_SET,
  payload: data
});

export const LocationPageSet = (data) => ({
  type: LOCATION_PAGE_SET,
  payload: data
});

export const LocationSearchSet = (data) => ({
  type: LOCATION_SEARCH_SET,
  payload: data
});

export const LocationSearchChangeSet = (data) => ({
  type: LOCATION_SEARCH_CHANGE,
  payload: data
});

export const LocationSearchLocationList = (data) => ({
  type: LOCATION_SEARCH_LOCATION_LIST,
  payload: data
});

export const LocationSearchLocationListSuccess = (message) => ({
  type: LOCATION_SEARCH_LOCATION_LIST_SUCCESS,
  payload: message
});

export const LocationSearchLocationListError = (error) => ({
  type: LOCATION_SEARCH_LOCATION_LIST_ERROR,
  payload: error
}); 

export const LocationSearchProcessList = (data) => ({
  type: LOCATION_SEARCH_PROCESS_LIST,
  payload: data
});

export const LocationSearchProcessListSuccess = (message) => ({
  type: LOCATION_SEARCH_PROCESS_LIST_SUCCESS,
  payload: message
});

export const LocationSearchProcessListError = (error) => ({
  type: LOCATION_SEARCH_PROCESS_LIST_ERROR,
  payload: error
}); 

export const LocationSearchMedicamentList = (data) => ({
  type: LOCATION_SEARCH_MEDICAMENT_LIST,
  payload: data
});

export const LocationSearchMedicamentListSuccess = (message) => ({
  type: LOCATION_SEARCH_MEDICAMENT_LIST_SUCCESS,
  payload: message
});

export const LocationSearchMedicamentListError = (error) => ({
  type: LOCATION_SEARCH_MEDICAMENT_LIST_ERROR,
  payload: error
}); 
export const LocationSearchGeoRegionList = (data) => ({
  type: LOCATION_SEARCH_GEO_REGION_LIST,
  payload: data
});

export const LocationSearchGeoRegionListSuccess = (message) => ({
  type: LOCATION_SEARCH_GEO_REGION_LIST_SUCCESS,
  payload: message
});

export const LocationSearchGeoRegionListError = (error) => ({
  type: LOCATION_SEARCH_GEO_REGION_LIST_ERROR,
  payload: error
}); 


export const LocationAddChangeSet = (data) => ({
  type: LOCATION_ADD_CHANGE,
  payload: data
});

export const LocationAddSet = (data) => ({
  type: LOCATION_ADD_SET,
  payload: data
});

export const LocationAddModalSet = (data) => ({
  type: LOCATION_ADD_MODAL_SET,
  payload: data
});

export const LocationAdd = (data) => ({
  type: LOCATION_ADD,
  payload: data
});

export const LocationAddSuccess = (message) => ({
  type: LOCATION_ADD_SUCCESS,
  payload: message
});

export const LocationAddError = (error) => ({
  type: LOCATION_ADD_ERROR,
  payload: error
});

export const LocationDetail = (data) => ({
  type: LOCATION_DETAIL,
  payload: data
});

export const LocationDetailSuccess = (data) => ({
  type: LOCATION_DETAIL_SUCCESS,
  payload: data
});

export const LocationDetailError = (error) => ({
  type: LOCATION_DETAIL_ERROR,
  payload: error
});

export const LocationViewModalSet = (data) => ({
  type: LOCATION_VIEW_MODAL_SET,
  payload: data
});

export const LocationEditModalSet = (data) => ({
  type: LOCATION_EDIT_MODAL_SET,
  payload: data
});

export const LocationEditChangeSet = (data) => ({
  type: LOCATION_EDIT_CHANGE,
  payload: data
});

export const LocationUpdate = (data) => ({
  type: LOCATION_UPDATE,
  payload: data
});

export const LocationUpdateSuccess = (message) => ({
  type: LOCATION_UPDATE_SUCCESS,
  payload: message.message
});

export const LocationUpdateError = (error) => ({
  type: LOCATION_UPDATE_ERROR,
  payload: error
});

export const LocationViewTabSet = (data) => ({
  type: LOCATION_VIEW_TAB_SET,
  payload: data
});

const location = (state) => state[NAME];

export const LocationSelector = createStructuredSelector({
  location
})
