import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  QUALITY_AUTOREVIEW_LIST,
  QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST,
  QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST,
  QUALITY_AUTOREVIEW_SEARCH_TAG_LIST,
  QUALITY_AUTOREVIEW_ADD,
  QUALITY_AUTOREVIEW_VIEW,
  QUALITY_AUTOREVIEW_UPDATE,
} from '../actions';
import {
  QualityAutoReviewListSuccess,
  QualityAutoReviewListError,
  QualityAutoReviewSearchQualitySourceListSuccess,
  QualityAutoReviewSearchQualitySourceListError,
  QualityAutoReviewSearchCode22ListSuccess,
  QualityAutoReviewSearchCode22ListError,
  QualityAutoReviewSearchTagListSuccess,
  QualityAutoReviewSearchTagListError,
  QualityAutoReviewAddSuccess,
  QualityAutoReviewAddError,
  QualityAutoReviewViewSuccess,
  QualityAutoReviewViewError,
  QualityAutoReviewUpdateSuccess,
  QualityAutoReviewUpdateError
} from '../actions';
 
export function* watchQualityAutoReviewList() {
  yield takeEvery(QUALITY_AUTOREVIEW_LIST, onQualityAutoReviewList);
}

export function* watchQualityAutoReviewSearchQualitySourceList() {
  yield takeEvery(QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST, onQualityAutoReviewSearchQualitySourceList);
}

export function* watchQualityAutoReviewSearchCode22List() {
  yield takeEvery(QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST, onQualityAutoReviewSearchCode22List);
}

export function* watchQualityAutoReviewSearchTagList() {
  yield takeEvery(QUALITY_AUTOREVIEW_SEARCH_TAG_LIST, onQualityAutoReviewSearchTagList);
}

export function* watchQualityAutoReviewAdd() {
  yield takeEvery(QUALITY_AUTOREVIEW_ADD, onQualityAutoReviewAdd);
}

export function* watchQualityAutoReviewView() {
  yield takeEvery(QUALITY_AUTOREVIEW_VIEW, onQualityAutoReviewView);
}

export function* watchQualityAutoReviewUpdate() {
  yield takeEvery(QUALITY_AUTOREVIEW_UPDATE, onQualityAutoReviewUpdate);
}

const onQualityAutoReviewListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_autoreview/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onQualityAutoReviewUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_autoreview/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onQualityAutoReviewSearchQualitySourceListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/quality_source', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onQualityAutoReviewSearchCode22ListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/code22_product', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onQualityAutoReviewSearchTagListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/tag', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onQualityAutoReviewAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_autoreview/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onQualityAutoReviewViewAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_autoreview/view', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onQualityAutoReviewList(data) {
  try {
    const response = yield call(onQualityAutoReviewListAsync, data.payload);
    yield put(QualityAutoReviewListSuccess(response.data));
  } catch (error) {
    yield put(QualityAutoReviewListError(error));
  }
}

function* onQualityAutoReviewSearchQualitySourceList(data) {
  try {
    const response = yield call(onQualityAutoReviewSearchQualitySourceListAsync, data.payload);
    yield put(QualityAutoReviewSearchQualitySourceListSuccess(response.data));
  } catch (error) {
    yield put(QualityAutoReviewSearchQualitySourceListError(error));
  }
}

function* onQualityAutoReviewSearchCode22List(data) {
  try {
    const response = yield call(onQualityAutoReviewSearchCode22ListAsync, data.payload);
    yield put(QualityAutoReviewSearchCode22ListSuccess(response.data));
  } catch (error) {
    yield put(QualityAutoReviewSearchCode22ListError(error));
  }
}

function* onQualityAutoReviewSearchTagList(data) {
  try {
    const response = yield call(onQualityAutoReviewSearchTagListAsync, data.payload);
    yield put(QualityAutoReviewSearchTagListSuccess(response.data));
  } catch (error) {
    yield put(QualityAutoReviewSearchTagListError(error));
  }
}

function* onQualityAutoReviewAdd(data) {
  try {
      const response = yield call(onQualityAutoReviewAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(QualityAutoReviewAddSuccess(response.data));
  } catch (error) {
      yield put(QualityAutoReviewAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onQualityAutoReviewView(data) {
  try {
    const response = yield call(onQualityAutoReviewViewAsync, data.payload);
    yield put(QualityAutoReviewViewSuccess(response.data.data));
  } catch (error) {
    yield put(QualityAutoReviewViewError(error));
  }
}

function* onQualityAutoReviewUpdate(data) {
  try {
      const response = yield call(onQualityAutoReviewUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(QualityAutoReviewUpdateSuccess(response.data));
  } catch (error) {
      yield put(QualityAutoReviewUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchQualityAutoReviewList),
    fork(watchQualityAutoReviewSearchQualitySourceList),
    fork(watchQualityAutoReviewSearchCode22List),
    fork(watchQualityAutoReviewSearchTagList),
    fork(watchQualityAutoReviewAdd),
    fork(watchQualityAutoReviewView),
    fork(watchQualityAutoReviewUpdate),
  ]);
} 