/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const VALIDATE_EMAIL = 'VALIDATE EMAIL';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE EMAIL SUCCESS';
export const VALIDATE_EMAIL_ERROR = 'VALIDATE EMAIL ERROR';


/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* USER */
export const USER_ACTIONS_LIST = 'USER_ACTIONS_LIST';
export const USER_ACTIONS_LIST_SUCCESS = 'USER_ACTIONS_LIST_SUCCESS';
export const USER_ACTIONS_LIST_ERROR = 'USER_ACTIONS_LIST_ERROR';

/* UPLOAD FILE */
export const UPLOAD_SET = 'UPLOAD_SET';
export const UPLOAD_SET_SUCCESS = 'UPLOAD_SET_SUCCESS';
export const UPLOAD_SET_ERROR = 'UPLOAD_SET_ERROR';
export const UPLOAD_RESET = 'UPLOAD_RESET';

/* APP */
export const APP_LIST = 'APP_LIST';
export const APP_LIST_SUCCESS = 'APP_LIST_SUCCESS';
export const APP_LIST_ERROR = 'APP_LIST_ERROR';
export const APP_PAGE_SET = 'APP_PAGE_SET';
export const APP_SEARCH_SET = 'APP_SEARCH_SET';
export const APP_SEARCH_CHANGE = 'APP_SEARCH_CHANGE';
export const APP_SEARCH_APP_LIST = 'APP_SEARCH_APP_LIST';
export const APP_SEARCH_APP_LIST_SUCCESS = 'APP_SEARCH_APP_LIST_SUCCESS';
export const APP_SEARCH_APP_LIST_ERROR = 'APP_SEARCH_APP_LIST_ERROR';
export const APP_SEARCH_APP_TYPE_LIST = 'APP_SEARCH_APP_TYPE_LIST';
export const APP_SEARCH_APP_TYPE_LIST_SUCCESS = 'APP_SEARCH_APP_TYPE_LIST_SUCCESS';
export const APP_SEARCH_APP_TYPE_LIST_ERROR = 'APP_SEARCH_APP_TYPE_LIST_ERROR';
export const APP_ADD_SET = 'APP_ADD_SET';
export const APP_ADD_MODAL_SET = 'APP_ADD_MODAL_SET';
export const APP_ADD = 'APP_ADD';
export const APP_ADD_SUCCESS = 'APP_ADD_SUCCESS';
export const APP_ADD_ERROR = 'APP_ADD_ERROR';
export const APP_ADD_CHANGE = 'APP_ADD_CHANGE';
export const APP_VIEW = 'APP_VIEW';
export const APP_VIEW_SUCCESS = 'APP_VIEW_SUCCESS';
export const APP_VIEW_ERROR = 'APP_VIEW_ERROR';
export const APP_VIEW_MODAL_SET = 'APP_VIEW_MODAL_SET';
export const APP_VIEW_TAB_SET = 'APP_VIEW_TAB_SET';
export const APP_EDIT_MODAL_SET = 'APP_EDIT_MODAL_SET';
export const APP_EDIT_SET = 'APP_EDIT_SET';
export const APP_EDIT_CHANGE = 'APP_EDIT_CHANGE';
export const APP_UPDATE = 'APP_UPDATE';
export const APP_UPDATE_SUCCESS = 'APP_UPDATE_SUCCESS';
export const APP_UPDATE_ERROR = 'APP_UPDATE_ERROR';

/* QUALITY_EMAIL */
export const QUALITY_EMAIL_LIST = 'QUALITY_EMAIL_LIST';
export const QUALITY_EMAIL_LIST_SUCCESS = 'QUALITY_EMAIL_LIST_SUCCESS';
export const QUALITY_EMAIL_LIST_ERROR = 'QUALITY_EMAIL_LIST_ERROR';
export const QUALITY_EMAIL_PAGE_SET = 'QUALITY_EMAIL_PAGE_SET';
export const QUALITY_EMAIL_SEARCH_SET = 'QUALITY_EMAIL_SEARCH_SET';
export const QUALITY_EMAIL_SEARCH_CHANGE = 'QUALITY_EMAIL_SEARCH_CHANGE';
export const QUALITY_EMAIL_SEARCH_REQUEST_LIST = 'QUALITY_EMAIL_SEARCH_REQUEST_LIST';
export const QUALITY_EMAIL_SEARCH_REQUEST_LIST_SUCCESS = 'QUALITY_EMAIL_SEARCH_REQUEST_SUCCESS';
export const QUALITY_EMAIL_SEARCH_REQUEST_LIST_ERROR = 'QUALITY_EMAIL_SEARCH_REQUEST_LIST_ERROR';
export const QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST = 'QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST';
export const QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'QUALITY_EMAIL_SEARCH_GEO_COUNTRY_SUCCESS';
export const QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST_ERROR = 'QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const QUALITY_EMAIL_ADD_SET = 'QUALITY_EMAIL_ADD_SET';
export const QUALITY_EMAIL_ADD_MODAL_SET = 'QUALITY_EMAIL_ADD_MODAL_SET';
export const QUALITY_EMAIL_ADD = 'QUALITY_EMAIL_ADD';
export const QUALITY_EMAIL_ADD_SUCCESS = 'QUALITY_EMAIL_ADD_SUCCESS';
export const QUALITY_EMAIL_ADD_ERROR = 'QUALITY_EMAIL_ADD_ERROR';
export const QUALITY_EMAIL_ADD_CHANGE = 'QUALITY_EMAIL_ADD_CHANGE';
export const QUALITY_EMAIL_DETAIL = 'QUALITY_EMAIL_DETAIL';
export const QUALITY_EMAIL_DETAIL_SUCCESS = 'QUALITY_EMAIL_DETAIL_SUCCESS';
export const QUALITY_EMAIL_DETAIL_ERROR = 'QUALITY_EMAIL_DETAIL_ERROR';
export const QUALITY_EMAIL_VIEW_MODAL_SET = 'QUALITY_EMAIL_VIEW_MODAL_SET';
export const QUALITY_EMAIL_EDIT_MODAL_SET = 'QUALITY_EMAIL_EDIT_MODAL_SET';
export const QUALITY_EMAIL_EDIT_SET = 'QUALITY_EMAIL_EDIT_SET';
export const QUALITY_EMAIL_EDIT_CHANGE = 'QUALITY_EMAIL_EDIT_CHANGE';
export const QUALITY_EMAIL_UPDATE = 'QUALITY_EMAIL_UPDATE';
export const QUALITY_EMAIL_UPDATE_SUCCESS = 'QUALITY_EMAIL_UPDATE_SUCCESS';
export const QUALITY_EMAIL_UPDATE_ERROR = 'QUALITY_EMAIL_UPDATE_ERROR';
export const QUALITY_EMAIL_VIEW_TAB_SET = 'QUALITY_EMAIL_VIEW_TAB_SET';

/* QUALITY_SOURCE */
export const QUALITY_SOURCE_LIST = 'QUALITY_SOURCE_LIST';
export const QUALITY_SOURCE_LIST_SUCCESS = 'QUALITY_SOURCE_LIST_SUCCESS';
export const QUALITY_SOURCE_LIST_ERROR = 'QUALITY_SOURCE_LIST_ERROR';
export const QUALITY_SOURCE_PAGE_SET = 'QUALITY_SOURCE_PAGE_SET';
export const QUALITY_SOURCE_SEARCH_SET = 'QUALITY_SOURCE_SEARCH_SET';
export const QUALITY_SOURCE_SEARCH_CHANGE = 'QUALITY_SOURCE_SEARCH_CHANGE';
export const QUALITY_SOURCE_SEARCH_REQUEST_LIST = 'QUALITY_SOURCE_SEARCH_REQUEST_LIST';
export const QUALITY_SOURCE_SEARCH_REQUEST_LIST_SUCCESS = 'QUALITY_SOURCE_SEARCH_REQUEST_SUCCESS';
export const QUALITY_SOURCE_SEARCH_REQUEST_LIST_ERROR = 'QUALITY_SOURCE_SEARCH_REQUEST_LIST_ERROR';
export const QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST = 'QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST';
export const QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'QUALITY_SOURCE_SEARCH_GEO_COUNTRY_SUCCESS';
export const QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_ERROR = 'QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const QUALITY_SOURCE_ADD_SET = 'QUALITY_SOURCE_ADD_SET';
export const QUALITY_SOURCE_ADD_MODAL_SET = 'QUALITY_SOURCE_ADD_MODAL_SET';
export const QUALITY_SOURCE_ADD = 'QUALITY_SOURCE_ADD';
export const QUALITY_SOURCE_ADD_SUCCESS = 'QUALITY_SOURCE_ADD_SUCCESS';
export const QUALITY_SOURCE_ADD_ERROR = 'QUALITY_SOURCE_ADD_ERROR';
export const QUALITY_SOURCE_ADD_CHANGE = 'QUALITY_SOURCE_ADD_CHANGE';
export const QUALITY_SOURCE_DETAIL = 'QUALITY_SOURCE_DETAIL';
export const QUALITY_SOURCE_DETAIL_SUCCESS = 'QUALITY_SOURCE_DETAIL_SUCCESS';
export const QUALITY_SOURCE_DETAIL_ERROR = 'QUALITY_SOURCE_DETAIL_ERROR';
export const QUALITY_SOURCE_VIEW_MODAL_SET = 'QUALITY_SOURCE_VIEW_MODAL_SET';
export const QUALITY_SOURCE_EDIT_MODAL_SET = 'QUALITY_SOURCE_EDIT_MODAL_SET';
export const QUALITY_SOURCE_EDIT_SET = 'QUALITY_SOURCE_EDIT_SET';
export const QUALITY_SOURCE_EDIT_CHANGE = 'QUALITY_SOURCE_EDIT_CHANGE';
export const QUALITY_SOURCE_UPDATE = 'QUALITY_SOURCE_UPDATE';
export const QUALITY_SOURCE_UPDATE_SUCCESS = 'QUALITY_SOURCE_UPDATE_SUCCESS';
export const QUALITY_SOURCE_UPDATE_ERROR = 'QUALITY_SOURCE_UPDATE_ERROR';
export const QUALITY_SOURCE_VIEW_TAB_SET = 'QUALITY_SOURCE_VIEW_TAB_SET';

/* QUALITY_AUTOREVIEW */
export const QUALITY_AUTOREVIEW_LIST = 'QUALITY_AUTOREVIEW_LIST';
export const QUALITY_AUTOREVIEW_LIST_SUCCESS = 'QUALITY_AUTOREVIEW_LIST_SUCCESS';
export const QUALITY_AUTOREVIEW_LIST_ERROR = 'QUALITY_AUTOREVIEW_LIST_ERROR';
export const QUALITY_AUTOREVIEW_PAGE_SET = 'QUALITY_AUTOREVIEW_PAGE_SET';
export const QUALITY_AUTOREVIEW_SEARCH_SET = 'QUALITY_AUTOREVIEW_SEARCH_SET';
export const QUALITY_AUTOREVIEW_SEARCH_CHANGE = 'QUALITY_AUTOREVIEW_SEARCH_CHANGE';
export const QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST = 'QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST';
export const QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_SUCCESS = 'QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_SUCCESS';
export const QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_ERROR = 'QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_ERROR';
export const QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST = 'QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST';
export const QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST_SUCCESS = 'QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST_SUCCESS';
export const QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST_ERROR = 'QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST_ERROR';
export const QUALITY_AUTOREVIEW_SEARCH_TAG_LIST = 'QUALITY_AUTOREVIEW_SEARCH_TAG_LIST';
export const QUALITY_AUTOREVIEW_SEARCH_TAG_LIST_SUCCESS = 'QUALITY_AUTOREVIEW_SEARCH_TAG_LIST_SUCCESS';
export const QUALITY_AUTOREVIEW_SEARCH_TAG_LIST_ERROR = 'QUALITY_AUTOREVIEW_SEARCH_TAG_LIST_ERROR';
export const QUALITY_AUTOREVIEW_SEARCH_TAG_SET = 'QUALITY_AUTOREVIEW_SEARCH_TAG_SET';
export const QUALITY_AUTOREVIEW_ADD_SET = 'QUALITY_AUTOREVIEW_ADD_SET';
export const QUALITY_AUTOREVIEW_ADD_MODAL_SET = 'QUALITY_AUTOREVIEW_ADD_MODAL_SET';
export const QUALITY_AUTOREVIEW_ADD = 'QUALITY_AUTOREVIEW_ADD';
export const QUALITY_AUTOREVIEW_ADD_SUCCESS = 'QUALITY_AUTOREVIEW_ADD_SUCCESS';
export const QUALITY_AUTOREVIEW_ADD_ERROR = 'QUALITY_AUTOREVIEW_ADD_ERROR';
export const QUALITY_AUTOREVIEW_ADD_CHANGE = 'QUALITY_AUTOREVIEW_ADD_CHANGE';
export const QUALITY_AUTOREVIEW_VIEW = 'QUALITY_AUTOREVIEW_VIEW';
export const QUALITY_AUTOREVIEW_VIEW_SUCCESS = 'QUALITY_AUTOREVIEW_VIEW_SUCCESS';
export const QUALITY_AUTOREVIEW_VIEW_ERROR = 'QUALITY_AUTOREVIEW_VIEW_ERROR';
export const QUALITY_AUTOREVIEW_VIEW_MODAL_SET = 'QUALITY_AUTOREVIEW_VIEW_MODAL_SET';
export const QUALITY_AUTOREVIEW_VIEW_TAB_SET = 'QUALITY_AUTOREVIEW_VIEW_TAB_SET';
export const QUALITY_AUTOREVIEW_EDIT_MODAL_SET = 'QUALITY_AUTOREVIEW_EDIT_MODAL_SET';
export const QUALITY_AUTOREVIEW_EDIT_SET = 'QUALITY_AUTOREVIEW_EDIT_SET';
export const QUALITY_AUTOREVIEW_EDIT_CHANGE = 'QUALITY_AUTOREVIEW_EDIT_CHANGE';
export const QUALITY_AUTOREVIEW_UPDATE = 'QUALITY_AUTOREVIEW_UPDATE';
export const QUALITY_AUTOREVIEW_UPDATE_SUCCESS = 'QUALITY_AUTOREVIEW_UPDATE_SUCCESS';
export const QUALITY_AUTOREVIEW_UPDATE_ERROR = 'QUALITY_AUTOREVIEW_UPDATE_ERROR';

/* AUTOREVIEW */
export const AUTOREVIEW_LIST = 'AUTOREVIEW_LIST';
export const AUTOREVIEW_LIST_SUCCESS = 'AUTOREVIEW_LIST_SUCCESS';
export const AUTOREVIEW_LIST_ERROR = 'AUTOREVIEW_LIST_ERROR';
export const AUTOREVIEW_PAGE_SET = 'AUTOREVIEW_PAGE_SET';
export const AUTOREVIEW_SEARCH_SET = 'AUTOREVIEW_SEARCH_SET';
export const AUTOREVIEW_SEARCH_CHANGE = 'AUTOREVIEW_SEARCH_CHANGE';
export const AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST = 'AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST';
export const AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_SUCCESS = 'AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_SUCCESS';
export const AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_ERROR = 'AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_ERROR';
export const AUTOREVIEW_SEARCH_CODE22_LIST = 'AUTOREVIEW_SEARCH_CODE22_LIST';
export const AUTOREVIEW_SEARCH_CODE22_LIST_SUCCESS = 'AUTOREVIEW_SEARCH_CODE22_LIST_SUCCESS';
export const AUTOREVIEW_SEARCH_CODE22_LIST_ERROR = 'AUTOREVIEW_SEARCH_CODE22_LIST_ERROR';
export const AUTOREVIEW_SEARCH_TAG_LIST = 'AUTOREVIEW_SEARCH_TAG_LIST';
export const AUTOREVIEW_SEARCH_TAG_LIST_SUCCESS = 'AUTOREVIEW_SEARCH_TAG_LIST_SUCCESS';
export const AUTOREVIEW_SEARCH_TAG_LIST_ERROR = 'AUTOREVIEW_SEARCH_TAG_LIST_ERROR';
export const AUTOREVIEW_SEARCH_TAG_SET = 'AUTOREVIEW_SEARCH_TAG_SET';
export const AUTOREVIEW_ADD_SET = 'AUTOREVIEW_ADD_SET';
export const AUTOREVIEW_ADD_MODAL_SET = 'AUTOREVIEW_ADD_MODAL_SET';
export const AUTOREVIEW_ADD = 'AUTOREVIEW_ADD';
export const AUTOREVIEW_ADD_SUCCESS = 'AUTOREVIEW_ADD_SUCCESS';
export const AUTOREVIEW_ADD_ERROR = 'AUTOREVIEW_ADD_ERROR';
export const AUTOREVIEW_ADD_CHANGE = 'AUTOREVIEW_ADD_CHANGE';
export const AUTOREVIEW_VIEW = 'AUTOREVIEW_VIEW';
export const AUTOREVIEW_VIEW_SUCCESS = 'AUTOREVIEW_VIEW_SUCCESS';
export const AUTOREVIEW_VIEW_ERROR = 'AUTOREVIEW_VIEW_ERROR';
export const AUTOREVIEW_VIEW_MODAL_SET = 'AUTOREVIEW_VIEW_MODAL_SET';
export const AUTOREVIEW_VIEW_TAB_SET = 'AUTOREVIEW_VIEW_TAB_SET';
export const AUTOREVIEW_EDIT_MODAL_SET = 'AUTOREVIEW_EDIT_MODAL_SET';
export const AUTOREVIEW_EDIT_SET = 'AUTOREVIEW_EDIT_SET';
export const AUTOREVIEW_EDIT_CHANGE = 'AUTOREVIEW_EDIT_CHANGE';
export const AUTOREVIEW_UPDATE = 'AUTOREVIEW_UPDATE';
export const AUTOREVIEW_UPDATE_SUCCESS = 'AUTOREVIEW_UPDATE_SUCCESS';
export const AUTOREVIEW_UPDATE_ERROR = 'AUTOREVIEW_UPDATE_ERROR';

/* QUALITY_NOTIFICATION */
export const QUALITY_NOTIFICATION_LIST = 'QUALITY_NOTIFICATION_LIST';
export const QUALITY_NOTIFICATION_LIST_SUCCESS = 'QUALITY_NOTIFICATION_LIST_SUCCESS';
export const QUALITY_NOTIFICATION_LIST_ERROR = 'QUALITY_NOTIFICATION_LIST_ERROR';
export const QUALITY_NOTIFICATION_PAGE_SET = 'QUALITY_NOTIFICATION_PAGE_SET';
export const QUALITY_NOTIFICATION_SEARCH_SET = 'QUALITY_NOTIFICATION_SEARCH_SET';
export const QUALITY_NOTIFICATION_SEARCH_CHANGE = 'QUALITY_NOTIFICATION_SEARCH_CHANGE';
export const QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST = 'QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST';
export const QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST_SUCCESS = 'QUALITY_NOTIFICATION_SEARCH_REQUEST_SUCCESS';
export const QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST_ERROR = 'QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST_ERROR';
export const QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST = 'QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST';
export const QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_SUCCESS';
export const QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST_ERROR = 'QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const QUALITY_NOTIFICATION_ADD_SET = 'QUALITY_NOTIFICATION_ADD_SET';
export const QUALITY_NOTIFICATION_ADD_MODAL_SET = 'QUALITY_NOTIFICATION_ADD_MODAL_SET';
export const QUALITY_NOTIFICATION_ADD = 'QUALITY_NOTIFICATION_ADD';
export const QUALITY_NOTIFICATION_ADD_SUCCESS = 'QUALITY_NOTIFICATION_ADD_SUCCESS';
export const QUALITY_NOTIFICATION_ADD_ERROR = 'QUALITY_NOTIFICATION_ADD_ERROR';
export const QUALITY_NOTIFICATION_ADD_CHANGE = 'QUALITY_NOTIFICATION_ADD_CHANGE';
export const QUALITY_NOTIFICATION_DETAIL = 'QUALITY_NOTIFICATION_DETAIL';
export const QUALITY_NOTIFICATION_DETAIL_SUCCESS = 'QUALITY_NOTIFICATION_DETAIL_SUCCESS';
export const QUALITY_NOTIFICATION_DETAIL_ERROR = 'QUALITY_NOTIFICATION_DETAIL_ERROR';
export const QUALITY_NOTIFICATION_VIEW_MODAL_SET = 'QUALITY_NOTIFICATION_VIEW_MODAL_SET';
export const QUALITY_NOTIFICATION_EDIT_MODAL_SET = 'QUALITY_NOTIFICATION_EDIT_MODAL_SET';
export const QUALITY_NOTIFICATION_EDIT_SET = 'QUALITY_NOTIFICATION_EDIT_SET';
export const QUALITY_NOTIFICATION_EDIT_CHANGE = 'QUALITY_NOTIFICATION_EDIT_CHANGE';
export const QUALITY_NOTIFICATION_UPDATE = 'QUALITY_NOTIFICATION_UPDATE';
export const QUALITY_NOTIFICATION_UPDATE_SUCCESS = 'QUALITY_NOTIFICATION_UPDATE_SUCCESS';
export const QUALITY_NOTIFICATION_UPDATE_ERROR = 'QUALITY_NOTIFICATION_UPDATE_ERROR';
export const QUALITY_NOTIFICATION_VIEW_TAB_SET = 'QUALITY_NOTIFICATION_VIEW_TAB_SET';


/* REQUEST */
export const REQUEST_LIST = 'REQUEST_LIST';
export const REQUEST_LIST_SUCCESS = 'REQUEST_LIST_SUCCESS';
export const REQUEST_LIST_ERROR = 'REQUEST_LIST_ERROR';
export const REQUEST_PAGE_SET = 'REQUEST_PAGE_SET';
export const REQUEST_SEARCH_SET = 'REQUEST_SEARCH_SET';
export const REQUEST_SEARCH_CHANGE = 'REQUEST_SEARCH_CHANGE';
export const REQUEST_SEARCH_REQUEST_USER_LIST = 'REQUEST_SEARCH_REQUEST_USER_LIST';
export const REQUEST_SEARCH_REQUEST_USER_LIST_SUCCESS = 'REQUEST_SEARCH_REQUEST_USER_SUCCESS';
export const REQUEST_SEARCH_REQUEST_USER_LIST_ERROR = 'REQUEST_SEARCH_REQUEST_USER_LIST_ERROR';
export const REQUEST_SEARCH_REQUEST_PROCESS_LIST = 'REQUEST_SEARCH_REQUEST_PROCESS_LIST';
export const REQUEST_SEARCH_REQUEST_PROCESS_LIST_SUCCESS = 'REQUEST_SEARCH_REQUEST_PROCESS_LIST_SUCCESS';
export const REQUEST_SEARCH_REQUEST_PROCESS_LIST_ERROR = 'REQUEST_SEARCH_REQUEST_PROCESS_LIST_ERROR';
export const REQUEST_SEARCH_GEO_COUNTRY_LIST = 'REQUEST_SEARCH_GEO_COUNTRY_LIST';
export const REQUEST_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'REQUEST_SEARCH_GEO_COUNTRY_SUCCESS';
export const REQUEST_SEARCH_GEO_COUNTRY_LIST_ERROR = 'REQUEST_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const REQUEST_ADD_SET = 'REQUEST_ADD_SET';
export const REQUEST_ADD_MODAL_SET = 'REQUEST_ADD_MODAL_SET';
export const REQUEST_ADD = 'REQUEST_ADD';
export const REQUEST_ADD_SUCCESS = 'REQUEST_ADD_SUCCESS';
export const REQUEST_ADD_ERROR = 'REQUEST_ADD_ERROR';
export const REQUEST_ADD_CHANGE = 'REQUEST_ADD_CHANGE';
export const REQUEST_DETAIL = 'REQUEST_DETAIL';
export const REQUEST_DETAIL_SUCCESS = 'REQUEST_DETAIL_SUCCESS';
export const REQUEST_DETAIL_ERROR = 'REQUEST_DETAIL_ERROR';
export const REQUEST_VIEW_MODAL_SET = 'REQUEST_VIEW_MODAL_SET';
export const REQUEST_EDIT_MODAL_SET = 'REQUEST_EDIT_MODAL_SET';
export const REQUEST_EDIT_SET = 'REQUEST_EDIT_SET';
export const REQUEST_EDIT_CHANGE = 'REQUEST_EDIT_CHANGE';
export const REQUEST_UPDATE = 'REQUEST_UPDATE';
export const REQUEST_UPDATE_SUCCESS = 'REQUEST_UPDATE_SUCCESS';
export const REQUEST_UPDATE_ERROR = 'REQUEST_UPDATE_ERROR';
export const REQUEST_VIEW_TAB_SET = 'REQUEST_VIEW_TAB_SET';

/* REQUEST_DETAIL */
export const REQUEST_DETAIL_LIST = 'REQUEST_DETAIL_LIST';
export const REQUEST_DETAIL_LIST_SUCCESS = 'REQUEST_DETAIL_LIST_SUCCESS';
export const REQUEST_DETAIL_LIST_ERROR = 'REQUEST_DETAIL_LIST_ERROR';
export const REQUEST_DETAIL_PAGE_SET = 'REQUEST_DETAIL_PAGE_SET';
export const REQUEST_DETAIL_SEARCH_SET = 'REQUEST_DETAIL_SEARCH_SET';
export const REQUEST_DETAIL_SEARCH_CHANGE = 'REQUEST_DETAIL_SEARCH_CHANGE';
export const REQUEST_DETAIL_SEARCH_ARTICLE_LIST = 'REQUEST_DETAIL_SEARCH_ARTICLE_LIST';
export const REQUEST_DETAIL_SEARCH_ARTICLE_LIST_SUCCESS = 'REQUEST_DETAIL_SEARCH_ARTICLE_SUCCESS';
export const REQUEST_DETAIL_SEARCH_ARTICLE_LIST_ERROR = 'REQUEST_DETAIL_SEARCH_ARTICLE_LIST_ERROR';
export const REQUEST_DETAIL_SEARCH_REQUEST_LIST = 'REQUEST_DETAIL_SEARCH_REQUEST_LIST';
export const REQUEST_DETAIL_SEARCH_REQUEST_LIST_SUCCESS = 'REQUEST_DETAIL_SEARCH_REQUEST_LIST_SUCCESS';
export const REQUEST_DETAIL_SEARCH_REQUEST_LIST_ERROR = 'REQUEST_DETAIL_SEARCH_REQUEST_LIST_ERROR';
export const REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST = 'REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST';
export const REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS';
export const REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST_ERROR = 'REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const REQUEST_DETAIL_SEARCH_GEO_REGION_LIST = 'REQUEST_DETAIL_SEARCH_GEO_REGION_LIST';
export const REQUEST_DETAIL_SEARCH_GEO_REGION_LIST_SUCCESS = 'REQUEST_DETAIL_SEARCH_GEO_REGION_LIST_SUCCESS';
export const REQUEST_DETAIL_SEARCH_GEO_REGION_LIST_ERROR = 'REQUEST_DETAIL_SEARCH_GEO_REGION_LIST_ERROR';
export const REQUEST_DETAIL_ADD_SET = 'REQUEST_DETAIL_ADD_SET';
export const REQUEST_DETAIL_ADD_MODAL_SET = 'REQUEST_DETAIL_ADD_MODAL_SET';
export const REQUEST_DETAIL_ADD = 'REQUEST_DETAIL_ADD';
export const REQUEST_DETAIL_ADD_SUCCESS = 'REQUEST_DETAIL_ADD_SUCCESS';
export const REQUEST_DETAIL_ADD_ERROR = 'REQUEST_DETAIL_ADD_ERROR';
export const REQUEST_DETAIL_ADD_CHANGE = 'REQUEST_DETAIL_ADD_CHANGE';
export const REQUEST_DETAIL_DETAIL = 'REQUEST_DETAIL_DETAIL';
export const REQUEST_DETAIL_DETAIL_SUCCESS = 'REQUEST_DETAIL_DETAIL_SUCCESS';
export const REQUEST_DETAIL_DETAIL_ERROR = 'REQUEST_DETAIL_DETAIL_ERROR';
export const REQUEST_DETAIL_VIEW_MODAL_SET = 'REQUEST_DETAIL_VIEW_MODAL_SET';
export const REQUEST_DETAIL_EDIT_MODAL_SET = 'REQUEST_DETAIL_EDIT_MODAL_SET';
export const REQUEST_DETAIL_EDIT_SET = 'REQUEST_DETAIL_EDIT_SET';
export const REQUEST_DETAIL_EDIT_CHANGE = 'REQUEST_DETAIL_EDIT_CHANGE';
export const REQUEST_DETAIL_UPDATE = 'REQUEST_DETAIL_UPDATE';
export const REQUEST_DETAIL_UPDATE_SUCCESS = 'REQUEST_DETAIL_UPDATE_SUCCESS';
export const REQUEST_DETAIL_UPDATE_ERROR = 'REQUEST_DETAIL_UPDATE_ERROR';
export const REQUEST_DETAIL_VIEW_TAB_SET = 'REQUEST_DETAIL_VIEW_TAB_SET';
export const REQUEST_DETAIL_SEARCH_ARTICLE_SET = 'REQUEST_DETAIL_SEARCH_ARTICLE_SET';

/* DASHBOARD */
export const DASHBOARD_LIST = 'DASHBOARD_LIST';
export const DASHBOARD_LIST_SUCCESS = 'DASHBOARD_LIST_SUCCESS';
export const DASHBOARD_LIST_ERROR = 'DASHBOARD_LIST_ERROR';
export const DASHBOARD_SEARCH_SET = 'DASHBOARD_SEARCH_SET';
export const DASHBOARD_SEARCH_CHANGE = 'DASHBOARD_SEARCH_CHANGE';
export const ARTICLE_CODE22 = 'ARTICLE_CODE22';
export const ARTICLE_CODE22_SUCCESS = 'ARTICLE_CODE22_SUCCESS';
export const ARTICLE_CODE22_ERROR = 'ARTICLE_CODE22_ERROR';
export const DASHBOARD_UNIT_PRICE = 'DASHBOARD_UNIT_PRICE';
export const DASHBOARD_UNIT_PRICE_SUCCESS = 'DASHBOARD_UNIT_PRICE_SUCCESS';
export const DASHBOARD_UNIT_PRICE_ERROR = 'DASHBOARD_UNIT_PRICE_ERROR';
export const DASHBOARD_SOURCE = 'DASHBOARD_SOURCE';
export const DASHBOARD_SOURCE_SUCCESS = 'DASHBOARD_SOURCE_SUCCESS';
export const DASHBOARD_SOURCE_ERROR = 'DASHBOARD_SOURCE_ERROR';
export const DASHBOARD_YEAR = 'DASHBOARD_YEAR';
export const DASHBOARD_YEAR_SUCCESS = 'DASHBOARD_YEAR_SUCCESS';
export const DASHBOARD_YEAR_ERROR = 'DASHBOARD_YEAR_ERROR';
export const DASHBOARD_PRICES = 'DASHBOARD_PRICES';
export const DASHBOARD_PRICES_SUCCESS = 'DASHBOARD_PRICES_SUCCESS';
export const DASHBOARD_PRICES_ERROR = 'DASHBOARD_PRICES_ERROR';
export const DASHBOARD_TRANSACTIONS = 'DASHBOARD_TRANSACTIONS';
export const DASHBOARD_TRANSACTIONS_SUCCESS = 'DASHBOARD_TRANSACTIONS_SUCCESS';
export const DASHBOARD_TRANSACTIONS_ERROR = 'DASHBOARD_TRANSACTIONS_ERROR';
export const DASHBOARD_RAZONABILIDAD = 'DASHBOARD_RAZONABILIDAD';
export const DASHBOARD_RAZONABILIDAD_SUCCESS = 'DASHBOARD_RAZONABILIDAD_SUCCESS';
export const DASHBOARD_RAZONABILIDAD_ERROR = 'DASHBOARD_RAZONABILIDAD_ERROR';
export const DASHBOARD_DATA_SUPPLIER = 'DASHBOARD_DATA_SUPPLIER';
export const DASHBOARD_DATA_SUPPLIER_SUCCESS = 'DASHBOARD_DATA_SUPPLIER_SUCCESS';
export const DASHBOARD_DATA_SUPPLIER_ERROR = 'DASHBOARD_DATA_SUPPLIER_ERROR';
export const DASHBOARD_DATA_SUPPLIER_PAGE_SET = 'DASHBOARD_DATA_SUPPLIER_PAGE_SET';

/* PROCESS */
export const PROCESS_LIST = 'PROCESS_LIST';
export const PROCESS_LIST_SUCCESS = 'PROCESS_LIST_SUCCESS';
export const PROCESS_LIST_ERROR = 'PROCESS_LIST_ERROR';
export const PROCESS_PAGE_SET = 'PROCESS_PAGE_SET';
export const PROCESS_SEARCH_SET = 'PROCESS_SEARCH_SET';
export const PROCESS_SEARCH_CHANGE = 'PROCESS_SEARCH_CHANGE';
export const PROCESS_SEARCH_PROCESS_USER_LIST = 'PROCESS_SEARCH_PROCESS_USER_LIST';
export const PROCESS_SEARCH_PROCESS_USER_LIST_SUCCESS = 'PROCESS_SEARCH_PROCESS_USER_SUCCESS';
export const PROCESS_SEARCH_PROCESS_USER_LIST_ERROR = 'PROCESS_SEARCH_PROCESS_USER_LIST_ERROR';
export const PROCESS_SEARCH_PROCESS_PROCESS_LIST = 'PROCESS_SEARCH_PROCESS_PROCESS_LIST';
export const PROCESS_SEARCH_PROCESS_PROCESS_LIST_SUCCESS = 'PROCESS_SEARCH_PROCESS_PROCESS_LIST_SUCCESS';
export const PROCESS_SEARCH_PROCESS_PROCESS_LIST_ERROR = 'PROCESS_SEARCH_PROCESS_PROCESS_LIST_ERROR';
export const PROCESS_SEARCH_GEO_COUNTRY_LIST = 'PROCESS_SEARCH_GEO_COUNTRY_LIST';
export const PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS = 'PROCESS_SEARCH_GEO_COUNTRY_SUCCESS';
export const PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR = 'PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR';
export const PROCESS_ADD_SET = 'PROCESS_ADD_SET';
export const PROCESS_ADD_MODAL_SET = 'PROCESS_ADD_MODAL_SET';
export const PROCESS_ADD = 'PROCESS_ADD';
export const PROCESS_ADD_SUCCESS = 'PROCESS_ADD_SUCCESS';
export const PROCESS_ADD_ERROR = 'PROCESS_ADD_ERROR';
export const PROCESS_ADD_CHANGE = 'PROCESS_ADD_CHANGE';
export const PROCESS_DETAIL = 'PROCESS_DETAIL';
export const PROCESS_DETAIL_SUCCESS = 'PROCESS_DETAIL_SUCCESS';
export const PROCESS_DETAIL_ERROR = 'PROCESS_DETAIL_ERROR';
export const PROCESS_VIEW_MODAL_SET = 'PROCESS_VIEW_MODAL_SET';
export const PROCESS_EDIT_MODAL_SET = 'PROCESS_EDIT_MODAL_SET';
export const PROCESS_EDIT_SET = 'PROCESS_EDIT_SET';
export const PROCESS_EDIT_CHANGE = 'PROCESS_EDIT_CHANGE';
export const PROCESS_UPDATE = 'PROCESS_UPDATE';
export const PROCESS_UPDATE_SUCCESS = 'PROCESS_UPDATE_SUCCESS';
export const PROCESS_UPDATE_ERROR = 'PROCESS_UPDATE_ERROR';
export const PROCESS_VIEW_TAB_SET = 'PROCESS_VIEW_TAB_SET';

/* PROCESS_DETAIL */
export const PROCESS_DETAIL_LIST = 'PROCESS_DETAIL_LIST';
export const PROCESS_DETAIL_LIST_SUCCESS = 'PROCESS_DETAIL_LIST_SUCCESS';
export const PROCESS_DETAIL_LIST_ERROR = 'PROCESS_DETAIL_LIST_ERROR';
export const PROCESS_DETAIL_PAGE_SET = 'PROCESS_DETAIL_PAGE_SET';
export const PROCESS_DETAIL_SEARCH_SET = 'PROCESS_DETAIL_SEARCH_SET';
export const PROCESS_DETAIL_SEARCH_CHANGE = 'PROCESS_DETAIL_SEARCH_CHANGE';
export const PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST = 'PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST';
export const PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST_SUCCESS = 'PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST_SUCCESS';
export const PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST_ERROR = 'PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST_ERROR';
export const PROCESS_DETAIL_SEARCH_PROCESS_LIST = 'PROCESS_DETAIL_SEARCH_PROCESS_LIST';
export const PROCESS_DETAIL_SEARCH_PROCESS_LIST_SUCCESS = 'PROCESS_DETAIL_SEARCH_PROCESS_LIST_SUCCESS';
export const PROCESS_DETAIL_SEARCH_PROCESS_LIST_ERROR = 'PROCESS_DETAIL_SEARCH_PROCESS_LIST_ERROR';
export const PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST = 'PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST';
export const PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST_SUCCESS = 'PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST_SUCCESS';
export const PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST_ERROR = 'PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST_ERROR';
export const PROCESS_DETAIL_SEARCH_GEO_REGION_LIST = 'PROCESS_DETAIL_SEARCH_GEO_REGION_LIST';
export const PROCESS_DETAIL_SEARCH_GEO_REGION_LIST_SUCCESS = 'PROCESS_DETAIL_SEARCH_GEO_REGION_LIST_SUCCESS';
export const PROCESS_DETAIL_SEARCH_GEO_REGION_LIST_ERROR = 'PROCESS_DETAIL_SEARCH_GEO_REGION_LIST_ERROR';
export const PROCESS_DETAIL_ADD_SET = 'PROCESS_DETAIL_ADD_SET';
export const PROCESS_DETAIL_ADD_MODAL_SET = 'PROCESS_DETAIL_ADD_MODAL_SET';
export const PROCESS_DETAIL_ADD = 'PROCESS_DETAIL_ADD';
export const PROCESS_DETAIL_ADD_SUCCESS = 'PROCESS_DETAIL_ADD_SUCCESS';
export const PROCESS_DETAIL_ADD_ERROR = 'PROCESS_DETAIL_ADD_ERROR';
export const PROCESS_DETAIL_ADD_CHANGE = 'PROCESS_DETAIL_ADD_CHANGE';
export const PROCESS_DETAIL_DETAIL = 'PROCESS_DETAIL_DETAIL';
export const PROCESS_DETAIL_DETAIL_SUCCESS = 'PROCESS_DETAIL_DETAIL_SUCCESS';
export const PROCESS_DETAIL_DETAIL_ERROR = 'PROCESS_DETAIL_DETAIL_ERROR';
export const PROCESS_DETAIL_VIEW_MODAL_SET = 'PROCESS_DETAIL_VIEW_MODAL_SET';
export const PROCESS_DETAIL_EDIT_MODAL_SET = 'PROCESS_DETAIL_EDIT_MODAL_SET';
export const PROCESS_DETAIL_EDIT_SET = 'PROCESS_DETAIL_EDIT_SET';
export const PROCESS_DETAIL_EDIT_CHANGE = 'PROCESS_DETAIL_EDIT_CHANGE';
export const PROCESS_DETAIL_UPDATE = 'PROCESS_DETAIL_UPDATE';
export const PROCESS_DETAIL_UPDATE_SUCCESS = 'PROCESS_DETAIL_UPDATE_SUCCESS';
export const PROCESS_DETAIL_UPDATE_ERROR = 'PROCESS_DETAIL_UPDATE_ERROR';
export const PROCESS_DETAIL_VIEW_TAB_SET = 'PROCESS_DETAIL_VIEW_TAB_SET';
export const PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_SET = 'PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_SET';

/* ARTICLE */
export const ARTICLE_LIST = 'ARTICLE_LIST';
export const ARTICLE_LIST_SUCCESS = 'ARTICLE_LIST_SUCCESS';
export const ARTICLE_LIST_ERROR = 'ARTICLE_LIST_ERROR';
export const ARTICLE_PAGE_SET = 'ARTICLE_PAGE_SET';
export const ARTICLE_SEARCH_SET = 'ARTICLE_SEARCH_SET';
export const ARTICLE_SEARCH_CHANGE = 'ARTICLE_SEARCH_CHANGE';
export const ARTICLE_SEARCH_ARTICLE_LIST = 'ARTICLE_SEARCH_ARTICLE_LIST';
export const ARTICLE_SEARCH_ARTICLE_LIST_SUCCESS = 'ARTICLE_SEARCH_ARTICLE_LIST_SUCCESS';
export const ARTICLE_SEARCH_ARTICLE_LIST_ERROR = 'ARTICLE_SEARCH_ARTICLE_LIST_ERROR';
export const ARTICLE_SEARCH_PROCESS_LIST = 'ARTICLE_SEARCH_PROCESS_LIST';
export const ARTICLE_SEARCH_PROCESS_LIST_SUCCESS = 'ARTICLE_SEARCH_PROCESS_LIST_SUCCESS';
export const ARTICLE_SEARCH_PROCESS_LIST_ERROR = 'ARTICLE_SEARCH_PROCESS_LIST_ERROR';
export const ARTICLE_SEARCH_MEDICAMENT_LIST = 'ARTICLE_SEARCH_MEDICAMENT_LIST';
export const ARTICLE_SEARCH_MEDICAMENT_LIST_SUCCESS = 'ARTICLE_SEARCH_MEDICAMENT_LIST_SUCCESS';
export const ARTICLE_SEARCH_MEDICAMENT_LIST_ERROR = 'ARTICLE_SEARCH_MEDICAMENT_LIST_ERROR';
export const ARTICLE_SEARCH_GEO_REGION_LIST = 'ARTICLE_SEARCH_GEO_REGION_LIST';
export const ARTICLE_SEARCH_GEO_REGION_LIST_SUCCESS = 'ARTICLE_SEARCH_GEO_REGION_LIST_SUCCESS';
export const ARTICLE_SEARCH_GEO_REGION_LIST_ERROR = 'ARTICLE_SEARCH_GEO_REGION_LIST_ERROR';
export const ARTICLE_ADD_SET = 'ARTICLE_ADD_SET';
export const ARTICLE_ADD_MODAL_SET = 'ARTICLE_ADD_MODAL_SET';
export const ARTICLE_ADD = 'ARTICLE_ADD';
export const ARTICLE_ADD_SUCCESS = 'ARTICLE_ADD_SUCCESS';
export const ARTICLE_ADD_ERROR = 'ARTICLE_ADD_ERROR';
export const ARTICLE_ADD_CHANGE = 'ARTICLE_ADD_CHANGE';
export const ARTICLE_DETAIL = 'ARTICLE_DETAIL';
export const ARTICLE_DETAIL_SUCCESS = 'ARTICLE_DETAIL_SUCCESS';
export const ARTICLE_DETAIL_ERROR = 'ARTICLE_DETAIL_ERROR';
export const ARTICLE_VIEW_MODAL_SET = 'ARTICLE_VIEW_MODAL_SET';
export const ARTICLE_EDIT_MODAL_SET = 'ARTICLE_EDIT_MODAL_SET';
export const ARTICLE_EDIT_SET = 'ARTICLE_EDIT_SET';
export const ARTICLE_EDIT_CHANGE = 'ARTICLE_EDIT_CHANGE';
export const ARTICLE_UPDATE = 'ARTICLE_UPDATE';
export const ARTICLE_UPDATE_SUCCESS = 'ARTICLE_UPDATE_SUCCESS';
export const ARTICLE_UPDATE_ERROR = 'ARTICLE_UPDATE_ERROR';
export const ARTICLE_VIEW_TAB_SET = 'ARTICLE_VIEW_TAB_SET';
export const ARTICLE_SEARCH_ARTICLE_SET = 'ARTICLE_SEARCH_ARTICLE_SET';
/* LOCATION */
export const LOCATION_LIST = 'LOCATION_LIST';
export const LOCATION_LIST_SUCCESS = 'LOCATION_LIST_SUCCESS';
export const LOCATION_LIST_ERROR = 'LOCATION_LIST_ERROR';
export const LOCATION_PAGE_SET = 'LOCATION_PAGE_SET';
export const LOCATION_SEARCH_SET = 'LOCATION_SEARCH_SET';
export const LOCATION_SEARCH_CHANGE = 'LOCATION_SEARCH_CHANGE';
export const LOCATION_SEARCH_LOCATION_LIST = 'LOCATION_SEARCH_LOCATION_LIST';
export const LOCATION_SEARCH_LOCATION_LIST_SUCCESS = 'LOCATION_SEARCH_LOCATION_LIST_SUCCESS';
export const LOCATION_SEARCH_LOCATION_LIST_ERROR = 'LOCATION_SEARCH_LOCATION_LIST_ERROR';
export const LOCATION_SEARCH_PROCESS_LIST = 'LOCATION_SEARCH_PROCESS_LIST';
export const LOCATION_SEARCH_PROCESS_LIST_SUCCESS = 'LOCATION_SEARCH_PROCESS_LIST_SUCCESS';
export const LOCATION_SEARCH_PROCESS_LIST_ERROR = 'LOCATION_SEARCH_PROCESS_LIST_ERROR';
export const LOCATION_SEARCH_MEDICAMENT_LIST = 'LOCATION_SEARCH_MEDICAMENT_LIST';
export const LOCATION_SEARCH_MEDICAMENT_LIST_SUCCESS = 'LOCATION_SEARCH_MEDICAMENT_LIST_SUCCESS';
export const LOCATION_SEARCH_MEDICAMENT_LIST_ERROR = 'LOCATION_SEARCH_MEDICAMENT_LIST_ERROR';
export const LOCATION_SEARCH_GEO_REGION_LIST = 'LOCATION_SEARCH_GEO_REGION_LIST';
export const LOCATION_SEARCH_GEO_REGION_LIST_SUCCESS = 'LOCATION_SEARCH_GEO_REGION_LIST_SUCCESS';
export const LOCATION_SEARCH_GEO_REGION_LIST_ERROR = 'LOCATION_SEARCH_GEO_REGION_LIST_ERROR';
export const LOCATION_ADD_SET = 'LOCATION_ADD_SET';
export const LOCATION_ADD_MODAL_SET = 'LOCATION_ADD_MODAL_SET';
export const LOCATION_ADD = 'LOCATION_ADD';
export const LOCATION_ADD_SUCCESS = 'LOCATION_ADD_SUCCESS';
export const LOCATION_ADD_ERROR = 'LOCATION_ADD_ERROR';
export const LOCATION_ADD_CHANGE = 'LOCATION_ADD_CHANGE';
export const LOCATION_DETAIL = 'LOCATION_DETAIL';
export const LOCATION_DETAIL_SUCCESS = 'LOCATION_DETAIL_SUCCESS';
export const LOCATION_DETAIL_ERROR = 'LOCATION_DETAIL_ERROR';
export const LOCATION_VIEW_MODAL_SET = 'LOCATION_VIEW_MODAL_SET';
export const LOCATION_EDIT_MODAL_SET = 'LOCATION_EDIT_MODAL_SET';
export const LOCATION_EDIT_SET = 'LOCATION_EDIT_SET';
export const LOCATION_EDIT_CHANGE = 'LOCATION_EDIT_CHANGE';
export const LOCATION_UPDATE = 'LOCATION_UPDATE';
export const LOCATION_UPDATE_SUCCESS = 'LOCATION_UPDATE_SUCCESS';
export const LOCATION_UPDATE_ERROR = 'LOCATION_UPDATE_ERROR';
export const LOCATION_VIEW_TAB_SET = 'LOCATION_VIEW_TAB_SET';
export const LOCATION_SEARCH_LOCATION_SET = 'LOCATION_SEARCH_LOCATION_SET';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './user/actions';
export * from './app/actions';
export * from './quality_email/actions';
export * from './quality_autoreview/actions';
export * from './quality_source/actions';
export * from './autoreview/actions';
export * from './quality_notification/actions';
export * from './request/actions';
export * from './request_detail/actions';
export * from './dashboard/actions';
export * from './validate_email/actions';
export * from './process/actions';
export * from './process_detail/actions';
export * from './mspas/article/actions';
export * from './mspas/location/actions';