import { createStructuredSelector } from 'reselect'

import {
  PROCESS_DETAIL_LIST,
  PROCESS_DETAIL_LIST_SUCCESS,
  PROCESS_DETAIL_LIST_ERROR,
  PROCESS_DETAIL_PAGE_SET,
  PROCESS_DETAIL_SEARCH_SET,
  PROCESS_DETAIL_SEARCH_CHANGE,
  PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST,
  PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST_SUCCESS,
  PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST_ERROR,
  PROCESS_DETAIL_SEARCH_PROCESS_LIST,
  PROCESS_DETAIL_SEARCH_PROCESS_LIST_SUCCESS,
  PROCESS_DETAIL_SEARCH_PROCESS_LIST_ERROR,
  PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST,
  PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST_SUCCESS,
  PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST_ERROR,
  PROCESS_DETAIL_SEARCH_GEO_REGION_LIST,
  PROCESS_DETAIL_SEARCH_GEO_REGION_LIST_SUCCESS,
  PROCESS_DETAIL_SEARCH_GEO_REGION_LIST_ERROR,
  PROCESS_DETAIL_ADD_SET,
  PROCESS_DETAIL_ADD_MODAL_SET,
  PROCESS_DETAIL_ADD,
  PROCESS_DETAIL_ADD_SUCCESS,
  PROCESS_DETAIL_ADD_ERROR,
  PROCESS_DETAIL_ADD_CHANGE,
  PROCESS_DETAIL_DETAIL,
  PROCESS_DETAIL_DETAIL_SUCCESS,
  PROCESS_DETAIL_DETAIL_ERROR,
  PROCESS_DETAIL_VIEW_MODAL_SET,
  PROCESS_DETAIL_EDIT_CHANGE,
  PROCESS_DETAIL_EDIT_MODAL_SET,
  PROCESS_DETAIL_UPDATE,
  PROCESS_DETAIL_UPDATE_SUCCESS,
  PROCESS_DETAIL_UPDATE_ERROR,
  PROCESS_DETAIL_VIEW_TAB_SET,
  PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_SET
} from '../actions';

export const NAME = 'process_detail';

export const ProcessDetailList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: PROCESS_DETAIL_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const ProcessDetailListSuccess = (data) => ({
  type: PROCESS_DETAIL_LIST_SUCCESS,
  payload: data
});

export const ProcessDetailListError = (error) => ({
  type: PROCESS_DETAIL_LIST_ERROR,
  payload: error
});

export const ProcessDetailSearchProcessDetailSet = (data) => ({
  type: PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_SET,
  payload: data
});

export const ProcessDetailPageSet = (data) => ({
  type: PROCESS_DETAIL_PAGE_SET,
  payload: data
});

export const ProcessDetailSearchSet = (data) => ({
  type: PROCESS_DETAIL_SEARCH_SET,
  payload: data
});

export const ProcessDetailSearchChangeSet = (data) => ({
  type: PROCESS_DETAIL_SEARCH_CHANGE,
  payload: data
});

export const ProcessDetailSearchProcessDetailList = (data) => ({
  type: PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST,
  payload: data
});

export const ProcessDetailSearchProcessDetailListSuccess = (message) => ({
  type: PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST_SUCCESS,
  payload: message
});

export const ProcessDetailSearchProcessDetailListError = (error) => ({
  type: PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST_ERROR,
  payload: error
}); 

export const ProcessDetailSearchProcessList = (data) => ({
  type: PROCESS_DETAIL_SEARCH_PROCESS_LIST,
  payload: data
});

export const ProcessDetailSearchProcessListSuccess = (message) => ({
  type: PROCESS_DETAIL_SEARCH_PROCESS_LIST_SUCCESS,
  payload: message
});

export const ProcessDetailSearchProcessListError = (error) => ({
  type: PROCESS_DETAIL_SEARCH_PROCESS_LIST_ERROR,
  payload: error
}); 

export const ProcessDetailSearchMedicamentList = (data) => ({
  type: PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST,
  payload: data
});

export const ProcessDetailSearchMedicamentListSuccess = (message) => ({
  type: PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST_SUCCESS,
  payload: message
});

export const ProcessDetailSearchMedicamentListError = (error) => ({
  type: PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST_ERROR,
  payload: error
}); 
export const ProcessDetailSearchGeoRegionList = (data) => ({
  type: PROCESS_DETAIL_SEARCH_GEO_REGION_LIST,
  payload: data
});

export const ProcessDetailSearchGeoRegionListSuccess = (message) => ({
  type: PROCESS_DETAIL_SEARCH_GEO_REGION_LIST_SUCCESS,
  payload: message
});

export const ProcessDetailSearchGeoRegionListError = (error) => ({
  type: PROCESS_DETAIL_SEARCH_GEO_REGION_LIST_ERROR,
  payload: error
}); 


export const ProcessDetailAddChangeSet = (data) => ({
  type: PROCESS_DETAIL_ADD_CHANGE,
  payload: data
});

export const ProcessDetailAddSet = (data) => ({
  type: PROCESS_DETAIL_ADD_SET,
  payload: data
});

export const ProcessDetailAddModalSet = (data) => ({
  type: PROCESS_DETAIL_ADD_MODAL_SET,
  payload: data
});

export const ProcessDetailAdd = (data) => ({
  type: PROCESS_DETAIL_ADD,
  payload: data
});

export const ProcessDetailAddSuccess = (message) => ({
  type: PROCESS_DETAIL_ADD_SUCCESS,
  payload: message
});

export const ProcessDetailAddError = (error) => ({
  type: PROCESS_DETAIL_ADD_ERROR,
  payload: error
});

export const ProcessDetailDetail = (data) => ({
  type: PROCESS_DETAIL_DETAIL,
  payload: data
});

export const ProcessDetailDetailSuccess = (data) => ({
  type: PROCESS_DETAIL_DETAIL_SUCCESS,
  payload: data
});

export const ProcessDetailDetailError = (error) => ({
  type: PROCESS_DETAIL_DETAIL_ERROR,
  payload: error
});

export const ProcessDetailViewModalSet = (data) => ({
  type: PROCESS_DETAIL_VIEW_MODAL_SET,
  payload: data
});

export const ProcessDetailEditModalSet = (data) => ({
  type: PROCESS_DETAIL_EDIT_MODAL_SET,
  payload: data
});

export const ProcessDetailEditChangeSet = (data) => ({
  type: PROCESS_DETAIL_EDIT_CHANGE,
  payload: data
});

export const ProcessDetailUpdate = (data) => ({
  type: PROCESS_DETAIL_UPDATE,
  payload: data
});

export const ProcessDetailUpdateSuccess = (message) => ({
  type: PROCESS_DETAIL_UPDATE_SUCCESS,
  payload: message.message
});

export const ProcessDetailUpdateError = (error) => ({
  type: PROCESS_DETAIL_UPDATE_ERROR,
  payload: error
});

export const ProcessDetailViewTabSet = (data) => ({
  type: PROCESS_DETAIL_VIEW_TAB_SET,
  payload: data
});

const process_detail = (state) => state[NAME];

export const ProcessDetailSelector = createStructuredSelector({
  process_detail
})
