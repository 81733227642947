import {
    VALIDATE_EMAIL,
    VALIDATE_EMAIL_SUCCESS,
    VALIDATE_EMAIL_ERROR
} from '../actions';

const INIT_STATE = {
    init: true,
    loadings: true,
    emailUser: {
        data: [] 
    },
    error: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case VALIDATE_EMAIL:
            return {
                ...state,
                loadings: true,
                emailUser: {
                    ...state.emailUser,
                    data: []
                }
            }
        case VALIDATE_EMAIL_SUCCESS:
            return {
                ...state,
                loadings: false,
                emailUser: {
                    ...state.emailUser,
                    ...action.payload
                }
            }
        case VALIDATE_EMAIL_ERROR:
            return {
                ...state,
                loadings: false,
                emailUser: {
                    ...state.emailUser,
                    data: []
                }
            }
        default:
            return { ...state };
    }

};