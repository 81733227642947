import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  APP_LIST,
  APP_SEARCH_APP_LIST,
  APP_SEARCH_APP_TYPE_LIST,
  APP_ADD,
  APP_VIEW,
  APP_UPDATE,
} from '../actions';
import {
  AppListSuccess,
  AppListError,
  AppSearchAppListSuccess,
  AppSearchAppListError,
  AppSearchAppTypeListSuccess,
  AppSearchAppTypeListError,
  AppAddSuccess,
  AppAddError,
  AppViewSuccess,
  AppViewError,
  AppUpdateSuccess,
  AppUpdateError
} from '../actions';
 
export function* watchAppList() {
  yield takeEvery(APP_LIST, onAppList);
}


export function* watchAppSearchAppList() {
  yield takeEvery(APP_SEARCH_APP_LIST, onAppSearchAppList);
}

export function* watchAppSearchAppTypeList() {
  yield takeEvery(APP_SEARCH_APP_TYPE_LIST, onAppSearchAppTypeList);
}

export function* watchAppAdd() {
  yield takeEvery(APP_ADD, onAppAdd);
}

export function* watchAppView() {
  yield takeEvery(APP_VIEW, onAppView);
}

export function* watchAppUpdate() {
  yield takeEvery(APP_UPDATE, onAppUpdate);
}

const onAppListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'app/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onAppUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'app/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}


const onAppSearchAppListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/app', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onAppSearchAppTypeListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/app_type', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onAppAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'app/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onAppViewAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'app/view', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onAppList(data) {
  try {
    const response = yield call(onAppListAsync, data.payload);
    yield put(AppListSuccess(response.data));
  } catch (error) {
    yield put(AppListError(error));
  }
}

function* onAppSearchAppList(data) {
  try {
    const response = yield call(onAppSearchAppListAsync, data.payload);
    yield put(AppSearchAppListSuccess(response.data));
  } catch (error) {
    yield put(AppSearchAppListError(error));
  }
}

function* onAppSearchAppTypeList(data) {
  try {
    const response = yield call(onAppSearchAppTypeListAsync, data.payload);
    yield put(AppSearchAppTypeListSuccess(response.data));
  } catch (error) {
    yield put(AppSearchAppTypeListError(error));
  }
}

function* onAppAdd(data) {
  try {
      const response = yield call(onAppAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(AppAddSuccess(response.data));
  } catch (error) {
      yield put(AppAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onAppView(data) {
  try {
    const response = yield call(onAppViewAsync, data.payload);
    yield put(AppViewSuccess(response.data.data));
  } catch (error) {
    yield put(AppViewError(error));
  }
}

function* onAppUpdate(data) {
  try {
      const response = yield call(onAppUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(AppUpdateSuccess(response.data));
  } catch (error) {
      yield put(AppUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAppList),
    fork(watchAppSearchAppList),
    fork(watchAppSearchAppTypeList),
    fork(watchAppAdd),
    fork(watchAppView),
    fork(watchAppUpdate),
  ]);
} 