import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { getCurrentLanguage } from '../../helpers/Utils';
import { 
  QUALITY_NOTIFICATION_LIST,
  QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST,
  QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST,
  QUALITY_NOTIFICATION_ADD,
  QUALITY_NOTIFICATION_DETAIL,
  QUALITY_NOTIFICATION_UPDATE,
} from '../actions';
import {
  QualityNotificationListSuccess,
  QualityNotificationListError,
  QualityNotificationSearchRequestListSuccess,
  QualityNotificationSearchRequestListError,
  QualityNotificationSearchGeoCountryListSuccess,
  QualityNotificationSearchGeoCountryListError,
  QualityNotificationAddSuccess,
  QualityNotificationAddError,
  QualityNotificationDetailSuccess,
  QualityNotificationDetailError,
  QualityNotificationUpdateSuccess,
  QualityNotificationUpdateError
} from '../actions';
 
const locale = getCurrentLanguage();
export function* watchQualityNotificationList() {
  yield takeEvery(QUALITY_NOTIFICATION_LIST, onQualityNotificationList);
}

export function* watchQualityNotificationSearchRequestList() {
  yield takeEvery(QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST, onQualityNotificationSearchRequestList);
}

export function* watchQualityNotificationSearchGeoCountryList() {
  yield takeEvery(QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST, onQualityNotificationSearchGeoCountryList);
}

export function* watchQualityNotificationAdd() {
  yield takeEvery(QUALITY_NOTIFICATION_ADD, onQualityNotificationAdd);
}

export function* watchQualityNotificationDetail() {
  yield takeEvery(QUALITY_NOTIFICATION_DETAIL, onQualityNotificationDetail);
}

export function* watchQualityNotificationUpdate() {
  yield takeEvery(QUALITY_NOTIFICATION_UPDATE, onQualityNotificationUpdate);
}

const onQualityNotificationListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_notification/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onQualityNotificationUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_notification/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onQualityNotificationSearchRequestListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onQualityNotificationSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onQualityNotificationAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_notification/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onQualityNotificationDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'quality_notification/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onQualityNotificationList(data) {
  try {
    const response = yield call(onQualityNotificationListAsync, data.payload);
    yield put(QualityNotificationListSuccess(response.data));
  } catch (error) {
    yield put(QualityNotificationListError(error));
  }
}

function* onQualityNotificationSearchRequestList(data) {
  try {
    console.log('onQualityNotificationSearchRequestList',data.payload)
    const response = yield call(onQualityNotificationSearchRequestListAsync, data.payload);
    yield put(QualityNotificationSearchRequestListSuccess(response.data));
  } catch (error) {
    yield put(QualityNotificationSearchRequestListError(error));
  }
}

function* onQualityNotificationSearchGeoCountryList(data) {
  try {
    const response = yield call(onQualityNotificationSearchGeoCountryListAsync, data.payload);
    yield put(QualityNotificationSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(QualityNotificationSearchGeoCountryListError(error));
  }
}

function* onQualityNotificationAdd(data) {
  try {
      const response = yield call(onQualityNotificationAddAsync, data);

      if (response.data.status !== 'error') {
        NotificationManager.success(locale==='es'?'Solicitud enviada':'Request sent', locale==='es'? 'Éxito':'Success', 3000, null, null, '');
      }
      if (response.data.status === 'error') {
        NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(QualityNotificationAddSuccess(response.data));
  } catch (error) {
      yield put(QualityNotificationAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onQualityNotificationDetail(data) {
  try {
    const response = yield call(onQualityNotificationDetailAsync, data.payload);
    yield put(QualityNotificationDetailSuccess(response.data.data));
  } catch (error) {
    yield put(QualityNotificationDetailError(error));
  }
}

function* onQualityNotificationUpdate(data) {
  try {
      const response = yield call(onQualityNotificationUpdateAsync, data.payload);
      if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(QualityNotificationUpdateSuccess(response.data));
  } catch (error) {
      yield put(QualityNotificationUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchQualityNotificationList),
    fork(watchQualityNotificationSearchRequestList),
    fork(watchQualityNotificationSearchGeoCountryList),
    fork(watchQualityNotificationAdd),
    fork(watchQualityNotificationDetail),
    fork(watchQualityNotificationUpdate),
  ]);
} 