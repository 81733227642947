import { createStructuredSelector } from 'reselect'

import {
  QUALITY_SOURCE_LIST,
  QUALITY_SOURCE_LIST_SUCCESS,
  QUALITY_SOURCE_LIST_ERROR,
  QUALITY_SOURCE_PAGE_SET,
  QUALITY_SOURCE_SEARCH_SET,
  QUALITY_SOURCE_SEARCH_CHANGE,
  QUALITY_SOURCE_SEARCH_REQUEST_LIST,
  QUALITY_SOURCE_SEARCH_REQUEST_LIST_SUCCESS,
  QUALITY_SOURCE_SEARCH_REQUEST_LIST_ERROR,
  QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST,
  QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_ERROR,
  QUALITY_SOURCE_ADD_SET,
  QUALITY_SOURCE_ADD_MODAL_SET,
  QUALITY_SOURCE_ADD,
  QUALITY_SOURCE_ADD_SUCCESS,
  QUALITY_SOURCE_ADD_ERROR,
  QUALITY_SOURCE_ADD_CHANGE,
  QUALITY_SOURCE_DETAIL,
  QUALITY_SOURCE_DETAIL_SUCCESS,
  QUALITY_SOURCE_DETAIL_ERROR,
  QUALITY_SOURCE_VIEW_MODAL_SET,
  QUALITY_SOURCE_EDIT_CHANGE,
  QUALITY_SOURCE_EDIT_MODAL_SET,
  QUALITY_SOURCE_UPDATE,
  QUALITY_SOURCE_UPDATE_SUCCESS,
  QUALITY_SOURCE_UPDATE_ERROR,
  QUALITY_SOURCE_VIEW_TAB_SET
} from '../actions';

export const NAME = 'quality_source';

export const QualitySourceList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: QUALITY_SOURCE_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const QualitySourceListSuccess = (data) => ({
  type: QUALITY_SOURCE_LIST_SUCCESS,
  payload: data
});

export const QualitySourceListError = (error) => ({
  type: QUALITY_SOURCE_LIST_ERROR,
  payload: error
});

export const QualitySourcePageSet = (data) => ({
  type: QUALITY_SOURCE_PAGE_SET,
  payload: data
});

export const QualitySourceSearchSet = (data) => ({
  type: QUALITY_SOURCE_SEARCH_SET,
  payload: data
});

export const QualitySourceSearchChangeSet = (data) => ({
  type: QUALITY_SOURCE_SEARCH_CHANGE,
  payload: data
});

export const QualitySourceSearchRequestList = (data) => ({
  type: QUALITY_SOURCE_SEARCH_REQUEST_LIST,
  payload: data
});

export const QualitySourceSearchRequestListSuccess = (message) => ({
  type: QUALITY_SOURCE_SEARCH_REQUEST_LIST_SUCCESS,
  payload: message
});

export const QualitySourceSearchRequestListError = (error) => ({
  type: QUALITY_SOURCE_SEARCH_REQUEST_LIST_ERROR,
  payload: error
}); 

export const QualitySourceSearchGeoCountryList = (data) => ({
  type: QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const QualitySourceSearchGeoCountryListSuccess = (message) => ({
  type: QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const QualitySourceSearchGeoCountryListError = (error) => ({
  type: QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 

export const QualitySourceAddChangeSet = (data) => ({
  type: QUALITY_SOURCE_ADD_CHANGE,
  payload: data
});

export const QualitySourceAddSet = (data) => ({
  type: QUALITY_SOURCE_ADD_SET,
  payload: data
});

export const QualitySourceAddModalSet = (data) => ({
  type: QUALITY_SOURCE_ADD_MODAL_SET,
  payload: data
});

export const QualitySourceAdd = (data) => ({
  type: QUALITY_SOURCE_ADD,
  payload: data
});

export const QualitySourceAddSuccess = (message) => ({
  type: QUALITY_SOURCE_ADD_SUCCESS,
  payload: message
});

export const QualitySourceAddError = (error) => ({
  type: QUALITY_SOURCE_ADD_ERROR,
  payload: error
});

export const QualitySourceDetail = (data) => ({
  type: QUALITY_SOURCE_DETAIL,
  payload: data
});

export const QualitySourceDetailSuccess = (data) => ({
  type: QUALITY_SOURCE_DETAIL_SUCCESS,
  payload: data
});

export const QualitySourceDetailError = (error) => ({
  type: QUALITY_SOURCE_DETAIL_ERROR,
  payload: error
});

export const QualitySourceViewModalSet = (data) => ({
  type: QUALITY_SOURCE_VIEW_MODAL_SET,
  payload: data
});

export const QualitySourceEditModalSet = (data) => ({
  type: QUALITY_SOURCE_EDIT_MODAL_SET,
  payload: data
});

export const QualitySourceEditChangeSet = (data) => ({
  type: QUALITY_SOURCE_EDIT_CHANGE,
  payload: data
});

export const QualitySourceUpdate = (data) => ({
  type: QUALITY_SOURCE_UPDATE,
  payload: data
});

export const QualitySourceUpdateSuccess = (message) => ({
  type: QUALITY_SOURCE_UPDATE_SUCCESS,
  payload: message.message
});

export const QualitySourceUpdateError = (error) => ({
  type: QUALITY_SOURCE_UPDATE_ERROR,
  payload: error
});

export const QualitySourceViewTabSet = (data) => ({
  type: QUALITY_SOURCE_VIEW_TAB_SET,
  payload: data
});

const quality_source = (state) => state[NAME];

export const QualitySourceSelector = createStructuredSelector({
  quality_source
})
