import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_LIST,
  REQUEST_SEARCH_REQUEST_USER_LIST,
  REQUEST_SEARCH_REQUEST_PROCESS_LIST,
  REQUEST_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_ADD,
  REQUEST_DETAIL,
  REQUEST_UPDATE,
} from '../actions';
import {
  RequestListSuccess,
  RequestListError,
  RequestSearchRequestUserListSuccess,
  RequestSearchRequestUserListError,
  RequestSearchRequestProcessListSuccess,
  RequestSearchRequestProcessListError,
  RequestSearchGeoCountryListSuccess,
  RequestSearchGeoCountryListError,
  RequestAddSuccess,
  RequestAddError,
  RequestDetailSuccess,
  RequestDetailError,
  RequestUpdateSuccess,
  RequestUpdateError
} from '../actions';
 
export function* watchRequestList() {
  yield takeEvery(REQUEST_LIST, onRequestList);
}

export function* watchRequestSearchRequestUserList() {
  yield takeEvery(REQUEST_SEARCH_REQUEST_USER_LIST, onRequestSearchRequestUserList);
}

export function* watchRequestSearchRequestProcessList() {
  yield takeEvery(REQUEST_SEARCH_REQUEST_PROCESS_LIST, onRequestSearchRequestProcessList);
}

export function* watchRequestSearchGeoCountryList() {
  yield takeEvery(REQUEST_SEARCH_GEO_COUNTRY_LIST, onRequestSearchGeoCountryList);
}

export function* watchRequestAdd() {
  yield takeEvery(REQUEST_ADD, onRequestAdd);
}

export function* watchRequestDetail() {
  yield takeEvery(REQUEST_DETAIL, onRequestDetail);
}

export function* watchRequestUpdate() {
  yield takeEvery(REQUEST_UPDATE, onRequestUpdate);
}

const onRequestListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onRequestSearchRequestUserListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request_user', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestSearchRequestProcessListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request_process', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country_request', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestList(data) {
  try {
    const response = yield call(onRequestListAsync, data.payload);
    yield put(RequestListSuccess(response.data));
  } catch (error) {
    yield put(RequestListError(error));
  }
}

function* onRequestSearchRequestUserList(data) {
  try {
    console.log('onRequestSearchRequestUserList',data.payload)
    const response = yield call(onRequestSearchRequestUserListAsync, data.payload);
    yield put(RequestSearchRequestUserListSuccess(response.data));
  } catch (error) {
    yield put(RequestSearchRequestUserListError(error));
  }
}

function* onRequestSearchRequestProcessList(data) {
  try {
    const response = yield call(onRequestSearchRequestProcessListAsync, data.payload);
    yield put(RequestSearchRequestProcessListSuccess(response.data));
  } catch (error) {
    yield put(RequestSearchRequestProcessListError(error));
  }
}

function* onRequestSearchGeoCountryList(data) {
  try {
    const response = yield call(onRequestSearchGeoCountryListAsync, data.payload);
    yield put(RequestSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestSearchGeoCountryListError(error));
  }
}

function* onRequestAdd(data) {
  try {
      const response = yield call(onRequestAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestAddSuccess(response.data));
  } catch (error) {
      yield put(RequestAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestDetail(data) {
  try {
    const response = yield call(onRequestDetailAsync, data.payload);
    yield put(RequestDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailError(error));
  }
}

function* onRequestUpdate(data) {
  try {
      const response = yield call(onRequestUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestList),
    fork(watchRequestSearchRequestUserList),
    fork(watchRequestSearchRequestProcessList),
    fork(watchRequestSearchGeoCountryList),
    fork(watchRequestAdd),
    fork(watchRequestDetail),
    fork(watchRequestUpdate),
  ]);
} 