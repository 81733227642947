/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import user from './user/saga';
import upload from './upload/saga';
import app from './app/saga';
import quality_source from './quality_source/saga';
import quality_autoreview from './quality_autoreview/saga';
import autoreview from './autoreview/saga';
import quality_notification from './quality_notification/saga';
import quality_email from './quality_email/saga';
import request from './request/saga';
import request_detail from './request_detail/saga';
import dashboard from './dashboard/saga';
import validate_email from './validate_email/saga';
import process from './process/saga';
import process_detail from './process_detail/saga';
import article from './mspas/article/saga';
import location from './mspas/location/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    user(),
    upload(),
    app(),
    quality_source(),
    quality_autoreview(),
    autoreview(),
    quality_notification(),
    quality_email(),
    request(),
    request_detail(),
    dashboard(),
    validate_email(),
    process(),
    process_detail(),
    article(),
    location()
  ]);
}
