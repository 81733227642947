import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  PROCESS_DETAIL_LIST,
  PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST,
  PROCESS_DETAIL_SEARCH_PROCESS_LIST,
  PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST,
  PROCESS_DETAIL_SEARCH_GEO_REGION_LIST,
  PROCESS_DETAIL_ADD,
  PROCESS_DETAIL_DETAIL,
  PROCESS_DETAIL_UPDATE,
} from '../actions';
import {
  ProcessDetailListSuccess,
  ProcessDetailListError,
  ProcessDetailSearchProcessDetailListSuccess,
  ProcessDetailSearchProcessDetailListError,
  ProcessDetailSearchProcessListSuccess,
  ProcessDetailSearchProcessListError,
  ProcessDetailSearchMedicamentListSuccess,
  ProcessDetailSearchMedicamentListError,
  ProcessDetailSearchGeoRegionListSuccess,
  ProcessDetailSearchGeoRegionListError,
  ProcessDetailAddSuccess,
  ProcessDetailAddError,
  ProcessDetailDetailSuccess,
  ProcessDetailDetailError,
  ProcessDetailUpdateSuccess,
  ProcessDetailUpdateError
} from '../actions';
 
export function* watchProcessDetailList() {
  yield takeEvery(PROCESS_DETAIL_LIST, onProcessDetailList);
}

export function* watchProcessDetailSearchProcessDetailList() {
  yield takeEvery(PROCESS_DETAIL_SEARCH_PROCESS_DETAIL_LIST, onProcessDetailSearchProcessDetailList);
}

export function* watchProcessDetailSearchProcessList() {
  yield takeEvery(PROCESS_DETAIL_SEARCH_PROCESS_LIST, onProcessDetailSearchProcessList);
}

export function* watchProcessDetailSearchMedicamentList() {
  yield takeEvery(PROCESS_DETAIL_SEARCH_MEDICAMENT_LIST, onProcessDetailSearchMedicamentList);
}

export function* watchProcessDetailSearchGeoRegionList() {
  yield takeEvery(PROCESS_DETAIL_SEARCH_GEO_REGION_LIST, onProcessDetailSearchGeoRegionList);
}

export function* watchProcessDetailAdd() {
  yield takeEvery(PROCESS_DETAIL_ADD, onProcessDetailAdd);
}

export function* watchProcessDetailDetail() {
  yield takeEvery(PROCESS_DETAIL_DETAIL, onProcessDetailDetail);
}

export function* watchProcessDetailUpdate() {
  yield takeEvery(PROCESS_DETAIL_UPDATE, onProcessDetailUpdate);
}

const onProcessDetailListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'process_detail/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onProcessDetailUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'process_detail/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onProcessDetailSearchProcessDetailListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/process_detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onProcessDetailSearchProcessListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/process', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onProcessDetailSearchMedicamentListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/medicament', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onProcessDetailSearchGeoRegionListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_region', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onProcessDetailAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'process_detail/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onProcessDetailDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'process_detail/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onProcessDetailList(data) {
  try {
    const response = yield call(onProcessDetailListAsync, data.payload);
    yield put(ProcessDetailListSuccess(response.data));
  } catch (error) {
    yield put(ProcessDetailListError(error));
  }
}

function* onProcessDetailSearchProcessDetailList(data) {
  try {
    const response = yield call(onProcessDetailSearchProcessDetailListAsync, data.payload);
    yield put(ProcessDetailSearchProcessDetailListSuccess(response.data));
  } catch (error) {
    yield put(ProcessDetailSearchProcessDetailListError(error));
  }
}

function* onProcessDetailSearchProcessList(data) {
  try {
    const response = yield call(onProcessDetailSearchProcessListAsync, data.payload);
    yield put(ProcessDetailSearchProcessListSuccess(response.data));
  } catch (error) {
    yield put(ProcessDetailSearchProcessListError(error));
  }
}

function* onProcessDetailSearchMedicamentList(data) {
  try {
    const response = yield call(onProcessDetailSearchMedicamentListAsync, data.payload);
    yield put(ProcessDetailSearchMedicamentListSuccess(response.data));
  } catch (error) {
    yield put(ProcessDetailSearchMedicamentListError(error));
  }
}

function* onProcessDetailSearchGeoRegionList(data) {
  try {
    const response = yield call(onProcessDetailSearchGeoRegionListAsync, data.payload);
    yield put(ProcessDetailSearchGeoRegionListSuccess(response.data));
  } catch (error) {
    yield put(ProcessDetailSearchGeoRegionListError(error));
  }
}

function* onProcessDetailAdd(data) {
  try {
      const response = yield call(onProcessDetailAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(ProcessDetailAddSuccess(response.data));
  } catch (error) {
      yield put(ProcessDetailAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onProcessDetailDetail(data) {
  try {
    const response = yield call(onProcessDetailDetailAsync, data.payload);
    yield put(ProcessDetailDetailSuccess(response.data.data));
  } catch (error) {
    yield put(ProcessDetailDetailError(error));
  }
}

function* onProcessDetailUpdate(data) {
  try {
      const response = yield call(onProcessDetailUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(ProcessDetailUpdateSuccess(response.data));
  } catch (error) {
      yield put(ProcessDetailUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchProcessDetailList),
    fork(watchProcessDetailSearchProcessDetailList),
    fork(watchProcessDetailSearchProcessList),
    fork(watchProcessDetailSearchMedicamentList),
    fork(watchProcessDetailSearchGeoRegionList),
    fork(watchProcessDetailAdd),
    fork(watchProcessDetailDetail),
    fork(watchProcessDetailUpdate),
  ]);
} 