import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../../helpers/Firebase';
import { servicePath } from '../../../constants/defaultValues';
import { NotificationManager } from '../../../components/common/react-notifications';
import { 
  LOCATION_LIST,
  LOCATION_SEARCH_LOCATION_LIST,
  LOCATION_SEARCH_PROCESS_LIST,
  LOCATION_SEARCH_MEDICAMENT_LIST,
  LOCATION_SEARCH_GEO_REGION_LIST,
  LOCATION_ADD,
  LOCATION_DETAIL,
  LOCATION_UPDATE,
} from '../../actions';
import {
  LocationListSuccess,
  LocationListError,
  LocationSearchLocationListSuccess,
  LocationSearchLocationListError,
  LocationSearchProcessListSuccess,
  LocationSearchProcessListError,
  LocationSearchMedicamentListSuccess,
  LocationSearchMedicamentListError,
  LocationSearchGeoRegionListSuccess,
  LocationSearchGeoRegionListError,
  LocationAddSuccess,
  LocationAddError,
  LocationDetailSuccess,
  LocationDetailError,
  LocationUpdateSuccess,
  LocationUpdateError
} from '../../actions';
 
export function* watchLocationList() {
  yield takeEvery(LOCATION_LIST, onLocationList);
}

export function* watchLocationSearchLocationList() {
  yield takeEvery(LOCATION_SEARCH_LOCATION_LIST, onLocationSearchLocationList);
}

export function* watchLocationSearchProcessList() {
  yield takeEvery(LOCATION_SEARCH_PROCESS_LIST, onLocationSearchProcessList);
}

export function* watchLocationSearchMedicamentList() {
  yield takeEvery(LOCATION_SEARCH_MEDICAMENT_LIST, onLocationSearchMedicamentList);
}

export function* watchLocationSearchGeoRegionList() {
  yield takeEvery(LOCATION_SEARCH_GEO_REGION_LIST, onLocationSearchGeoRegionList);
}

export function* watchLocationAdd() {
  yield takeEvery(LOCATION_ADD, onLocationAdd);
}

export function* watchLocationDetail() {
  yield takeEvery(LOCATION_DETAIL, onLocationDetail);
}

export function* watchLocationUpdate() {
  yield takeEvery(LOCATION_UPDATE, onLocationUpdate);
}

const onLocationListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'mspas/location/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onLocationUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'mspas/location/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onLocationSearchLocationListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/location', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onLocationSearchProcessListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/process', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onLocationSearchMedicamentListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/medicament', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onLocationSearchGeoRegionListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_region', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onLocationAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'mspas/location/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onLocationDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'mspas/location/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onLocationList(data) {
  try {
    const response = yield call(onLocationListAsync, data.payload);
    yield put(LocationListSuccess(response.data));
  } catch (error) {
    yield put(LocationListError(error));
  }
}

function* onLocationSearchLocationList(data) {
  try {
    const response = yield call(onLocationSearchLocationListAsync, data.payload);
    yield put(LocationSearchLocationListSuccess(response.data));
  } catch (error) {
    yield put(LocationSearchLocationListError(error));
  }
}

function* onLocationSearchProcessList(data) {
  try {
    const response = yield call(onLocationSearchProcessListAsync, data.payload);
    yield put(LocationSearchProcessListSuccess(response.data));
  } catch (error) {
    yield put(LocationSearchProcessListError(error));
  }
}

function* onLocationSearchMedicamentList(data) {
  try {
    const response = yield call(onLocationSearchMedicamentListAsync, data.payload);
    yield put(LocationSearchMedicamentListSuccess(response.data));
  } catch (error) {
    yield put(LocationSearchMedicamentListError(error));
  }
}

function* onLocationSearchGeoRegionList(data) {
  try {
    const response = yield call(onLocationSearchGeoRegionListAsync, data.payload);
    yield put(LocationSearchGeoRegionListSuccess(response.data));
  } catch (error) {
    yield put(LocationSearchGeoRegionListError(error));
  }
}

function* onLocationAdd(data) {
  try {
      const response = yield call(onLocationAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(LocationAddSuccess(response.data));
  } catch (error) {
      yield put(LocationAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onLocationDetail(data) {
  try {
    const response = yield call(onLocationDetailAsync, data.payload);
    yield put(LocationDetailSuccess(response.data.data));
  } catch (error) {
    yield put(LocationDetailError(error));
  }
}

function* onLocationUpdate(data) {
  try {
      const response = yield call(onLocationUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(LocationUpdateSuccess(response.data));
  } catch (error) {
      yield put(LocationUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLocationList),
    fork(watchLocationSearchLocationList),
    fork(watchLocationSearchProcessList),
    fork(watchLocationSearchMedicamentList),
    fork(watchLocationSearchGeoRegionList),
    fork(watchLocationAdd),
    fork(watchLocationDetail),
    fork(watchLocationUpdate),
  ]);
} 