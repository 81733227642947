import { createStructuredSelector } from 'reselect'

import {
  QUALITY_NOTIFICATION_LIST,
  QUALITY_NOTIFICATION_LIST_SUCCESS,
  QUALITY_NOTIFICATION_LIST_ERROR,
  QUALITY_NOTIFICATION_PAGE_SET,
  QUALITY_NOTIFICATION_SEARCH_SET,
  QUALITY_NOTIFICATION_SEARCH_CHANGE,
  QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST,
  QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST_SUCCESS,
  QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST_ERROR,
  QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST,
  QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST_ERROR,
  QUALITY_NOTIFICATION_ADD_SET,
  QUALITY_NOTIFICATION_ADD_MODAL_SET,
  QUALITY_NOTIFICATION_ADD,
  QUALITY_NOTIFICATION_ADD_SUCCESS,
  QUALITY_NOTIFICATION_ADD_ERROR,
  QUALITY_NOTIFICATION_ADD_CHANGE,
  QUALITY_NOTIFICATION_DETAIL,
  QUALITY_NOTIFICATION_DETAIL_SUCCESS,
  QUALITY_NOTIFICATION_DETAIL_ERROR,
  QUALITY_NOTIFICATION_VIEW_MODAL_SET,
  QUALITY_NOTIFICATION_EDIT_CHANGE,
  QUALITY_NOTIFICATION_EDIT_MODAL_SET,
  QUALITY_NOTIFICATION_UPDATE,
  QUALITY_NOTIFICATION_UPDATE_SUCCESS,
  QUALITY_NOTIFICATION_UPDATE_ERROR,
  QUALITY_NOTIFICATION_VIEW_TAB_SET
} from '../actions';

export const NAME = 'quality_notification';

export const QualityNotificationList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: QUALITY_NOTIFICATION_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const QualityNotificationListSuccess = (data) => ({
  type: QUALITY_NOTIFICATION_LIST_SUCCESS,
  payload: data
});

export const QualityNotificationListError = (error) => ({
  type: QUALITY_NOTIFICATION_LIST_ERROR,
  payload: error
});

export const QualityNotificationPageSet = (data) => ({
  type: QUALITY_NOTIFICATION_PAGE_SET,
  payload: data
});

export const QualityNotificationSearchSet = (data) => ({
  type: QUALITY_NOTIFICATION_SEARCH_SET,
  payload: data
});

export const QualityNotificationSearchChangeSet = (data) => ({
  type: QUALITY_NOTIFICATION_SEARCH_CHANGE,
  payload: data
});

export const QualityNotificationSearchRequestList = (data) => ({
  type: QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST,
  payload: data
});

export const QualityNotificationSearchRequestListSuccess = (message) => ({
  type: QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST_SUCCESS,
  payload: message
});

export const QualityNotificationSearchRequestListError = (error) => ({
  type: QUALITY_NOTIFICATION_SEARCH_REQUEST_LIST_ERROR,
  payload: error
}); 

export const QualityNotificationSearchGeoCountryList = (data) => ({
  type: QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const QualityNotificationSearchGeoCountryListSuccess = (message) => ({
  type: QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const QualityNotificationSearchGeoCountryListError = (error) => ({
  type: QUALITY_NOTIFICATION_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 

export const QualityNotificationAddChangeSet = (data) => ({
  type: QUALITY_NOTIFICATION_ADD_CHANGE,
  payload: data
});

export const QualityNotificationAddSet = (data) => ({
  type: QUALITY_NOTIFICATION_ADD_SET,
  payload: data
});

export const QualityNotificationAddModalSet = (data) => ({
  type: QUALITY_NOTIFICATION_ADD_MODAL_SET,
  payload: data
});

export const QualityNotificationAdd = (data) => ({
  type: QUALITY_NOTIFICATION_ADD,
  payload: data
});

export const QualityNotificationAddSuccess = (message) => ({
  type: QUALITY_NOTIFICATION_ADD_SUCCESS,
  payload: message
});

export const QualityNotificationAddError = (error) => ({
  type: QUALITY_NOTIFICATION_ADD_ERROR,
  payload: error
});

export const QualityNotificationDetail = (data) => ({
  type: QUALITY_NOTIFICATION_DETAIL,
  payload: data
});

export const QualityNotificationDetailSuccess = (data) => ({
  type: QUALITY_NOTIFICATION_DETAIL_SUCCESS,
  payload: data
});

export const QualityNotificationDetailError = (error) => ({
  type: QUALITY_NOTIFICATION_DETAIL_ERROR,
  payload: error
});

export const QualityNotificationViewModalSet = (data) => ({
  type: QUALITY_NOTIFICATION_VIEW_MODAL_SET,
  payload: data
});

export const QualityNotificationEditModalSet = (data) => ({
  type: QUALITY_NOTIFICATION_EDIT_MODAL_SET,
  payload: data
});

export const QualityNotificationEditChangeSet = (data) => ({
  type: QUALITY_NOTIFICATION_EDIT_CHANGE,
  payload: data
});

export const QualityNotificationUpdate = (data) => ({
  type: QUALITY_NOTIFICATION_UPDATE,
  payload: data
});

export const QualityNotificationUpdateSuccess = (message) => ({
  type: QUALITY_NOTIFICATION_UPDATE_SUCCESS,
  payload: message.message
});

export const QualityNotificationUpdateError = (error) => ({
  type: QUALITY_NOTIFICATION_UPDATE_ERROR,
  payload: error
});

export const QualityNotificationViewTabSet = (data) => ({
  type: QUALITY_NOTIFICATION_VIEW_TAB_SET,
  payload: data
});

const quality_notification = (state) => state[NAME];

export const QualityNotificationSelector = createStructuredSelector({
  quality_notification
})
