import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  AUTOREVIEW_LIST,
  AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST,
  AUTOREVIEW_SEARCH_CODE22_LIST,
  AUTOREVIEW_SEARCH_TAG_LIST,
  AUTOREVIEW_ADD,
  AUTOREVIEW_VIEW,
  AUTOREVIEW_UPDATE,
} from '../actions';
import {
  AutoReviewListSuccess,
  AutoReviewListError,
  AutoReviewSearchQualitySourceListSuccess,
  AutoReviewSearchQualitySourceListError,
  AutoReviewSearchCode22ListSuccess,
  AutoReviewSearchCode22ListError,
  AutoReviewSearchTagListSuccess,
  AutoReviewSearchTagListError,
  AutoReviewAddSuccess,
  AutoReviewAddError,
  AutoReviewViewSuccess,
  AutoReviewViewError,
  AutoReviewUpdateSuccess,
  AutoReviewUpdateError
} from '../actions';
 
export function* watchAutoReviewList() {
  yield takeEvery(AUTOREVIEW_LIST, onAutoReviewList);
}

export function* watchAutoReviewSearchQualitySourceList() {
  yield takeEvery(AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST, onAutoReviewSearchQualitySourceList);
}

export function* watchAutoReviewSearchCode22List() {
  yield takeEvery(AUTOREVIEW_SEARCH_CODE22_LIST, onAutoReviewSearchCode22List);
}

export function* watchAutoReviewSearchTagList() {
  yield takeEvery(AUTOREVIEW_SEARCH_TAG_LIST, onAutoReviewSearchTagList);
}

export function* watchAutoReviewAdd() {
  yield takeEvery(AUTOREVIEW_ADD, onAutoReviewAdd);
}

export function* watchAutoReviewView() {
  yield takeEvery(AUTOREVIEW_VIEW, onAutoReviewView);
}

export function* watchAutoReviewUpdate() {
  yield takeEvery(AUTOREVIEW_UPDATE, onAutoReviewUpdate);
}

const onAutoReviewListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'autoreview/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onAutoReviewUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'autoreview/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onAutoReviewSearchQualitySourceListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/quality_source', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onAutoReviewSearchCode22ListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/code22_product', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onAutoReviewSearchTagListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/tag', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onAutoReviewAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'autoreview/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onAutoReviewViewAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'autoreview/view', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onAutoReviewList(data) {
  try {
    const response = yield call(onAutoReviewListAsync, data.payload);
    yield put(AutoReviewListSuccess(response.data));
  } catch (error) {
    yield put(AutoReviewListError(error));
  }
}

function* onAutoReviewSearchQualitySourceList(data) {
  try {
    const response = yield call(onAutoReviewSearchQualitySourceListAsync, data.payload);
    yield put(AutoReviewSearchQualitySourceListSuccess(response.data));
  } catch (error) {
    yield put(AutoReviewSearchQualitySourceListError(error));
  }
}

function* onAutoReviewSearchCode22List(data) {
  try {
    const response = yield call(onAutoReviewSearchCode22ListAsync, data.payload);
    yield put(AutoReviewSearchCode22ListSuccess(response.data));
  } catch (error) {
    yield put(AutoReviewSearchCode22ListError(error));
  }
}

function* onAutoReviewSearchTagList(data) {
  try {
    const response = yield call(onAutoReviewSearchTagListAsync, data.payload);
    yield put(AutoReviewSearchTagListSuccess(response.data));
  } catch (error) {
    yield put(AutoReviewSearchTagListError(error));
  }
}

function* onAutoReviewAdd(data) {
  try {
      const response = yield call(onAutoReviewAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(AutoReviewAddSuccess(response.data));
  } catch (error) {
      yield put(AutoReviewAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onAutoReviewView(data) {
  try {
    const response = yield call(onAutoReviewViewAsync, data.payload);
    yield put(AutoReviewViewSuccess(response.data.data));
  } catch (error) {
    yield put(AutoReviewViewError(error));
  }
}

function* onAutoReviewUpdate(data) {
  try {
      const response = yield call(onAutoReviewUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(AutoReviewUpdateSuccess(response.data));
  } catch (error) {
      yield put(AutoReviewUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAutoReviewList),
    fork(watchAutoReviewSearchQualitySourceList),
    fork(watchAutoReviewSearchCode22List),
    fork(watchAutoReviewSearchTagList),
    fork(watchAutoReviewAdd),
    fork(watchAutoReviewView),
    fork(watchAutoReviewUpdate),
  ]);
} 