import { createStructuredSelector } from 'reselect'

import {
    VALIDATE_EMAIL,
    VALIDATE_EMAIL_SUCCESS,
    VALIDATE_EMAIL_ERROR
} from '../actions';

export const NAME = 'validate_email';

export const ValidateEmail = (user, history) => ({
    type: VALIDATE_EMAIL,
    payload: { user, history },
});
export const ValidateEmailSuccess = (user) => ({
    type: VALIDATE_EMAIL_SUCCESS,
    payload: user,
});
export const ValidateEmailError = (message) => ({
    type: VALIDATE_EMAIL_ERROR,
    payload: { message },
});

const validate_email = (state) => state[NAME];

export const ValidateEmailUserSelector = createStructuredSelector({
    validate_email
})
