import { createStructuredSelector } from "reselect";
import {
    DASHBOARD_LIST,
    DASHBOARD_LIST_SUCCESS,
    DASHBOARD_LIST_ERROR,
    DASHBOARD_SEARCH_SET,
    DASHBOARD_SEARCH_CHANGE,
    ARTICLE_CODE22,
    ARTICLE_CODE22_SUCCESS,
    ARTICLE_CODE22_ERROR,
    DASHBOARD_UNIT_PRICE,
    DASHBOARD_UNIT_PRICE_SUCCESS,
    DASHBOARD_UNIT_PRICE_ERROR,
    DASHBOARD_SOURCE,
    DASHBOARD_SOURCE_SUCCESS,
    DASHBOARD_SOURCE_ERROR,
    DASHBOARD_YEAR,
    DASHBOARD_YEAR_SUCCESS,
    DASHBOARD_YEAR_ERROR,
    DASHBOARD_PRICES,
    DASHBOARD_PRICES_SUCCESS,
    DASHBOARD_PRICES_ERROR,
    DASHBOARD_TRANSACTIONS,
    DASHBOARD_TRANSACTIONS_SUCCESS,
    DASHBOARD_TRANSACTIONS_ERROR,
    DASHBOARD_RAZONABILIDAD,
    DASHBOARD_RAZONABILIDAD_SUCCESS,
    DASHBOARD_RAZONABILIDAD_ERROR,
    DASHBOARD_DATA_SUPPLIER,
    DASHBOARD_DATA_SUPPLIER_SUCCESS,
    DASHBOARD_DATA_SUPPLIER_ERROR,
    DASHBOARD_DATA_SUPPLIER_PAGE_SET
} from "../actions";

export const NAME = 'dashboard';

export const DashboardList = (filters, stateFilter) => ({
    type: DASHBOARD_LIST,
    payload: { filters: filters, filtersState: stateFilter }
});

export const DashboardListSuccess = (data) => ({
    type: DASHBOARD_LIST_SUCCESS,
    payload: data
});

export const DashboardListError = (error) => ({
    type: DASHBOARD_LIST_ERROR,
    payload: error
});

export const DashboardSearchSet = (data) => ({
    type: DASHBOARD_SEARCH_SET,
    payload: data
});

export const DashboardSearchChangeSet = (data) => ({
    type: DASHBOARD_SEARCH_CHANGE,
    payload: data
});

export const ArticleCode22 = (filters) => ({
    type: ARTICLE_CODE22,
    payload: { filters: filters }
});

export const ArticleCode22Success = (data) => ({
    type: ARTICLE_CODE22_SUCCESS,
    payload: data
});

export const ArticleCode22Error = (error) => ({
    type: ARTICLE_CODE22_ERROR,
    payload: error
});

export const DashboardUnitPrice = (filters) => ({
    type: DASHBOARD_UNIT_PRICE,
    payload: { filters: filters }
});

export const DashboardUnitPriceSuccess = (data) => ({
    type: DASHBOARD_UNIT_PRICE_SUCCESS,
    payload: data
});

export const DashboardUnitPriceError = (error) => ({
    type: DASHBOARD_UNIT_PRICE_ERROR,
    payload: error
});

export const DashboardSource = (filters) => ({
    type: DASHBOARD_SOURCE,
    payload: { filters: filters }
});

export const DashboardSourceSuccess = (data) => ({
    type: DASHBOARD_SOURCE_SUCCESS,
    payload: data
});

export const DashboardSourceError = (error) => ({
    type: DASHBOARD_SOURCE_ERROR,
    payload: error
});

export const DashboardYear = (filters) => ({
    type: DASHBOARD_YEAR,
    payload: { filters: filters }
});

export const DashboardYearSuccess = (data) => ({
    type: DASHBOARD_YEAR_SUCCESS,
    payload: data
});

export const DashboardYearError = (error) => ({
    type: DASHBOARD_YEAR_ERROR,
    payload: error
});

export const DashboardPrices = (filters) => ({
    type: DASHBOARD_PRICES,
    payload: { filters: filters }
});

export const DashboardPricesSuccess = (data) => ({
    type: DASHBOARD_PRICES_SUCCESS,
    payload: data
});

export const DashboardPricesError = (error) => ({
    type: DASHBOARD_PRICES_ERROR,
    payload: error
});

export const DashboardTransactions = (filters) => ({
    type: DASHBOARD_TRANSACTIONS,
    payload: { filters: filters }
});

export const DashboardTransactionsSuccess = (data) => ({
    type: DASHBOARD_TRANSACTIONS_SUCCESS,
    payload: data
});

export const DashboardTransactionsError = (error) => ({
    type: DASHBOARD_TRANSACTIONS_ERROR,
    payload: error
});

export const DashboardRazonabilidad = (filters) => ({
    type: DASHBOARD_RAZONABILIDAD,
    payload: { filters: filters }
});

export const DashboardRazonabilidadSuccess = (data) => ({
    type: DASHBOARD_RAZONABILIDAD_SUCCESS,
    payload: data
});

export const DashboardRazonabilidadError = (error) => ({
    type: DASHBOARD_RAZONABILIDAD_ERROR,
    payload: error
});

export const DashboardDataSupplier = (page, num_page, filters, data) => ({
    type: DASHBOARD_DATA_SUPPLIER,
    payload: { page: page, num_page: num_page,  filters: data, data: data }
});

export const DashboardDataSupplierSuccess = (data) => ({
    type: DASHBOARD_DATA_SUPPLIER_SUCCESS,
    payload: data
});

export const DashboardDataSupplierError = (error) => ({
    type: DASHBOARD_DATA_SUPPLIER_ERROR,
    payload: error
});

export const DashboardDataSupplierPageSet = (data) => ({
    type: DASHBOARD_DATA_SUPPLIER_PAGE_SET,
    payload: data
});


const dashboard = (state) => state[NAME];

export const DashboardSelector = createStructuredSelector({
    dashboard
})
