import { createStructuredSelector } from 'reselect'

import {
  AUTOREVIEW_LIST,
  AUTOREVIEW_LIST_SUCCESS,
  AUTOREVIEW_LIST_ERROR,
  AUTOREVIEW_PAGE_SET,
  AUTOREVIEW_SEARCH_SET,
  AUTOREVIEW_SEARCH_CHANGE,
  AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST,
  AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_SUCCESS,
  AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_ERROR,
  AUTOREVIEW_SEARCH_CODE22_LIST,
  AUTOREVIEW_SEARCH_CODE22_LIST_SUCCESS,
  AUTOREVIEW_SEARCH_CODE22_LIST_ERROR,
  AUTOREVIEW_SEARCH_TAG_LIST,
  AUTOREVIEW_SEARCH_TAG_LIST_SUCCESS,
  AUTOREVIEW_SEARCH_TAG_LIST_ERROR,
  AUTOREVIEW_SEARCH_TAG_SET,
  AUTOREVIEW_ADD_SET,
  AUTOREVIEW_ADD_MODAL_SET,
  AUTOREVIEW_ADD,
  AUTOREVIEW_ADD_SUCCESS,
  AUTOREVIEW_ADD_ERROR,
  AUTOREVIEW_ADD_CHANGE,
  AUTOREVIEW_VIEW,
  AUTOREVIEW_VIEW_SUCCESS,
  AUTOREVIEW_VIEW_ERROR,
  AUTOREVIEW_VIEW_MODAL_SET,
  AUTOREVIEW_VIEW_TAB_SET,
  AUTOREVIEW_EDIT_CHANGE,
  AUTOREVIEW_EDIT_MODAL_SET,
  AUTOREVIEW_UPDATE,
  AUTOREVIEW_UPDATE_SUCCESS,
  AUTOREVIEW_UPDATE_ERROR
} from '../actions';

export const NAME = 'autoreview';

export const AutoReviewList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: AUTOREVIEW_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const AutoReviewListSuccess = (data) => ({
  type: AUTOREVIEW_LIST_SUCCESS,
  payload: data
});

export const AutoReviewListError = (error) => ({
  type: AUTOREVIEW_LIST_ERROR,
  payload: error
});

export const AutoReviewPageSet = (data) => ({
  type: AUTOREVIEW_PAGE_SET,
  payload: data
});

export const AutoReviewSearchSet = (data) => ({
  type: AUTOREVIEW_SEARCH_SET,
  payload: data
});

export const AutoReviewSearchTagSet = (data) => ({
  type: AUTOREVIEW_SEARCH_TAG_SET,
  payload: data
});

export const AutoReviewSearchChangeSet = (data) => ({
  type: AUTOREVIEW_SEARCH_CHANGE,
  payload: data
});

export const AutoReviewSearchQualitySourceList = (data) => ({
  type: AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST,
  payload: data
});

export const AutoReviewSearchQualitySourceListSuccess = (message) => ({
  type: AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_SUCCESS,
  payload: message
});

export const AutoReviewSearchQualitySourceListError = (error) => ({
  type: AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_ERROR,
  payload: error
}); 

export const AutoReviewSearchCode22List = (data) => ({
  type: AUTOREVIEW_SEARCH_CODE22_LIST,
  payload: data
});

export const AutoReviewSearchCode22ListSuccess = (message) => ({
  type: AUTOREVIEW_SEARCH_CODE22_LIST_SUCCESS,
  payload: message
});

export const AutoReviewSearchCode22ListError = (error) => ({
  type: AUTOREVIEW_SEARCH_CODE22_LIST_ERROR,
  payload: error
}); 

export const AutoReviewSearchTagList = (data) => ({
  type: AUTOREVIEW_SEARCH_TAG_LIST,
  payload: data
});

export const AutoReviewSearchTagListSuccess = (message) => ({
  type: AUTOREVIEW_SEARCH_TAG_LIST_SUCCESS,
  payload: message
});

export const AutoReviewSearchTagListError = (error) => ({
  type: AUTOREVIEW_SEARCH_TAG_LIST_ERROR,
  payload: error
}); 

export const AutoReviewAddChangeSet = (data) => ({
  type: AUTOREVIEW_ADD_CHANGE,
  payload: data
});

export const AutoReviewAddSet = (data) => ({
  type: AUTOREVIEW_ADD_SET,
  payload: data
});

export const AutoReviewAddModalSet = (data) => ({
  type: AUTOREVIEW_ADD_MODAL_SET,
  payload: data
});

export const AutoReviewAdd = (data) => ({
  type: AUTOREVIEW_ADD,
  payload: data
});

export const AutoReviewAddSuccess = (message) => ({
  type: AUTOREVIEW_ADD_SUCCESS,
  payload: message
});

export const AutoReviewAddError = (error) => ({
  type: AUTOREVIEW_ADD_ERROR,
  payload: error
});

export const AutoReviewView = (data) => ({
  type: AUTOREVIEW_VIEW,
  payload: data
});

export const AutoReviewViewSuccess = (data) => ({
  type: AUTOREVIEW_VIEW_SUCCESS,
  payload: data
});

export const AutoReviewViewError = (error) => ({
  type: AUTOREVIEW_VIEW_ERROR,
  payload: error
});

export const AutoReviewViewModalSet = (data) => ({
  type: AUTOREVIEW_VIEW_MODAL_SET,
  payload: data
});

export const AutoReviewEditModalSet = (data) => ({
  type: AUTOREVIEW_EDIT_MODAL_SET,
  payload: data
});

export const AutoReviewEditChangeSet = (data) => ({
  type: AUTOREVIEW_EDIT_CHANGE,
  payload: data
});

export const AutoReviewUpdate = (data) => ({
  type: AUTOREVIEW_UPDATE,
  payload: data
});

export const AutoReviewUpdateSuccess = (message) => ({
  type: AUTOREVIEW_UPDATE_SUCCESS,
  payload: message.message
});

export const AutoReviewUpdateError = (error) => ({
  type: AUTOREVIEW_UPDATE_ERROR,
  payload: error
});

export const AutoReviewViewTabSet = (data) => ({
  type: AUTOREVIEW_VIEW_TAB_SET,
  payload: data
});

const autoreview = (state) => state[NAME];

export const AutoReviewSelector = createStructuredSelector({
  autoreview
})
