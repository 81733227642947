import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import {
    USER_ACTIONS_LIST,
} from '../actions';

import {
    UserActionsListSuccess,
    UserActionsListError
} from '../actions';

export function* watchUserActionsList() {
    yield takeEvery(USER_ACTIONS_LIST, onUserActionsList);
}

function getToken(auth) {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            user.getIdToken().then((token) => {
                unsubscribe();
                resolve(token);
            });
        }, reject);
    });
}

const onUserActionsListAsync = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'user/actions', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

function* onUserActionsList(data) {
    try {
        const response = yield call(onUserActionsListAsync, data.payload);
        yield put(UserActionsListSuccess(response.data));
    } catch (error) {
        yield put(UserActionsListError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchUserActionsList)
    ]);
}
