import { createStructuredSelector } from 'reselect'

import {
  REQUEST_LIST,
  REQUEST_LIST_SUCCESS,
  REQUEST_LIST_ERROR,
  REQUEST_PAGE_SET,
  REQUEST_SEARCH_SET,
  REQUEST_SEARCH_CHANGE,
  REQUEST_SEARCH_REQUEST_USER_LIST,
  REQUEST_SEARCH_REQUEST_USER_LIST_SUCCESS,
  REQUEST_SEARCH_REQUEST_USER_LIST_ERROR,
  REQUEST_SEARCH_REQUEST_PROCESS_LIST,
  REQUEST_SEARCH_REQUEST_PROCESS_LIST_SUCCESS,
  REQUEST_SEARCH_REQUEST_PROCESS_LIST_ERROR,
  REQUEST_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_ADD_SET,
  REQUEST_ADD_MODAL_SET,
  REQUEST_ADD,
  REQUEST_ADD_SUCCESS,
  REQUEST_ADD_ERROR,
  REQUEST_ADD_CHANGE,
  REQUEST_DETAIL,
  REQUEST_DETAIL_SUCCESS,
  REQUEST_DETAIL_ERROR,
  REQUEST_VIEW_MODAL_SET,
  REQUEST_EDIT_CHANGE,
  REQUEST_EDIT_MODAL_SET,
  REQUEST_UPDATE,
  REQUEST_UPDATE_SUCCESS,
  REQUEST_UPDATE_ERROR,
  REQUEST_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request';

export const RequestList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestListSuccess = (data) => ({
  type: REQUEST_LIST_SUCCESS,
  payload: data
});

export const RequestListError = (error) => ({
  type: REQUEST_LIST_ERROR,
  payload: error
});

export const RequestPageSet = (data) => ({
  type: REQUEST_PAGE_SET,
  payload: data
});

export const RequestSearchSet = (data) => ({
  type: REQUEST_SEARCH_SET,
  payload: data
});

export const RequestSearchChangeSet = (data) => ({
  type: REQUEST_SEARCH_CHANGE,
  payload: data
});

export const RequestSearchRequestUserList = (data) => ({
  type: REQUEST_SEARCH_REQUEST_USER_LIST,
  payload: data
});

export const RequestSearchRequestUserListSuccess = (message) => ({
  type: REQUEST_SEARCH_REQUEST_USER_LIST_SUCCESS,
  payload: message
});

export const RequestSearchRequestUserListError = (error) => ({
  type: REQUEST_SEARCH_REQUEST_USER_LIST_ERROR,
  payload: error
}); 

export const RequestSearchRequestProcessList = (data) => ({
  type: REQUEST_SEARCH_REQUEST_PROCESS_LIST,
  payload: data
});

export const RequestSearchRequestProcessListSuccess = (message) => ({
  type: REQUEST_SEARCH_REQUEST_PROCESS_LIST_SUCCESS,
  payload: message
});

export const RequestSearchRequestProcessListError = (error) => ({
  type: REQUEST_SEARCH_REQUEST_PROCESS_LIST_ERROR,
  payload: error
}); 

export const RequestSearchGeoCountryList = (data) => ({
  type: REQUEST_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestSearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestSearchGeoCountryListError = (error) => ({
  type: REQUEST_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 

export const RequestAddChangeSet = (data) => ({
  type: REQUEST_ADD_CHANGE,
  payload: data
});

export const RequestAddSet = (data) => ({
  type: REQUEST_ADD_SET,
  payload: data
});

export const RequestAddModalSet = (data) => ({
  type: REQUEST_ADD_MODAL_SET,
  payload: data
});

export const RequestAdd = (data) => ({
  type: REQUEST_ADD,
  payload: data
});

export const RequestAddSuccess = (message) => ({
  type: REQUEST_ADD_SUCCESS,
  payload: message
});

export const RequestAddError = (error) => ({
  type: REQUEST_ADD_ERROR,
  payload: error
});

export const RequestDetail = (data) => ({
  type: REQUEST_DETAIL,
  payload: data
});

export const RequestDetailSuccess = (data) => ({
  type: REQUEST_DETAIL_SUCCESS,
  payload: data
});

export const RequestDetailError = (error) => ({
  type: REQUEST_DETAIL_ERROR,
  payload: error
});

export const RequestViewModalSet = (data) => ({
  type: REQUEST_VIEW_MODAL_SET,
  payload: data
});

export const RequestEditModalSet = (data) => ({
  type: REQUEST_EDIT_MODAL_SET,
  payload: data
});

export const RequestEditChangeSet = (data) => ({
  type: REQUEST_EDIT_CHANGE,
  payload: data
});

export const RequestUpdate = (data) => ({
  type: REQUEST_UPDATE,
  payload: data
});

export const RequestUpdateSuccess = (message) => ({
  type: REQUEST_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestUpdateError = (error) => ({
  type: REQUEST_UPDATE_ERROR,
  payload: error
});

export const RequestViewTabSet = (data) => ({
  type: REQUEST_VIEW_TAB_SET,
  payload: data
});

const request = (state) => state[NAME];

export const RequestSelector = createStructuredSelector({
  request
})
