import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  PROCESS_LIST,
  PROCESS_SEARCH_PROCESS_USER_LIST,
  PROCESS_SEARCH_PROCESS_PROCESS_LIST,
  PROCESS_SEARCH_GEO_COUNTRY_LIST,
  PROCESS_ADD,
  PROCESS_DETAIL,
  PROCESS_UPDATE,
} from '../actions';
import {
  ProcessListSuccess,
  ProcessListError,
  ProcessSearchProcessUserListSuccess,
  ProcessSearchProcessUserListError,
  ProcessSearchProcessProcessListSuccess,
  ProcessSearchProcessProcessListError,
  ProcessSearchGeoCountryListSuccess,
  ProcessSearchGeoCountryListError,
  ProcessAddSuccess,
  ProcessAddError,
  ProcessDetailSuccess,
  ProcessDetailError,
  ProcessUpdateSuccess,
  ProcessUpdateError
} from '../actions';
 
export function* watchProcessList() {
  yield takeEvery(PROCESS_LIST, onProcessList);
}

export function* watchProcessSearchProcessUserList() {
  yield takeEvery(PROCESS_SEARCH_PROCESS_USER_LIST, onProcessSearchProcessUserList);
}

export function* watchProcessSearchProcessProcessList() {
  yield takeEvery(PROCESS_SEARCH_PROCESS_PROCESS_LIST, onProcessSearchProcessProcessList);
}

export function* watchProcessSearchGeoCountryList() {
  yield takeEvery(PROCESS_SEARCH_GEO_COUNTRY_LIST, onProcessSearchGeoCountryList);
}

export function* watchProcessAdd() {
  yield takeEvery(PROCESS_ADD, onProcessAdd);
}

export function* watchProcessDetail() {
  yield takeEvery(PROCESS_DETAIL, onProcessDetail);
}

export function* watchProcessUpdate() {
  yield takeEvery(PROCESS_UPDATE, onProcessUpdate);
}

const onProcessListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'process/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onProcessUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'process/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onProcessSearchProcessUserListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/process_user', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onProcessSearchProcessProcessListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/process_process', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onProcessSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country_process', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onProcessAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'process/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onProcessDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'process/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onProcessList(data) {
  try {
    const response = yield call(onProcessListAsync, data.payload);
    yield put(ProcessListSuccess(response.data));
  } catch (error) {
    yield put(ProcessListError(error));
  }
}

function* onProcessSearchProcessUserList(data) {
  try {
    console.log('onProcessSearchProcessUserList',data.payload)
    const response = yield call(onProcessSearchProcessUserListAsync, data.payload);
    yield put(ProcessSearchProcessUserListSuccess(response.data));
  } catch (error) {
    yield put(ProcessSearchProcessUserListError(error));
  }
}

function* onProcessSearchProcessProcessList(data) {
  try {
    const response = yield call(onProcessSearchProcessProcessListAsync, data.payload);
    yield put(ProcessSearchProcessProcessListSuccess(response.data));
  } catch (error) {
    yield put(ProcessSearchProcessProcessListError(error));
  }
}

function* onProcessSearchGeoCountryList(data) {
  try {
    const response = yield call(onProcessSearchGeoCountryListAsync, data.payload);
    yield put(ProcessSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(ProcessSearchGeoCountryListError(error));
  }
}

function* onProcessAdd(data) {
  try {
      const response = yield call(onProcessAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(ProcessAddSuccess(response.data));
  } catch (error) {
      yield put(ProcessAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onProcessDetail(data) {
  try {
    const response = yield call(onProcessDetailAsync, data.payload);
    yield put(ProcessDetailSuccess(response.data.data));
  } catch (error) {
    yield put(ProcessDetailError(error));
  }
}

function* onProcessUpdate(data) {
  try {
      const response = yield call(onProcessUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(ProcessUpdateSuccess(response.data));
  } catch (error) {
      yield put(ProcessUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchProcessList),
    fork(watchProcessSearchProcessUserList),
    fork(watchProcessSearchProcessProcessList),
    fork(watchProcessSearchGeoCountryList),
    fork(watchProcessAdd),
    fork(watchProcessDetail),
    fork(watchProcessUpdate),
  ]);
} 