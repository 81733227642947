import {
  LOCATION_LIST, 
  LOCATION_LIST_SUCCESS,
  LOCATION_LIST_ERROR,
  LOCATION_SEARCH_SET,
  LOCATION_SEARCH_CHANGE, 
  LOCATION_PAGE_SET,
  LOCATION_SEARCH_LOCATION_LIST,
  LOCATION_SEARCH_LOCATION_LIST_SUCCESS,
  LOCATION_SEARCH_LOCATION_LIST_ERROR,
  LOCATION_SEARCH_PROCESS_LIST,
  LOCATION_SEARCH_PROCESS_LIST_SUCCESS,
  LOCATION_SEARCH_PROCESS_LIST_ERROR,
  LOCATION_SEARCH_MEDICAMENT_LIST,
  LOCATION_SEARCH_MEDICAMENT_LIST_SUCCESS,
  LOCATION_SEARCH_MEDICAMENT_LIST_ERROR,
  LOCATION_SEARCH_GEO_REGION_LIST,
  LOCATION_SEARCH_GEO_REGION_LIST_SUCCESS,
  LOCATION_SEARCH_GEO_REGION_LIST_ERROR,
  LOCATION_ADD_SET,
  LOCATION_ADD_MODAL_SET,
  LOCATION_ADD,
  LOCATION_ADD_SUCCESS,
  LOCATION_ADD_ERROR,
  LOCATION_ADD_CHANGE,
  LOCATION_DETAIL,
  LOCATION_DETAIL_SUCCESS,
  LOCATION_DETAIL_ERROR,
  LOCATION_VIEW_MODAL_SET,
  LOCATION_EDIT_CHANGE,
  LOCATION_EDIT_MODAL_SET,
  LOCATION_UPDATE,
  LOCATION_UPDATE_SUCCESS,
  LOCATION_UPDATE_ERROR,
  LOCATION_VIEW_TAB_SET,
  LOCATION_SEARCH_LOCATION_SET
} from '../../actions';
 
const INIT_STATE = {
  loading: true,
  list: {
    header:{
      name: 'code',
      order:'sorted-asc'
    },
    page:{
      current: 1,
      rows: 2000
    }
  },
  add: {
      data: {
        new:{
          quantity: 0
        }
      },
      modal: false,
      loading: false,
      message: '',
      url: '',
      type: ''
  },
  error: false,
  search:{
    modal: false,
    init:true,
    data:{
      location: false,
      process: false
    },
    value:{
      location: false,
      process: false
    },
    location:{
      loading: true,
      list:[],
      message: ''
    },
    process:{
      loading: true,
      list:[],
      message: ''
    },
    medicament:{
      loading: true,
      list:[],
      message: ''
    },
    geo_region:{
      loading: true,
      list:[],
      message: ''
    }
  },
  detail: {
    data: [],
    tab: '1',
    loading: true,
  },
  view: {
    loading: true,
    data: [],
    tab: '1',
    modal: false,
    log: {
      data: [],
      loading: false,
    },
  },
  edit: {
    loading: true,
    data: {
        new: [],
        old: []
    },
    list: {
        status: {
            data: [],
            loading: false,
        }
    },
    modal: false,
    update: {
        loading: false,
        response: '',
        message: ''
    }
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOCATION_LIST:
      return {
        ...state,
        loading: true,
        list: {
          ...state.list
        }
        
      }
    case LOCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          ...action.payload
        } 
      }
    case LOCATION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }
    case LOCATION_SEARCH_SET:
      return {
        ...state,
        search:{
          ...state.search,
          data: action.payload,
        }
      }
    case LOCATION_SEARCH_CHANGE:
      return {
        ...state,
        search:{
          ...state.search,
          value: action.payload,
        }
      }
    case LOCATION_PAGE_SET:
      return {
        ...state,
        list: {
          ...state.list,
          page: {
            ...state.list.page,
            ... action.payload,
          }
        },
        log: []
      }

    case LOCATION_SEARCH_LOCATION_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          location:{
            ...state.search.location,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case LOCATION_SEARCH_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          location:{
            ...state.search.location,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case LOCATION_SEARCH_LOCATION_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          location:{
            ...state.search.location,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    case LOCATION_SEARCH_PROCESS_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          process:{
            ...state.search.process,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case LOCATION_SEARCH_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          process:{
            ...state.search.process,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case LOCATION_SEARCH_PROCESS_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          process:{
            ...state.search.process,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }

    case LOCATION_SEARCH_MEDICAMENT_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          medicament:{
            ...state.search.medicament,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case LOCATION_SEARCH_MEDICAMENT_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          medicament:{
            ...state.search.medicament,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case LOCATION_SEARCH_MEDICAMENT_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          medicament:{
            ...state.search.medicament,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    case LOCATION_SEARCH_GEO_REGION_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          geo_region:{
            ...state.search.geo_region,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case LOCATION_SEARCH_GEO_REGION_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          geo_region:{
            ...state.search.geo_region,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case LOCATION_SEARCH_GEO_REGION_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          geo_region:{
            ...state.search.geo_region,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    case LOCATION_ADD_CHANGE:
      return {
        ...state,
        add: {
          ...state.add,
          data: {
            ...state.add.data,
            new: {
              ...state.add.data.new,
              ...action.payload
            }
          }
        }
      }
    case LOCATION_ADD_SET:
      return {
        ...state,
        add: {
          ...state.add,
          data: action.payload
        }
      }
    case LOCATION_ADD_MODAL_SET:
      return {
        ...state,
        add: {
          ...state.add,
          modal: action.payload
        }
      }
    case LOCATION_ADD:
      return {
        ...state,
        add: {
          ...state.add, 
          loading: true
        }
      }
    case LOCATION_ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          modal: false,
          loading: false,
          message: action.payload.message,
          id: action.payload.id
        },
        view: {
          ...state.view,
          modal: false
        }
      }
    case LOCATION_ADD_ERROR:
      return {
        ...state,
        add: {
          ...state.add,
          loading: false,
          message: action.payload.message
        }
      }
    case LOCATION_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          data: []
        }
      }
    case LOCATION_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: action.payload
        },
        edit: {
          ...state.edit,
          loading: false,
          data: {
            new: action.payload,
            old: action.payload
          }
        }
      }
    case LOCATION_DETAIL_ERROR:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: []
        }
      }
    case LOCATION_VIEW_MODAL_SET:
      return {
        ...state,
        view: {
          ...state.view,
          modal: action.payload
        }
      }
    case LOCATION_EDIT_MODAL_SET:
      return {
        ...state,
        edit: {
          ...state.edit,
          modal: action.payload
        }
      }
    case LOCATION_EDIT_CHANGE:
      return {
        ...state,
        edit: {
          ...state.edit,
          data: {
            ...state.edit.data,
            new: {
              ...state.edit.data.new,
              ...action.payload
            }
          }
        }
      }
    case LOCATION_UPDATE:
      return {
        ...state,
        edit: {
          ...state.edit,
          data: {
            ...state.edit.data,
            ...action.payload,
          },
          update: {
            ...state.update,
            loading: true,
            response: '',
            message: ''
          }
        }
      }
    case LOCATION_UPDATE_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          modal: false,
          data: {
            ...state.edit.data,
            new: {
              ...state.edit.data.new,
              update_reason: ''
            },
            old: {
              ...state.edit.data.new,
              update_reason: ''
            }
          },
          update: {
            ...state.update,
            loading: false,
            response: 'success',
            message: action.payload
          }
        },
        view: {
          ...state.view,
          modal: false
        }
      }
    case LOCATION_UPDATE_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          update: {
            ...state.update,
            loading: false,
            response: 'error',
            message: action.payload
          }
        }
      }
    case LOCATION_VIEW_TAB_SET:
      return {
        ...state,
        view: {
          ...state.view,
          tab: action.payload
        }
      }
    case LOCATION_SEARCH_LOCATION_SET:
      return {
        ...state,
        search:{
          ...state.search,
          location:{
            ...state.search.location,
            loading: false,
            list:[ ...state.search.location.list, action.payload],
            message: 'No se encotraron datos'
          }
        }
      }
    default:
      return { ...state };
  }
};