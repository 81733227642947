import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import app from './app/reducer';
import user from './user/reducer';
import upload from './upload/reducer';
import quality_source from './quality_source/reducer';
import quality_autoreview from './quality_autoreview/reducer';
import autoreview from './autoreview/reducer';
import quality_notification from './quality_notification/reducer';
import quality_email from './quality_email/reducer';
import request from './request/reducer';
import request_detail from './request_detail/reducer';
import dashboard from './dashboard/reducer';
import validate_email from './validate_email/reducer';
import process from './process/reducer';
import process_detail from './process_detail/reducer';
import article from './mspas/article/reducer';
import location from './mspas/location/reducer';

const reducers = combineReducers({
  user,
  upload,
  menu,
  settings,
  authUser,
  app,
  quality_email,
  quality_source,
  quality_autoreview,
  autoreview,
  quality_notification,
  quality_email,
  request,
  request_detail,
  dashboard,
  validate_email,
  process,
  process_detail,
  article,
  location
});

export default reducers;
