import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_DETAIL_LIST,
  REQUEST_DETAIL_SEARCH_ARTICLE_LIST,
  REQUEST_DETAIL_SEARCH_REQUEST_LIST,
  REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_SEARCH_GEO_REGION_LIST,
  REQUEST_DETAIL_ADD,
  REQUEST_DETAIL_DETAIL,
  REQUEST_DETAIL_UPDATE,
} from '../actions';
import {
  RequestDetailListSuccess,
  RequestDetailListError,
  RequestDetailSearchArticleListSuccess,
  RequestDetailSearchArticleListError,
  RequestDetailSearchRequestListSuccess,
  RequestDetailSearchRequestListError,
  RequestDetailSearchGeoCountryListSuccess,
  RequestDetailSearchGeoCountryListError,
  RequestDetailSearchGeoRegionListSuccess,
  RequestDetailSearchGeoRegionListError,
  RequestDetailAddSuccess,
  RequestDetailAddError,
  RequestDetailDetailSuccess,
  RequestDetailDetailError,
  RequestDetailUpdateSuccess,
  RequestDetailUpdateError
} from '../actions';
 
export function* watchRequestDetailList() {
  yield takeEvery(REQUEST_DETAIL_LIST, onRequestDetailList);
}

export function* watchRequestDetailSearchArticleList() {
  yield takeEvery(REQUEST_DETAIL_SEARCH_ARTICLE_LIST, onRequestDetailSearchArticleList);
}

export function* watchRequestDetailSearchRequestList() {
  yield takeEvery(REQUEST_DETAIL_SEARCH_REQUEST_LIST, onRequestDetailSearchRequestList);
}

export function* watchRequestDetailSearchGeoCountryList() {
  yield takeEvery(REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST, onRequestDetailSearchGeoCountryList);
}

export function* watchRequestDetailSearchGeoRegionList() {
  yield takeEvery(REQUEST_DETAIL_SEARCH_GEO_REGION_LIST, onRequestDetailSearchGeoRegionList);
}

export function* watchRequestDetailAdd() {
  yield takeEvery(REQUEST_DETAIL_ADD, onRequestDetailAdd);
}

export function* watchRequestDetailDetail() {
  yield takeEvery(REQUEST_DETAIL_DETAIL, onRequestDetailDetail);
}

export function* watchRequestDetailUpdate() {
  yield takeEvery(REQUEST_DETAIL_UPDATE, onRequestDetailUpdate);
}

const onRequestDetailListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestDetailUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onRequestDetailSearchArticleListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/article_request_detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailSearchRequestListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/request', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country_request_detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailSearchGeoRegionListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_region', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestDetailDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestDetailList(data) {
  try {
    const response = yield call(onRequestDetailListAsync, data.payload);
    yield put(RequestDetailListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailListError(error));
  }
}

function* onRequestDetailSearchArticleList(data) {
  try {
    const response = yield call(onRequestDetailSearchArticleListAsync, data.payload);
    yield put(RequestDetailSearchArticleListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailSearchArticleListError(error));
  }
}

function* onRequestDetailSearchRequestList(data) {
  try {
    const response = yield call(onRequestDetailSearchRequestListAsync, data.payload);
    yield put(RequestDetailSearchRequestListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailSearchRequestListError(error));
  }
}

function* onRequestDetailSearchGeoCountryList(data) {
  try {
    const response = yield call(onRequestDetailSearchGeoCountryListAsync, data.payload);
    yield put(RequestDetailSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailSearchGeoCountryListError(error));
  }
}

function* onRequestDetailSearchGeoRegionList(data) {
  try {
    const response = yield call(onRequestDetailSearchGeoRegionListAsync, data.payload);
    yield put(RequestDetailSearchGeoRegionListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailSearchGeoRegionListError(error));
  }
}

function* onRequestDetailAdd(data) {
  try {
      const response = yield call(onRequestDetailAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailAddSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestDetailDetail(data) {
  try {
    const response = yield call(onRequestDetailDetailAsync, data.payload);
    yield put(RequestDetailDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailDetailError(error));
  }
}

function* onRequestDetailUpdate(data) {
  try {
      const response = yield call(onRequestDetailUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestDetailList),
    fork(watchRequestDetailSearchArticleList),
    fork(watchRequestDetailSearchRequestList),
    fork(watchRequestDetailSearchGeoCountryList),
    fork(watchRequestDetailSearchGeoRegionList),
    fork(watchRequestDetailAdd),
    fork(watchRequestDetailDetail),
    fork(watchRequestDetailUpdate),
  ]);
} 