import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { servicePath } from '../../constants/defaultValues';

import {
    VALIDATE_EMAIL
} from '../actions';

import {
    ValidateEmailSuccess,
    ValidateEmailError
} from '../actions';


export function* watchValidateEmail() {
    yield takeEvery(VALIDATE_EMAIL, onValidateEmail);
}

const onValidateEmailAsync = async (payload) => {
    const response = await fetch(servicePath + 'validate_email/validate', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { message: true, data: response };
}

    function* onValidateEmail(data) {
    try {
        const response = yield call(onValidateEmailAsync, data.payload);
        yield put(ValidateEmailSuccess(response.data));
    } catch (error) {
        yield put(ValidateEmailError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchValidateEmail)
    ]);
}
