import { createStructuredSelector } from "reselect";
import {
    USER_ACTIONS_LIST,
    USER_ACTIONS_LIST_SUCCESS,
    USER_ACTIONS_LIST_ERROR
} from "../actions";

export const NAME = 'user';

export const UserActionsList = (data) => ({
    type: USER_ACTIONS_LIST,
    payload: data
});

export const UserActionsListSuccess = (data) => ({
    type: USER_ACTIONS_LIST_SUCCESS,
    payload: data
});

export const UserActionsListError = (error) => ({
    type: USER_ACTIONS_LIST_ERROR,
    payload: error
});

const user = (state) => state[NAME];

export const UserSelector = createStructuredSelector({
    user
})
