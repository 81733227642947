import { createStructuredSelector } from 'reselect'

import {
  APP_LIST,
  APP_LIST_SUCCESS,
  APP_LIST_ERROR,
  APP_PAGE_SET,
  APP_SEARCH_SET,
  APP_SEARCH_CHANGE,
  APP_SEARCH_APP_LIST,
  APP_SEARCH_APP_LIST_SUCCESS,
  APP_SEARCH_APP_LIST_ERROR,
  APP_SEARCH_APP_TYPE_LIST,
  APP_SEARCH_APP_TYPE_LIST_SUCCESS,
  APP_SEARCH_APP_TYPE_LIST_ERROR,
  APP_ADD_SET,
  APP_ADD_MODAL_SET,
  APP_ADD,
  APP_ADD_SUCCESS,
  APP_ADD_ERROR,
  APP_ADD_CHANGE,
  APP_VIEW,
  APP_VIEW_SUCCESS,
  APP_VIEW_ERROR,
  APP_VIEW_MODAL_SET,
  APP_VIEW_TAB_SET,
  APP_EDIT_CHANGE,
  APP_EDIT_MODAL_SET,
  APP_UPDATE,
  APP_UPDATE_SUCCESS,
  APP_UPDATE_ERROR
} from '../actions';

export const NAME = 'app';

export const AppList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: APP_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const AppListSuccess = (data) => ({
  type: APP_LIST_SUCCESS,
  payload: data
});

export const AppListError = (error) => ({
  type: APP_LIST_ERROR,
  payload: error
});

export const AppPageSet = (data) => ({
  type: APP_PAGE_SET,
  payload: data
});

export const AppSearchSet = (data) => ({
  type: APP_SEARCH_SET,
  payload: data
});

export const AppSearchChangeSet = (data) => ({
  type: APP_SEARCH_CHANGE,
  payload: data
});

export const AppSearchAppList = (data) => ({
  type: APP_SEARCH_APP_LIST,
  payload: data
});

export const AppSearchAppListSuccess = (message) => ({
  type: APP_SEARCH_APP_LIST_SUCCESS,
  payload: message
});

export const AppSearchAppListError = (error) => ({
  type: APP_SEARCH_APP_LIST_ERROR,
  payload: error
}); 
export const AppSearchAppTypeList = (data) => ({
  type: APP_SEARCH_APP_TYPE_LIST,
  payload: data
});

export const AppSearchAppTypeListSuccess = (message) => ({
  type: APP_SEARCH_APP_TYPE_LIST_SUCCESS,
  payload: message
});

export const AppSearchAppTypeListError = (error) => ({
  type: APP_SEARCH_APP_TYPE_LIST_ERROR,
  payload: error
}); 

export const AppAddChangeSet = (data) => ({
  type: APP_ADD_CHANGE,
  payload: data
});

export const AppAddSet = (data) => ({
  type: APP_ADD_SET,
  payload: data
});

export const AppAddModalSet = (data) => ({
  type: APP_ADD_MODAL_SET,
  payload: data
});

export const AppAdd = (data) => ({
  type: APP_ADD,
  payload: data
});

export const AppAddSuccess = (message) => ({
  type: APP_ADD_SUCCESS,
  payload: message
});

export const AppAddError = (error) => ({
  type: APP_ADD_ERROR,
  payload: error
});

export const AppView = (data) => ({
  type: APP_VIEW,
  payload: data
});

export const AppViewSuccess = (data) => ({
  type: APP_VIEW_SUCCESS,
  payload: data
});

export const AppViewError = (error) => ({
  type: APP_VIEW_ERROR,
  payload: error
});

export const AppViewModalSet = (data) => ({
  type: APP_VIEW_MODAL_SET,
  payload: data
});

export const AppEditModalSet = (data) => ({
  type: APP_EDIT_MODAL_SET,
  payload: data
});

export const AppEditChangeSet = (data) => ({
  type: APP_EDIT_CHANGE,
  payload: data
});

export const AppUpdate = (data) => ({
  type: APP_UPDATE,
  payload: data
});

export const AppUpdateSuccess = (message) => ({
  type: APP_UPDATE_SUCCESS,
  payload: message.message
});

export const AppUpdateError = (error) => ({
  type: APP_UPDATE_ERROR,
  payload: error
});

export const AppViewTabSet = (data) => ({
  type: APP_VIEW_TAB_SET,
  payload: data
});

const app = (state) => state[NAME];

export const AppSelector = createStructuredSelector({
  app
})
