import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { getCurrentLanguage } from '../../helpers/Utils';

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
} from './actions';

import { adminRoot, userRoot, verifiedRoot } from '../../constants/defaultValues';

const locale = getCurrentLanguage();
function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, sendSignInLinkToEmail);
}


const sendSignInLinkToEmailAsync = async (email) =>{
  var actionCodeSettings = {
    url: verifiedRoot,
    handleCodeInApp: true,
  };
  await authFB
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then((user) => user )
    .catch((error) => error);
  }
  
function* sendSignInLinkToEmail({ payload }) {
  const { email } = payload.user;
  try {
    yield call(sendSignInLinkToEmailAsync, email);
    window.localStorage.setItem('emailForSignIn', email)
    yield put(loginUserSuccess({message:locale==='es'?'Hemos enviado un vinculo de acceso a tu correo electrónico':'We have sent an access link to your email'}));
    NotificationManager.success(locale==='es'?'Hemos enviado un vinculo de acceso a tu correo electrónico':'We have sent an access link to your email', locale==='es'?'Éxito':'Success', 3000, null, null, '');
    setTimeout(() => {
      window.location.href = `${userRoot}/unverified`
    }, 1500);
  } catch (error) {
    yield put(loginUserError(error));
    //NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, signInLinkToEmail);
}

const signInLinkToEmailAsync = async (email, href) =>{
  await authFB
  .signInWithEmailLink(email, href) 
  .then((user) => user)
  .catch((error) => error);
}
  
const registerSQLAsync = async (data) => {
  await authFB.currentUser.updateProfile({ displayName: data.email })
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'register', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { message: true, data: response };
};
    
function* signInLinkToEmail({ payload }) {
  const { email, link } = payload.user;
  try {
    const registerUser = yield call( signInLinkToEmailAsync, email, link );
    
    yield call( registerSQLAsync, {email:email});
    yield put(registerUserSuccess({email:email}));
    window.location.href = adminRoot
  } catch (error) {
    yield put(registerUserError(error));
    window.location.href = adminRoot
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await authFB
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  yield call(logoutAsync, history);
}


export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser)
  ]);
}
