import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../../helpers/Firebase';
import { servicePath } from '../../../constants/defaultValues';
import { NotificationManager } from '../../../components/common/react-notifications';
import { 
  ARTICLE_LIST,
  ARTICLE_SEARCH_ARTICLE_LIST,
  ARTICLE_SEARCH_PROCESS_LIST,
  ARTICLE_SEARCH_MEDICAMENT_LIST,
  ARTICLE_SEARCH_GEO_REGION_LIST,
  ARTICLE_ADD,
  ARTICLE_DETAIL,
  ARTICLE_UPDATE,
} from '../../actions';
import {
  ArticleListSuccess,
  ArticleListError,
  ArticleSearchArticleListSuccess,
  ArticleSearchArticleListError,
  ArticleSearchProcessListSuccess,
  ArticleSearchProcessListError,
  ArticleSearchMedicamentListSuccess,
  ArticleSearchMedicamentListError,
  ArticleSearchGeoRegionListSuccess,
  ArticleSearchGeoRegionListError,
  ArticleAddSuccess,
  ArticleAddError,
  ArticleDetailSuccess,
  ArticleDetailError,
  ArticleUpdateSuccess,
  ArticleUpdateError
} from '../../actions';
 
export function* watchArticleList() {
  yield takeEvery(ARTICLE_LIST, onArticleList);
}

export function* watchArticleSearchArticleList() {
  yield takeEvery(ARTICLE_SEARCH_ARTICLE_LIST, onArticleSearchArticleList);
}

export function* watchArticleSearchProcessList() {
  yield takeEvery(ARTICLE_SEARCH_PROCESS_LIST, onArticleSearchProcessList);
}

export function* watchArticleSearchMedicamentList() {
  yield takeEvery(ARTICLE_SEARCH_MEDICAMENT_LIST, onArticleSearchMedicamentList);
}

export function* watchArticleSearchGeoRegionList() {
  yield takeEvery(ARTICLE_SEARCH_GEO_REGION_LIST, onArticleSearchGeoRegionList);
}

export function* watchArticleAdd() {
  yield takeEvery(ARTICLE_ADD, onArticleAdd);
}

export function* watchArticleDetail() {
  yield takeEvery(ARTICLE_DETAIL, onArticleDetail);
}

export function* watchArticleUpdate() {
  yield takeEvery(ARTICLE_UPDATE, onArticleUpdate);
}

const onArticleListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'mspas/article/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onArticleUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'mspas/article/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}

const onArticleSearchArticleListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/article', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onArticleSearchProcessListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/process', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onArticleSearchMedicamentListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/medicament', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onArticleSearchGeoRegionListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_region', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onArticleAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'mspas/article/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onArticleDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'mspas/article/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onArticleList(data) {
  try {
    const response = yield call(onArticleListAsync, data.payload);
    yield put(ArticleListSuccess(response.data));
  } catch (error) {
    yield put(ArticleListError(error));
  }
}

function* onArticleSearchArticleList(data) {
  try {
    const response = yield call(onArticleSearchArticleListAsync, data.payload);
    yield put(ArticleSearchArticleListSuccess(response.data));
  } catch (error) {
    yield put(ArticleSearchArticleListError(error));
  }
}

function* onArticleSearchProcessList(data) {
  try {
    const response = yield call(onArticleSearchProcessListAsync, data.payload);
    yield put(ArticleSearchProcessListSuccess(response.data));
  } catch (error) {
    yield put(ArticleSearchProcessListError(error));
  }
}

function* onArticleSearchMedicamentList(data) {
  try {
    const response = yield call(onArticleSearchMedicamentListAsync, data.payload);
    yield put(ArticleSearchMedicamentListSuccess(response.data));
  } catch (error) {
    yield put(ArticleSearchMedicamentListError(error));
  }
}

function* onArticleSearchGeoRegionList(data) {
  try {
    const response = yield call(onArticleSearchGeoRegionListAsync, data.payload);
    yield put(ArticleSearchGeoRegionListSuccess(response.data));
  } catch (error) {
    yield put(ArticleSearchGeoRegionListError(error));
  }
}

function* onArticleAdd(data) {
  try {
      const response = yield call(onArticleAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(ArticleAddSuccess(response.data));
  } catch (error) {
      yield put(ArticleAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onArticleDetail(data) {
  try {
    const response = yield call(onArticleDetailAsync, data.payload);
    yield put(ArticleDetailSuccess(response.data.data));
  } catch (error) {
    yield put(ArticleDetailError(error));
  }
}

function* onArticleUpdate(data) {
  try {
      const response = yield call(onArticleUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Éxito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(ArticleUpdateSuccess(response.data));
  } catch (error) {
      yield put(ArticleUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchArticleList),
    fork(watchArticleSearchArticleList),
    fork(watchArticleSearchProcessList),
    fork(watchArticleSearchMedicamentList),
    fork(watchArticleSearchGeoRegionList),
    fork(watchArticleAdd),
    fork(watchArticleDetail),
    fork(watchArticleUpdate),
  ]);
} 