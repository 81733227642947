import { createStructuredSelector } from 'reselect'

import {
  PROCESS_LIST,
  PROCESS_LIST_SUCCESS,
  PROCESS_LIST_ERROR,
  PROCESS_PAGE_SET,
  PROCESS_SEARCH_SET,
  PROCESS_SEARCH_CHANGE,
  PROCESS_SEARCH_PROCESS_USER_LIST,
  PROCESS_SEARCH_PROCESS_USER_LIST_SUCCESS,
  PROCESS_SEARCH_PROCESS_USER_LIST_ERROR,
  PROCESS_SEARCH_PROCESS_PROCESS_LIST,
  PROCESS_SEARCH_PROCESS_PROCESS_LIST_SUCCESS,
  PROCESS_SEARCH_PROCESS_PROCESS_LIST_ERROR,
  PROCESS_SEARCH_GEO_COUNTRY_LIST,
  PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR,
  PROCESS_ADD_SET,
  PROCESS_ADD_MODAL_SET,
  PROCESS_ADD,
  PROCESS_ADD_SUCCESS,
  PROCESS_ADD_ERROR,
  PROCESS_ADD_CHANGE,
  PROCESS_DETAIL,
  PROCESS_DETAIL_SUCCESS,
  PROCESS_DETAIL_ERROR,
  PROCESS_VIEW_MODAL_SET,
  PROCESS_EDIT_CHANGE,
  PROCESS_EDIT_MODAL_SET,
  PROCESS_UPDATE,
  PROCESS_UPDATE_SUCCESS,
  PROCESS_UPDATE_ERROR,
  PROCESS_VIEW_TAB_SET
} from '../actions';

export const NAME = 'process';

export const ProcessList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: PROCESS_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const ProcessListSuccess = (data) => ({
  type: PROCESS_LIST_SUCCESS,
  payload: data
});

export const ProcessListError = (error) => ({
  type: PROCESS_LIST_ERROR,
  payload: error
});

export const ProcessPageSet = (data) => ({
  type: PROCESS_PAGE_SET,
  payload: data
});

export const ProcessSearchSet = (data) => ({
  type: PROCESS_SEARCH_SET,
  payload: data
});

export const ProcessSearchChangeSet = (data) => ({
  type: PROCESS_SEARCH_CHANGE,
  payload: data
});

export const ProcessSearchProcessUserList = (data) => ({
  type: PROCESS_SEARCH_PROCESS_USER_LIST,
  payload: data
});

export const ProcessSearchProcessUserListSuccess = (message) => ({
  type: PROCESS_SEARCH_PROCESS_USER_LIST_SUCCESS,
  payload: message
});

export const ProcessSearchProcessUserListError = (error) => ({
  type: PROCESS_SEARCH_PROCESS_USER_LIST_ERROR,
  payload: error
}); 

export const ProcessSearchProcessProcessList = (data) => ({
  type: PROCESS_SEARCH_PROCESS_PROCESS_LIST,
  payload: data
});

export const ProcessSearchProcessProcessListSuccess = (message) => ({
  type: PROCESS_SEARCH_PROCESS_PROCESS_LIST_SUCCESS,
  payload: message
});

export const ProcessSearchProcessProcessListError = (error) => ({
  type: PROCESS_SEARCH_PROCESS_PROCESS_LIST_ERROR,
  payload: error
}); 

export const ProcessSearchGeoCountryList = (data) => ({
  type: PROCESS_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const ProcessSearchGeoCountryListSuccess = (message) => ({
  type: PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const ProcessSearchGeoCountryListError = (error) => ({
  type: PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 

export const ProcessAddChangeSet = (data) => ({
  type: PROCESS_ADD_CHANGE,
  payload: data
});

export const ProcessAddSet = (data) => ({
  type: PROCESS_ADD_SET,
  payload: data
});

export const ProcessAddModalSet = (data) => ({
  type: PROCESS_ADD_MODAL_SET,
  payload: data
});

export const ProcessAdd = (data) => ({
  type: PROCESS_ADD,
  payload: data
});

export const ProcessAddSuccess = (message) => ({
  type: PROCESS_ADD_SUCCESS,
  payload: message
});

export const ProcessAddError = (error) => ({
  type: PROCESS_ADD_ERROR,
  payload: error
});

export const ProcessDetail = (data) => ({
  type: PROCESS_DETAIL,
  payload: data
});

export const ProcessDetailSuccess = (data) => ({
  type: PROCESS_DETAIL_SUCCESS,
  payload: data
});

export const ProcessDetailError = (error) => ({
  type: PROCESS_DETAIL_ERROR,
  payload: error
});

export const ProcessViewModalSet = (data) => ({
  type: PROCESS_VIEW_MODAL_SET,
  payload: data
});

export const ProcessEditModalSet = (data) => ({
  type: PROCESS_EDIT_MODAL_SET,
  payload: data
});

export const ProcessEditChangeSet = (data) => ({
  type: PROCESS_EDIT_CHANGE,
  payload: data
});

export const ProcessUpdate = (data) => ({
  type: PROCESS_UPDATE,
  payload: data
});

export const ProcessUpdateSuccess = (message) => ({
  type: PROCESS_UPDATE_SUCCESS,
  payload: message.message
});

export const ProcessUpdateError = (error) => ({
  type: PROCESS_UPDATE_ERROR,
  payload: error
});

export const ProcessViewTabSet = (data) => ({
  type: PROCESS_VIEW_TAB_SET,
  payload: data
});

const process = (state) => state[NAME];

export const ProcessSelector = createStructuredSelector({
  process
})
