import {
    DASHBOARD_LIST,
    DASHBOARD_LIST_SUCCESS,
    DASHBOARD_LIST_ERROR,
    DASHBOARD_SEARCH_SET,
    DASHBOARD_SEARCH_CHANGE,
    ARTICLE_CODE22,
    ARTICLE_CODE22_SUCCESS,
    ARTICLE_CODE22_ERROR,
    DASHBOARD_UNIT_PRICE,
    DASHBOARD_UNIT_PRICE_SUCCESS,
    DASHBOARD_UNIT_PRICE_ERROR,
    DASHBOARD_SOURCE,
    DASHBOARD_SOURCE_SUCCESS,
    DASHBOARD_SOURCE_ERROR,
    DASHBOARD_YEAR,
    DASHBOARD_YEAR_SUCCESS,
    DASHBOARD_YEAR_ERROR,
    DASHBOARD_PRICES,
    DASHBOARD_PRICES_SUCCESS,
    DASHBOARD_PRICES_ERROR,
    DASHBOARD_TRANSACTIONS,
    DASHBOARD_TRANSACTIONS_SUCCESS,
    DASHBOARD_TRANSACTIONS_ERROR,
    DASHBOARD_RAZONABILIDAD,
    DASHBOARD_RAZONABILIDAD_SUCCESS,
    DASHBOARD_RAZONABILIDAD_ERROR,
    DASHBOARD_DATA_SUPPLIER,
    DASHBOARD_DATA_SUPPLIER_SUCCESS,
    DASHBOARD_DATA_SUPPLIER_ERROR,
    DASHBOARD_DATA_SUPPLIER_PAGE_SET
} from '../actions';

const INIT_STATE = {
    loading: true,
    list: {
        data: {},
        loading: true,
        message: ''
    },
    article_code22: {
        data: [],
        loading: true,
        message: ''
    },
    unitprice: {
        data: [],
        loading: true,
        message: ''
    },
    source: {
        data: [],
        loading: true,
        message: ''
    },
    year: {
        data: [],
        loading: true,
        message: ''
    },
    orppricegraph: {
        data: [],
        loading: true,
    },
    orpcomprasunops: {
        data: [],
        loading: true,
    },
    razonabilidad: {
        data: [],
        loading: true,
    },
    datasupplier: {
        page: {
            current: 1,
            rows: 10
        },
        data: [],
        loading: true
    },
    error: false,
    search: {
        modal: false,
        init: true,
        data: {
            anio: '',
            idcode22: 201,
            code22: 'Aciclovir 400mg/1 U /Tableta Oral/ Tableta por 400 mg',
            source_code: '',
            med_description: 201,
            med_description_anio: 201
        },
        value: {
            anio: '',
            idcode22: 201,
            code22: 'Aciclovir 400mg/1 U /Tableta Oral/ Tableta por 400 mg',
            source_code: '',
            med_description: 201,
            med_description_anio: 201
        }
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_LIST:
            return {
                ...state,
                loading: true,
                list: {
                    ...state.list,
                    loading: true
                }
            }
        case DASHBOARD_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: {
                    ...state.list,
                    ...action.payload,
                    loading: false
                }
            }
        case DASHBOARD_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }

        case DASHBOARD_SEARCH_SET:
            return {
                ...state,
                search: {
                    ...state.search,
                    data: action.payload,
                }
            }
        case DASHBOARD_SEARCH_CHANGE:
            return {
                ...state,
                search: {
                    ...state.search,
                    value: action.payload,
                }
            }

        case ARTICLE_CODE22:
            return {
                ...state,
                init: false,
                article_code22: {
                    ...state.article_code22,
                    loading: true,
                    data: [],
                    message: ''
                }
            }
        case ARTICLE_CODE22_SUCCESS:
            return {
                ...state,
                article_code22: {
                    ...state.article_code22,
                    loading: false,
                    data: action.payload,
                    message: ''
                }
            }
        case ARTICLE_CODE22_ERROR:
            return {
                ...state,
                article_code22: {
                    ...state.article_code22,
                    loading: false,
                    data: [],
                    message: 'No se encotraron datos'
                }
            }
        case DASHBOARD_UNIT_PRICE:
            return {
                ...state,
                init: false,
                unitprice: {
                    ...state.unitprice,
                    loading: true,
                    data: [],
                    message: ''
                }
            }
        case DASHBOARD_UNIT_PRICE_SUCCESS:
            return {
                ...state,
                unitprice: {
                    ...state.unitprice,
                    loading: false,
                    data: action.payload,
                    message: ''
                }
            }
        case DASHBOARD_UNIT_PRICE_ERROR:
            return {
                ...state,
                unitprice: {
                    ...state.unitprice,
                    loading: false,
                    data: [],
                    message: 'No se encotraron datos'
                }
            }

        case DASHBOARD_SOURCE:
            return {
                ...state,
                init: false,
                source: {
                    ...state.source,
                    loading: true,
                    data: [],
                    message: ''
                }
            }
        case DASHBOARD_SOURCE_SUCCESS:
            return {
                ...state,
                source: {
                    ...state.source,
                    loading: false,
                    data: action.payload,
                    message: ''
                }
            }
        case DASHBOARD_SOURCE_ERROR:
            return {
                ...state,
                source: {
                    ...state.source,
                    loading: false,
                    data: [],
                    message: 'No se encotraron datos'
                }
            }

        case DASHBOARD_YEAR:
            return {
                ...state,
                init: false,
                year: {
                    ...state.year,
                    loading: true,
                    data: [],
                    message: ''
                }
            }
        case DASHBOARD_YEAR_SUCCESS:
            return {
                ...state,
                year: {
                    ...state.year,
                    loading: false,
                    data: action.payload,
                    message: ''
                }
            }
        case DASHBOARD_YEAR_ERROR:
            return {
                ...state,
                year: {
                    ...state.year,
                    loading: false,
                    data: [],
                    message: 'No se encotraron datos'
                }
            }

        case DASHBOARD_PRICES:
            return {
                ...state,
                init: false,
                orppricegraph: {
                    ...state.orppricegraph,
                    loading: true,
                    data: [],
                    message: ''
                }
            }
        case DASHBOARD_PRICES_SUCCESS:
            return {
                ...state,
                orppricegraph: {
                    ...state.orppricegraph,
                    loading: false,
                    data: action.payload,
                    message: ''
                }
            }
        case DASHBOARD_PRICES_ERROR:
            return {
                ...state,
                orppricegraph: {
                    ...state.orppricegraph,
                    loading: false,
                    data: [],
                    message: 'No se encotraron datos'
                }
            }

        case DASHBOARD_TRANSACTIONS:
            return {
                ...state,
                init: false,
                orpcomprasunops: {
                    ...state.orpcomprasunops,
                    loading: true,
                    data: [],
                    message: ''
                }
            }
        case DASHBOARD_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                orpcomprasunops: {
                    ...state.orpcomprasunops,
                    loading: false,
                    data: action.payload,
                    message: ''
                }
            }
        case DASHBOARD_TRANSACTIONS_ERROR:
            return {
                ...state,
                orpcomprasunops: {
                    ...state.orpcomprasunops,
                    loading: false,
                    data: [],
                    message: 'No se encotraron datos'
                }
            }

        case DASHBOARD_RAZONABILIDAD:
            return {
                ...state,
                init: false,
                razonabilidad: {
                    ...state.razonabilidad,
                    loading: true,
                    data: [],
                    message: ''
                }
            }

        case DASHBOARD_RAZONABILIDAD_SUCCESS:
            return {
                ...state,
                razonabilidad: {
                    ...state.razonabilidad,
                    loading: false,
                    data: action.payload,
                    message: ''
                }
            }

        case DASHBOARD_RAZONABILIDAD_ERROR:
            return {
                ...state,
                razonabilidad: {
                    ...state.razonabilidad,
                    loading: false,
                    data: [],
                    message: 'No se encotraron datos'
                }
            }
        case DASHBOARD_DATA_SUPPLIER:
            return {
                ...state,
                init: false,
                datasupplier: {
                    ...state.datasupplier,
                    loading: true,
                    data: [],
                    message: ''
                }
            }
        case DASHBOARD_DATA_SUPPLIER_SUCCESS:
            return {
                ...state,
                loading: false,
                datasupplier: {
                    ...state.datasupplier,
                    data: action.payload,
                    loading: false
                }
            }
        case DASHBOARD_DATA_SUPPLIER_ERROR:
            return {
                ...state,
                datasupplier: {
                    ...state.datasupplier,
                    loading: false,
                    data: [],
                    message: 'No se encotraron datos'
                }
            }
        case DASHBOARD_DATA_SUPPLIER_PAGE_SET:
            return {
                ...state,
                datasupplier: {
                    ...state.datasupplier,
                    page: {
                        ...state.datasupplier.page,
                        ...action.payload,
                    }
                },
                log: []
            }

        default:
            return { ...state };
    }
};