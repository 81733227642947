import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { getCurrentLanguage } from '../../helpers/Utils';
import { 
  QUALITY_EMAIL_LIST,
  QUALITY_EMAIL_SEARCH_REQUEST_LIST,
  QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST,
  QUALITY_EMAIL_ADD,
  QUALITY_EMAIL_DETAIL,
  QUALITY_EMAIL_UPDATE,
} from '../actions';
import {
  QualityEmailListSuccess,
  QualityEmailListError,
  QualityEmailSearchRequestListSuccess,
  QualityEmailSearchRequestListError,
  QualityEmailSearchGeoCountryListSuccess,
  QualityEmailSearchGeoCountryListError,
  QualityEmailAddSuccess,
  QualityEmailAddError,
  QualityEmailDetailSuccess,
  QualityEmailDetailError,
  QualityEmailUpdateSuccess,
  QualityEmailUpdateError
} from '../actions';
 
const locale = getCurrentLanguage();
export function* watchQualityEmailList() {
  yield takeEvery(QUALITY_EMAIL_LIST, onQualityEmailList);
}

export function* watchQualityEmailSearchRequestList() {
  yield takeEvery(QUALITY_EMAIL_SEARCH_REQUEST_LIST, onQualityEmailSearchRequestList);
}

export function* watchQualityEmailSearchGeoCountryList() {
  yield takeEvery(QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST, onQualityEmailSearchGeoCountryList);
}

export function* watchQualityEmailAdd() {
  yield takeEvery(QUALITY_EMAIL_ADD, onQualityEmailAdd);
}

export function* watchQualityEmailDetail() {
  yield takeEvery(QUALITY_EMAIL_DETAIL, onQualityEmailDetail);
}

export function* watchQualityEmailUpdate() {
  yield takeEvery(QUALITY_EMAIL_UPDATE, onQualityEmailUpdate);
}

const onQualityEmailListAsync  = async (payload) => {
  const response = await fetch(servicePath + 'quality_email/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onQualityEmailUpdateAsync = async (data) => {
  const response = await fetch(servicePath + 'quality_email/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onQualityEmailSearchRequestListAsync = async (payload) => {
  const response = await fetch(servicePath + 'search/request', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onQualityEmailSearchGeoCountryListAsync = async (payload) => {
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'GET', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onQualityEmailAddAsync = async (data) => {
  const response = await fetch(servicePath + 'quality_email/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onQualityEmailDetailAsync  = async (payload) => {
  const response = await fetch(servicePath + 'quality_email/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onQualityEmailList(data) {
  try {
    const response = yield call(onQualityEmailListAsync, data.payload);
    yield put(QualityEmailListSuccess(response.data));
  } catch (error) {
    yield put(QualityEmailListError(error));
  }
}

function* onQualityEmailSearchRequestList(data) {
  try {
    console.log('onQualityEmailSearchRequestList',data.payload)
    const response = yield call(onQualityEmailSearchRequestListAsync, data.payload);
    yield put(QualityEmailSearchRequestListSuccess(response.data));
  } catch (error) {
    yield put(QualityEmailSearchRequestListError(error));
  }
}

function* onQualityEmailSearchGeoCountryList(data) {
  try {
    const response = yield call(onQualityEmailSearchGeoCountryListAsync, data.payload);
    yield put(QualityEmailSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(QualityEmailSearchGeoCountryListError(error));
  }
}

function* onQualityEmailAdd(data) {
  try {
      const response = yield call(onQualityEmailAddAsync, data);

      if (response.data.status !== 'error') { 
          NotificationManager.success(locale==='es'?'Solicitud enviada':'Request sent', locale==='es'? 'Éxito':'Success', 3000, null, null, '');
      }
      if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(QualityEmailAddSuccess(response.data));
  } catch (error) {
      yield put(QualityEmailAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onQualityEmailDetail(data) {
  try {
    const response = yield call(onQualityEmailDetailAsync, data.payload);
    yield put(QualityEmailDetailSuccess(response.data.data));
  } catch (error) {
    yield put(QualityEmailDetailError(error));
  }
}

function* onQualityEmailUpdate(data) {
  try {
      const response = yield call(onQualityEmailUpdateAsync, data.payload);
      if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(QualityEmailUpdateSuccess(response.data));
  } catch (error) {
      yield put(QualityEmailUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchQualityEmailList),
    fork(watchQualityEmailSearchRequestList),
    fork(watchQualityEmailSearchGeoCountryList),
    fork(watchQualityEmailAdd),
    fork(watchQualityEmailDetail),
    fork(watchQualityEmailUpdate),
  ]);
} 