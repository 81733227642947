import { createStructuredSelector } from 'reselect'

import {
  QUALITY_AUTOREVIEW_LIST,
  QUALITY_AUTOREVIEW_LIST_SUCCESS,
  QUALITY_AUTOREVIEW_LIST_ERROR,
  QUALITY_AUTOREVIEW_PAGE_SET,
  QUALITY_AUTOREVIEW_SEARCH_SET,
  QUALITY_AUTOREVIEW_SEARCH_CHANGE,
  QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST,
  QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_SUCCESS,
  QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_ERROR,
  QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST,
  QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST_SUCCESS,
  QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST_ERROR,
  QUALITY_AUTOREVIEW_SEARCH_TAG_LIST,
  QUALITY_AUTOREVIEW_SEARCH_TAG_LIST_SUCCESS,
  QUALITY_AUTOREVIEW_SEARCH_TAG_LIST_ERROR,
  QUALITY_AUTOREVIEW_SEARCH_TAG_SET,
  QUALITY_AUTOREVIEW_ADD_SET,
  QUALITY_AUTOREVIEW_ADD_MODAL_SET,
  QUALITY_AUTOREVIEW_ADD,
  QUALITY_AUTOREVIEW_ADD_SUCCESS,
  QUALITY_AUTOREVIEW_ADD_ERROR,
  QUALITY_AUTOREVIEW_ADD_CHANGE,
  QUALITY_AUTOREVIEW_VIEW,
  QUALITY_AUTOREVIEW_VIEW_SUCCESS,
  QUALITY_AUTOREVIEW_VIEW_ERROR,
  QUALITY_AUTOREVIEW_VIEW_MODAL_SET,
  QUALITY_AUTOREVIEW_VIEW_TAB_SET,
  QUALITY_AUTOREVIEW_EDIT_CHANGE,
  QUALITY_AUTOREVIEW_EDIT_MODAL_SET,
  QUALITY_AUTOREVIEW_UPDATE,
  QUALITY_AUTOREVIEW_UPDATE_SUCCESS,
  QUALITY_AUTOREVIEW_UPDATE_ERROR
} from '../actions';

export const NAME = 'quality_autoreview';

export const QualityAutoReviewList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: QUALITY_AUTOREVIEW_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const QualityAutoReviewListSuccess = (data) => ({
  type: QUALITY_AUTOREVIEW_LIST_SUCCESS,
  payload: data
});

export const QualityAutoReviewListError = (error) => ({
  type: QUALITY_AUTOREVIEW_LIST_ERROR,
  payload: error
});

export const QualityAutoReviewPageSet = (data) => ({
  type: QUALITY_AUTOREVIEW_PAGE_SET,
  payload: data
});

export const QualityAutoReviewSearchSet = (data) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_SET,
  payload: data
});

export const QualityAutoReviewSearchTagSet = (data) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_TAG_SET,
  payload: data
});

export const QualityAutoReviewSearchChangeSet = (data) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_CHANGE,
  payload: data
});

export const QualityAutoReviewSearchQualitySourceList = (data) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST,
  payload: data
});

export const QualityAutoReviewSearchQualitySourceListSuccess = (message) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_SUCCESS,
  payload: message
});

export const QualityAutoReviewSearchQualitySourceListError = (error) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_QUALITY_SOURCE_LIST_ERROR,
  payload: error
}); 

export const QualityAutoReviewSearchCode22List = (data) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST,
  payload: data
});

export const QualityAutoReviewSearchCode22ListSuccess = (message) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST_SUCCESS,
  payload: message
});

export const QualityAutoReviewSearchCode22ListError = (error) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_CODE22_LIST_ERROR,
  payload: error
}); 

export const QualityAutoReviewSearchTagList = (data) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_TAG_LIST,
  payload: data
});

export const QualityAutoReviewSearchTagListSuccess = (message) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_TAG_LIST_SUCCESS,
  payload: message
});

export const QualityAutoReviewSearchTagListError = (error) => ({
  type: QUALITY_AUTOREVIEW_SEARCH_TAG_LIST_ERROR,
  payload: error
}); 

export const QualityAutoReviewAddChangeSet = (data) => ({
  type: QUALITY_AUTOREVIEW_ADD_CHANGE,
  payload: data
});

export const QualityAutoReviewAddSet = (data) => ({
  type: QUALITY_AUTOREVIEW_ADD_SET,
  payload: data
});

export const QualityAutoReviewAddModalSet = (data) => ({
  type: QUALITY_AUTOREVIEW_ADD_MODAL_SET,
  payload: data
});

export const QualityAutoReviewAdd = (data) => ({
  type: QUALITY_AUTOREVIEW_ADD,
  payload: data
});

export const QualityAutoReviewAddSuccess = (message) => ({
  type: QUALITY_AUTOREVIEW_ADD_SUCCESS,
  payload: message
});

export const QualityAutoReviewAddError = (error) => ({
  type: QUALITY_AUTOREVIEW_ADD_ERROR,
  payload: error
});

export const QualityAutoReviewView = (data) => ({
  type: QUALITY_AUTOREVIEW_VIEW,
  payload: data
});

export const QualityAutoReviewViewSuccess = (data) => ({
  type: QUALITY_AUTOREVIEW_VIEW_SUCCESS,
  payload: data
});

export const QualityAutoReviewViewError = (error) => ({
  type: QUALITY_AUTOREVIEW_VIEW_ERROR,
  payload: error
});

export const QualityAutoReviewViewModalSet = (data) => ({
  type: QUALITY_AUTOREVIEW_VIEW_MODAL_SET,
  payload: data
});

export const QualityAutoReviewEditModalSet = (data) => ({
  type: QUALITY_AUTOREVIEW_EDIT_MODAL_SET,
  payload: data
});

export const QualityAutoReviewEditChangeSet = (data) => ({
  type: QUALITY_AUTOREVIEW_EDIT_CHANGE,
  payload: data
});

export const QualityAutoReviewUpdate = (data) => ({
  type: QUALITY_AUTOREVIEW_UPDATE,
  payload: data
});

export const QualityAutoReviewUpdateSuccess = (message) => ({
  type: QUALITY_AUTOREVIEW_UPDATE_SUCCESS,
  payload: message.message
});

export const QualityAutoReviewUpdateError = (error) => ({
  type: QUALITY_AUTOREVIEW_UPDATE_ERROR,
  payload: error
});

export const QualityAutoReviewViewTabSet = (data) => ({
  type: QUALITY_AUTOREVIEW_VIEW_TAB_SET,
  payload: data
});

const quality_autoreview = (state) => state[NAME];

export const QualityAutoReviewSelector = createStructuredSelector({
  quality_autoreview
})
