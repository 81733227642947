import React from 'react';
import Particles from 'react-particles';

const NxtParticles = () => {
  return (
    <>
      <Particles 
        params={{ 
          particles: { number: { value: 30, density: { enable: true, value_area: 1200 }}, size: { value: 1.2 }, color: { value: '#0092d1'}, line_linked:{color: { value: '#0092d1'}}},
          interactivity: { events: { onhover: { enable: true, mode: "repulse" } } },
        }} 
      />
    </>
  )
}

export default NxtParticles;