import {
  QUALITY_SOURCE_LIST, 
  QUALITY_SOURCE_LIST_SUCCESS,
  QUALITY_SOURCE_LIST_ERROR,
  QUALITY_SOURCE_SEARCH_SET,
  QUALITY_SOURCE_SEARCH_CHANGE, 
  QUALITY_SOURCE_PAGE_SET,
  QUALITY_SOURCE_SEARCH_REQUEST_LIST,
  QUALITY_SOURCE_SEARCH_REQUEST_LIST_SUCCESS,
  QUALITY_SOURCE_SEARCH_REQUEST_LIST_ERROR,
  QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST,
  QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_ERROR,
  QUALITY_SOURCE_ADD_SET,
  QUALITY_SOURCE_ADD_MODAL_SET,
  QUALITY_SOURCE_ADD,
  QUALITY_SOURCE_ADD_SUCCESS,
  QUALITY_SOURCE_ADD_ERROR,
  QUALITY_SOURCE_ADD_CHANGE,
  QUALITY_SOURCE_DETAIL,
  QUALITY_SOURCE_DETAIL_SUCCESS,
  QUALITY_SOURCE_DETAIL_ERROR,
  QUALITY_SOURCE_VIEW_MODAL_SET,
  QUALITY_SOURCE_EDIT_CHANGE,
  QUALITY_SOURCE_EDIT_MODAL_SET,
  QUALITY_SOURCE_UPDATE,
  QUALITY_SOURCE_UPDATE_SUCCESS,
  QUALITY_SOURCE_UPDATE_ERROR,
  QUALITY_SOURCE_VIEW_TAB_SET
} from '../actions';
 
const INIT_STATE = {
  loading: true,
  list: {
    header:{
      name: 'code',
      order:'sorted-asc'
    },
    page:{
      current: 1,
      rows: 10
    }
  },
  add: {
      data: {
        new:{
          quantity: 0
        }
      },
      modal: false,
      loading: false,
      message: '',
      url: '',
      type: ''
  },
  error: false,
  search:{
    modal: false,
    init:true,
    data:{
      request: false,
      geo_country: false
    },
    value:{
      request: false,
      geo_country: false
    },
    request:{
      loading: true,
      list:[],
      message: ''
    },
    geo_country:{
      loading: true,
      list:[],
      message: ''
    }
  },
  detail: {
    data: [],
    tab: '1',
    loading: true,
  },
  view: {
    loading: true,
    data: [],
    tab: '1',
    modal: false,
    log: {
      data: [],
      loading: false,
    },
  },
  edit: {
    loading: true,
    data: {
        new: [],
        old: []
    },
    list: {
        status: {
            data: [],
            loading: false,
        }
    },
    modal: false,
    update: {
        loading: false,
        response: '',
        message: ''
    }
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case QUALITY_SOURCE_LIST:
      return {
        ...state,
        loading: true,
        list: {
          ...state.list
        }
        
      }
    case QUALITY_SOURCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          ...action.payload
        } 
      }
    case QUALITY_SOURCE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }
    case QUALITY_SOURCE_SEARCH_SET:
      return {
        ...state,
        search:{
          ...state.search,
          data: action.payload,
        }
      }
    case QUALITY_SOURCE_SEARCH_CHANGE:
      return {
        ...state,
        search:{
          ...state.search,
          value: action.payload,
        }
      }
    case QUALITY_SOURCE_PAGE_SET:
      return {
        ...state,
        list: {
          ...state.list,
          page: {
            ...state.list.page,
            ... action.payload,
          }
        },
        log: []
      }

    case QUALITY_SOURCE_SEARCH_REQUEST_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          request:{
            ...state.search.request,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case QUALITY_SOURCE_SEARCH_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          request:{
            ...state.search.request,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case QUALITY_SOURCE_SEARCH_REQUEST_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          request:{
            ...state.search.request,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    case QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          geo_country:{
            ...state.search.geo_country,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          geo_country:{
            ...state.search.geo_country,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case QUALITY_SOURCE_SEARCH_GEO_COUNTRY_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          geo_country:{
            ...state.search.geo_country,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    case QUALITY_SOURCE_ADD_CHANGE:
      return {
        ...state,
        add: {
          ...state.add,
          data: {
            ...state.add.data,
            new: {
              ...state.add.data.new,
              ...action.payload
            }
          }
        }
      }
    case QUALITY_SOURCE_ADD_SET:
      return {
        ...state,
        add: {
          ...state.add,
          data: action.payload
        }
      }
    case QUALITY_SOURCE_ADD_MODAL_SET:
      return {
        ...state,
        add: {
          ...state.add,
          modal: action.payload
        }
      }
    case QUALITY_SOURCE_ADD:
      return {
        ...state,
        add: {
          ...state.add, 
          loading: true
        }
      }
    case QUALITY_SOURCE_ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          modal: false,
          loading: false,
          message: action.payload.message,
          id: action.payload.id
        },
        view: {
          ...state.view,
          modal: false
        }
      }
    case QUALITY_SOURCE_ADD_ERROR:
      return {
        ...state,
        add: {
          ...state.add,
          loading: false,
          message: action.payload.message
        }
      }
    case QUALITY_SOURCE_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          data: []
        }
      }
    case QUALITY_SOURCE_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: action.payload
        },
        edit: {
          ...state.edit,
          loading: false,
          data: {
            new: action.payload,
            old: action.payload
          }
        }
      }
    case QUALITY_SOURCE_DETAIL_ERROR:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: []
        }
      }
    case QUALITY_SOURCE_VIEW_MODAL_SET:
      return {
        ...state,
        view: {
          ...state.view,
          modal: action.payload
        }
      }
    case QUALITY_SOURCE_EDIT_MODAL_SET:
      return {
        ...state,
        edit: {
          ...state.edit,
          modal: action.payload
        }
      }
    case QUALITY_SOURCE_EDIT_CHANGE:
      return {
        ...state,
        edit: {
          ...state.edit,
          data: {
            ...state.edit.data,
            new: {
              ...state.edit.data.new,
              ...action.payload
            }
          }
        }
      }
    case QUALITY_SOURCE_UPDATE:
      return {
        ...state,
        edit: {
          ...state.edit,
          data: {
            ...state.edit.data,
            ...action.payload,
          },
          update: {
            ...state.update,
            loading: true,
            response: '',
            message: ''
          }
        }
      }
    case QUALITY_SOURCE_UPDATE_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          modal: false,
          data: {
            ...state.edit.data,
            new: {
              ...state.edit.data.new,
              update_reason: ''
            },
            old: {
              ...state.edit.data.new,
              update_reason: ''
            }
          },
          update: {
            ...state.update,
            loading: false,
            response: 'success',
            message: action.payload
          }
        },
        view: {
          ...state.view,
          modal: false
        }
      }
    case QUALITY_SOURCE_UPDATE_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          update: {
            ...state.update,
            loading: false,
            response: 'error',
            message: action.payload
          }
        }
      }
    case QUALITY_SOURCE_VIEW_TAB_SET:
      return {
        ...state,
        view: {
          ...state.view,
          tab: action.payload
        }
      }
    default:
      return { ...state };
  }
};