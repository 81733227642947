import { createStructuredSelector } from 'reselect'

import {
  QUALITY_EMAIL_LIST,
  QUALITY_EMAIL_LIST_SUCCESS,
  QUALITY_EMAIL_LIST_ERROR,
  QUALITY_EMAIL_PAGE_SET,
  QUALITY_EMAIL_SEARCH_SET,
  QUALITY_EMAIL_SEARCH_CHANGE,
  QUALITY_EMAIL_SEARCH_REQUEST_LIST,
  QUALITY_EMAIL_SEARCH_REQUEST_LIST_SUCCESS,
  QUALITY_EMAIL_SEARCH_REQUEST_LIST_ERROR,
  QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST,
  QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST_ERROR,
  QUALITY_EMAIL_ADD_SET,
  QUALITY_EMAIL_ADD_MODAL_SET,
  QUALITY_EMAIL_ADD,
  QUALITY_EMAIL_ADD_SUCCESS,
  QUALITY_EMAIL_ADD_ERROR,
  QUALITY_EMAIL_ADD_CHANGE,
  QUALITY_EMAIL_DETAIL,
  QUALITY_EMAIL_DETAIL_SUCCESS,
  QUALITY_EMAIL_DETAIL_ERROR,
  QUALITY_EMAIL_VIEW_MODAL_SET,
  QUALITY_EMAIL_EDIT_CHANGE,
  QUALITY_EMAIL_EDIT_MODAL_SET,
  QUALITY_EMAIL_UPDATE,
  QUALITY_EMAIL_UPDATE_SUCCESS,
  QUALITY_EMAIL_UPDATE_ERROR,
  QUALITY_EMAIL_VIEW_TAB_SET
} from '../actions';

export const NAME = 'quality_email';

export const QualityEmailList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: QUALITY_EMAIL_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const QualityEmailListSuccess = (data) => ({
  type: QUALITY_EMAIL_LIST_SUCCESS,
  payload: data
});

export const QualityEmailListError = (error) => ({
  type: QUALITY_EMAIL_LIST_ERROR,
  payload: error
});

export const QualityEmailPageSet = (data) => ({
  type: QUALITY_EMAIL_PAGE_SET,
  payload: data
});

export const QualityEmailSearchSet = (data) => ({
  type: QUALITY_EMAIL_SEARCH_SET,
  payload: data
});

export const QualityEmailSearchChangeSet = (data) => ({
  type: QUALITY_EMAIL_SEARCH_CHANGE,
  payload: data
});

export const QualityEmailSearchRequestList = (data) => ({
  type: QUALITY_EMAIL_SEARCH_REQUEST_LIST,
  payload: data
});

export const QualityEmailSearchRequestListSuccess = (message) => ({
  type: QUALITY_EMAIL_SEARCH_REQUEST_LIST_SUCCESS,
  payload: message
});

export const QualityEmailSearchRequestListError = (error) => ({
  type: QUALITY_EMAIL_SEARCH_REQUEST_LIST_ERROR,
  payload: error
}); 

export const QualityEmailSearchGeoCountryList = (data) => ({
  type: QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const QualityEmailSearchGeoCountryListSuccess = (message) => ({
  type: QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const QualityEmailSearchGeoCountryListError = (error) => ({
  type: QUALITY_EMAIL_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 

export const QualityEmailAddChangeSet = (data) => ({
  type: QUALITY_EMAIL_ADD_CHANGE,
  payload: data
});

export const QualityEmailAddSet = (data) => ({
  type: QUALITY_EMAIL_ADD_SET,
  payload: data
});

export const QualityEmailAddModalSet = (data) => ({
  type: QUALITY_EMAIL_ADD_MODAL_SET,
  payload: data
});

export const QualityEmailAdd = (data) => ({
  type: QUALITY_EMAIL_ADD,
  payload: data
});

export const QualityEmailAddSuccess = (message) => ({
  type: QUALITY_EMAIL_ADD_SUCCESS,
  payload: message
});

export const QualityEmailAddError = (error) => ({
  type: QUALITY_EMAIL_ADD_ERROR,
  payload: error
});

export const QualityEmailDetail = (data) => ({
  type: QUALITY_EMAIL_DETAIL,
  payload: data
});

export const QualityEmailDetailSuccess = (data) => ({
  type: QUALITY_EMAIL_DETAIL_SUCCESS,
  payload: data
});

export const QualityEmailDetailError = (error) => ({
  type: QUALITY_EMAIL_DETAIL_ERROR,
  payload: error
});

export const QualityEmailViewModalSet = (data) => ({
  type: QUALITY_EMAIL_VIEW_MODAL_SET,
  payload: data
});

export const QualityEmailEditModalSet = (data) => ({
  type: QUALITY_EMAIL_EDIT_MODAL_SET,
  payload: data
});

export const QualityEmailEditChangeSet = (data) => ({
  type: QUALITY_EMAIL_EDIT_CHANGE,
  payload: data
});

export const QualityEmailUpdate = (data) => ({
  type: QUALITY_EMAIL_UPDATE,
  payload: data
});

export const QualityEmailUpdateSuccess = (message) => ({
  type: QUALITY_EMAIL_UPDATE_SUCCESS,
  payload: message.message
});

export const QualityEmailUpdateError = (error) => ({
  type: QUALITY_EMAIL_UPDATE_ERROR,
  payload: error
});

export const QualityEmailViewTabSet = (data) => ({
  type: QUALITY_EMAIL_VIEW_TAB_SET,
  payload: data
});

const quality_email = (state) => state[NAME];

export const QualityEmailSelector = createStructuredSelector({
  quality_email
})
