import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import {
    DASHBOARD_LIST,
    ARTICLE_CODE22,
    DASHBOARD_PRICES,
    DASHBOARD_UNIT_PRICE,
    DASHBOARD_SOURCE,
    DASHBOARD_YEAR,
    DASHBOARD_TRANSACTIONS,
    DASHBOARD_RAZONABILIDAD,
    DASHBOARD_DATA_SUPPLIER
} from '../actions';

import {
    DashboardListSuccess,
    DashboardListError,
    ArticleCode22Success,
    ArticleCode22Error,
    DashboardUnitPriceSuccess,
    DashboardUnitPriceError,
    DashboardSourceSuccess,
    DashboardSourceError,
    DashboardYearSuccess,
    DashboardYearError,
    DashboardPricesSuccess,
    DashboardPricesError,
    DashboardTransactionsSuccess,
    DashboardTransactionsError,
    DashboardRazonabilidadSuccess,
    DashboardRazonabilidadError,
    DashboardDataSupplierSuccess,
    DashboardDataSupplierError
} from '../actions';

export function* watchDashboardList() {
    yield takeEvery(DASHBOARD_LIST, onDashboardList);
}

export function* watchArticleCode22() {
    yield takeEvery(ARTICLE_CODE22, onArticleCode22);
}

export function* watchDashboardUnitPrice() {
    yield takeEvery(DASHBOARD_UNIT_PRICE, onDashboardUnitPrice);
}

export function* watchDashboardSource() {
    yield takeEvery(DASHBOARD_SOURCE, onDashboardSource);
}

export function* watchDashboardYear() {
    yield takeEvery(DASHBOARD_YEAR, onDashboardYear);
}

export function* watchDashboardPrices() {
    yield takeEvery(DASHBOARD_PRICES, onDashboardPrices);
}

export function* watchDashboardTransactions() {
    yield takeEvery(DASHBOARD_TRANSACTIONS, onDashboardTransactions);
}

export function* watchDashboardRazonabilidad() {
    yield takeEvery(DASHBOARD_RAZONABILIDAD, onDashboardRazonabilidad);
}

export function* watchDashboardDataSupplier() {
    yield takeEvery(DASHBOARD_DATA_SUPPLIER, onDashboardDataSupplier);
}

function getToken(auth) {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            user.getIdToken().then((token) => {
                unsubscribe();
                resolve(token);
            });
        }, reject);
    });
}

const onDashboardListAsync = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'dashboard/list', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

const onArticleCode22Async = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'dashboard/article', { 
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

const onDashboardUnitPriceAsync = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'dashboard/unitprice', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

const onDashboardSourceAsync = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'dashboard/source', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

const onDashboardYearAsync = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'dashboard/year', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

const onDashboardPricesAsync = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'dashboard/prices', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

const onDashboardTransactionsAsync = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'dashboard/transactions', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

const onDashboardRazonabilidadAsync = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'dashboard/razonabilidad', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

const onDashboardDataSupplierAsync = async (payload) => {
    let token = await getToken(authFB);
    const response = await fetch(servicePath + 'dashboard/datasupplier', {
        method: 'POST', mode: 'cors', cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'token': token },
        body: JSON.stringify(payload)
    })
        .then(response => response.json())
        .then(data => { return data; })
        .catch(err => console.log(err));
    return { data: response };
};

function* onDashboardList(data) {
    try {
        const response = yield call(onDashboardListAsync, data.payload);
        yield put(DashboardListSuccess(response.data));
    } catch (error) {
        yield put(DashboardListError(error));
    }
}


function* onArticleCode22(data) {
    try {
        const response = yield call(onArticleCode22Async, data.payload);
        yield put(ArticleCode22Success(response.data));
    } catch (error) {
        yield put(ArticleCode22Error(error));
    }
}

function* onDashboardUnitPrice(data) {
    try {
        const response = yield call(onDashboardUnitPriceAsync, data.payload);
        yield put(DashboardUnitPriceSuccess(response.data));
    } catch (error) {
        yield put(DashboardUnitPriceError(error));
    }
}

function* onDashboardSource(data) {
    try {
        const response = yield call(onDashboardSourceAsync, data.payload);
        yield put(DashboardSourceSuccess(response.data));
    } catch (error) {
        yield put(DashboardSourceError(error));
    }
}

function* onDashboardYear(data) {
    try {
        const response = yield call(onDashboardYearAsync, data.payload);
        yield put(DashboardYearSuccess(response.data));
    } catch (error) {
        yield put(DashboardYearError(error));
    }
}

function* onDashboardPrices(data) {
    try {
        const response = yield call(onDashboardPricesAsync, data.payload);
        yield put(DashboardPricesSuccess(response.data));
    } catch (error) {
        yield put(DashboardPricesError(error));
    }
}

function* onDashboardTransactions(data) {
    try {
        const response = yield call(onDashboardTransactionsAsync, data.payload);
        yield put(DashboardTransactionsSuccess(response.data));
    } catch (error) {
        yield put(DashboardTransactionsError(error));
    }
}

function* onDashboardRazonabilidad(data) {
    try {
        const response = yield call(onDashboardRazonabilidadAsync, data.payload);
        yield put(DashboardRazonabilidadSuccess(response.data));
    } catch (error) {
        yield put(DashboardRazonabilidadError(error));
    }
}

function* onDashboardDataSupplier(data) {
    try {
        const response = yield call(onDashboardDataSupplierAsync, data.payload);
        yield put(DashboardDataSupplierSuccess(response.data));
    } catch (error) {
        yield put(DashboardDataSupplierError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchDashboardList),
        fork(watchArticleCode22),
        fork(watchDashboardPrices),
        fork(watchDashboardTransactions),
        fork(watchDashboardRazonabilidad),
        fork(watchDashboardUnitPrice),
        fork(watchDashboardSource),
        fork(watchDashboardYear),
        fork(watchDashboardDataSupplier)
    ]);
}
