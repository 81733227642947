import {
    USER_ACTIONS_LIST,
    USER_ACTIONS_LIST_SUCCESS,
    USER_ACTIONS_LIST_ERROR
} from '../actions';

const INIT_STATE = {
    loading: true,
    actions: {
        data: {actions: 0, user:{iduser_type: 2}},
        loading: true,
        message: ''
    },
    error: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_ACTIONS_LIST:
            return {
                ...state,
                actions: {
                    ...state.actions
                }
            }
        case USER_ACTIONS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                actions: {
                    ...state.actions,
                    ...action.payload
                }
            }
        case USER_ACTIONS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }

        default:
            return { ...state };
    }
};