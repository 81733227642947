import {
  PROCESS_LIST, 
  PROCESS_LIST_SUCCESS,
  PROCESS_LIST_ERROR,
  PROCESS_SEARCH_SET,
  PROCESS_SEARCH_CHANGE, 
  PROCESS_PAGE_SET,
  PROCESS_SEARCH_PROCESS_USER_LIST,
  PROCESS_SEARCH_PROCESS_USER_LIST_SUCCESS,
  PROCESS_SEARCH_PROCESS_USER_LIST_ERROR,
  PROCESS_SEARCH_PROCESS_PROCESS_LIST,
  PROCESS_SEARCH_PROCESS_PROCESS_LIST_SUCCESS,
  PROCESS_SEARCH_PROCESS_PROCESS_LIST_ERROR,
  PROCESS_SEARCH_GEO_COUNTRY_LIST,
  PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR,
  PROCESS_ADD_SET,
  PROCESS_ADD_MODAL_SET,
  PROCESS_ADD,
  PROCESS_ADD_SUCCESS,
  PROCESS_ADD_ERROR,
  PROCESS_ADD_CHANGE,
  PROCESS_DETAIL,
  PROCESS_DETAIL_SUCCESS,
  PROCESS_DETAIL_ERROR,
  PROCESS_VIEW_MODAL_SET,
  PROCESS_EDIT_CHANGE,
  PROCESS_EDIT_MODAL_SET,
  PROCESS_UPDATE,
  PROCESS_UPDATE_SUCCESS,
  PROCESS_UPDATE_ERROR,
  PROCESS_VIEW_TAB_SET
} from '../actions';
 
const INIT_STATE = {
  loading: true,
  list: {
    header:{
      name: 'code',
      order:'sorted-asc'
    },
    page:{
      current: 1,
      rows: 10
    }
  },
  add: {
      data: {
        new:{
          quantity: 0
        }
      },
      modal: false,
      loading: false,
      message: '',
      url: '',
      type: ''
  },
  error: false,
  search:{
    modal: false,
    init:true,
    data:{
      process_user: false,
      process_process: false,
      geo_country: false
    },
    value:{
      process_user: false,
      process_process: false,
      geo_country: false
    },
    process_user:{
      loading: true,
      list:[],
      message: ''
    },
    process_process:{
      loading: true,
      list:[],
      message: ''
    },
    geo_country:{
      loading: true,
      list:[],
      message: ''
    }
  },
  detail: {
    data: [],
    tab: '1',
    loading: true,
  },
  view: {
    loading: true,
    data: [],
    tab: '1',
    modal: false,
    log: {
      data: [],
      loading: false,
    },
  },
  edit: {
    loading: true,
    data: {
        new: [],
        old: []
    },
    list: {
        status: {
            data: [],
            loading: false,
        }
    },
    modal: false,
    update: {
        loading: false,
        response: '',
        message: ''
    }
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROCESS_LIST:
      return {
        ...state,
        loading: true,
        list: {
          ...state.list
        }
        
      }
    case PROCESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          ...action.payload
        } 
      }
    case PROCESS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }
    case PROCESS_SEARCH_SET:
      return {
        ...state,
        search:{
          ...state.search,
          data: action.payload,
        }
      }
    case PROCESS_SEARCH_CHANGE:
      return {
        ...state,
        search:{
          ...state.search,
          value: action.payload,
        }
      }
    case PROCESS_PAGE_SET:
      return {
        ...state,
        list: {
          ...state.list,
          page: {
            ...state.list.page,
            ... action.payload,
          }
        },
        log: []
      }

    case PROCESS_SEARCH_PROCESS_USER_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          process_user:{
            ...state.search.process_user,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_USER_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          process_user:{
            ...state.search.process_user,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_USER_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          process_user:{
            ...state.search.process_user,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_PROCESS_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          process_process:{
            ...state.search.process_process,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          process_process:{
            ...state.search.process_process,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_PROCESS_PROCESS_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          process_process:{
            ...state.search.process_process,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }

    case PROCESS_SEARCH_GEO_COUNTRY_LIST:
      return {
        ...state,
        search:{
          ...state.search,
          init:false,
          geo_country:{
            ...state.search.geo_country,
            loading: true,
            list:[],
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_GEO_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        search:{
          ...state.search,
          geo_country:{
            ...state.search.geo_country,
            loading: false,
            list: action.payload,
            message: ''
          }
        }
      }
    case PROCESS_SEARCH_GEO_COUNTRY_LIST_ERROR:
      return {
        ...state,
        search:{
          ...state.search,
          geo_country:{
            ...state.search.geo_country,
            loading: false,
            list:[],
            message: 'No se encotraron datos'
          }
        }
      }
    case PROCESS_ADD_CHANGE:
      return {
        ...state,
        add: {
          ...state.add,
          data: {
            ...state.add.data,
            new: {
              ...state.add.data.new,
              ...action.payload
            }
          }
        }
      }
    case PROCESS_ADD_SET:
      return {
        ...state,
        add: {
          ...state.add,
          data: action.payload
        }
      }
    case PROCESS_ADD_MODAL_SET:
      return {
        ...state,
        add: {
          ...state.add,
          modal: action.payload
        }
      }
    case PROCESS_ADD:
      return {
        ...state,
        add: {
          ...state.add, 
          loading: true
        }
      }
    case PROCESS_ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          modal: false,
          loading: false,
          message: action.payload.message,
          id: action.payload.id
        }
      }
    case PROCESS_ADD_ERROR:
      return {
        ...state,
        add: {
          ...state.add,
          loading: false,
          message: action.payload.message
        }
      }
    case PROCESS_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          data: []
        }
      }
    case PROCESS_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: {...action.payload.process, process_geo_country: action.payload.geo, process_experience: action.payload.experience}
        },
        edit: {
          ...state.edit,
          loading: false,
          data: {
            new: {...action.payload.process, process_geo_country: action.payload.geo, process_experience: action.payload.experience},
            old: {...action.payload.process, process_geo_country: action.payload.geo, process_experience: action.payload.experience}
          }
        }
      }
    case PROCESS_DETAIL_ERROR:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: []
        }
      }
    case PROCESS_VIEW_MODAL_SET:
      return {
        ...state,
        view: {
          ...state.view,
          modal: action.payload
        }
      }
    case PROCESS_EDIT_MODAL_SET:
      return {
        ...state,
        edit: {
          ...state.edit,
          modal: action.payload
        }
      }
    case PROCESS_EDIT_CHANGE:
      return {
        ...state,
        edit: {
          ...state.edit,
          data: {
            ...state.edit.data,
            new: {
              ...state.edit.data.new,
              ...action.payload
            }
          }
        }
      }
    case PROCESS_UPDATE:
      return {
        ...state,
        edit: {
          ...state.edit,
          data: {
            ...state.edit.data,
            ...action.payload,
          },
          update: {
            ...state.update,
            loading: true,
            response: '',
            message: ''
          }
        }
      }
    case PROCESS_UPDATE_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          modal: false,
          data: {
            ...state.edit.data,
            new: {
              ...state.edit.data.new,
              update_reason: ''
            },
            old: {
              ...state.edit.data.new,
              update_reason: ''
            }
          },
          update: {
            ...state.update,
            loading: false,
            response: 'success',
            message: action.payload
          }
        }
      }
    case PROCESS_UPDATE_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          update: {
            ...state.update,
            loading: false,
            response: 'error',
            message: action.payload
          }
        }
      }
    case PROCESS_VIEW_TAB_SET:
      return {
        ...state,
        view: {
          ...state.view,
          tab: action.payload
        }
      }
    default:
      return { ...state };
  }
};