import { createStructuredSelector } from 'reselect'

import {
  REQUEST_DETAIL_LIST,
  REQUEST_DETAIL_LIST_SUCCESS,
  REQUEST_DETAIL_LIST_ERROR,
  REQUEST_DETAIL_PAGE_SET,
  REQUEST_DETAIL_SEARCH_SET,
  REQUEST_DETAIL_SEARCH_CHANGE,
  REQUEST_DETAIL_SEARCH_ARTICLE_LIST,
  REQUEST_DETAIL_SEARCH_ARTICLE_LIST_SUCCESS,
  REQUEST_DETAIL_SEARCH_ARTICLE_LIST_ERROR,
  REQUEST_DETAIL_SEARCH_REQUEST_LIST,
  REQUEST_DETAIL_SEARCH_REQUEST_LIST_SUCCESS,
  REQUEST_DETAIL_SEARCH_REQUEST_LIST_ERROR,
  REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_DETAIL_SEARCH_GEO_REGION_LIST,
  REQUEST_DETAIL_SEARCH_GEO_REGION_LIST_SUCCESS,
  REQUEST_DETAIL_SEARCH_GEO_REGION_LIST_ERROR,
  REQUEST_DETAIL_ADD_SET,
  REQUEST_DETAIL_ADD_MODAL_SET,
  REQUEST_DETAIL_ADD,
  REQUEST_DETAIL_ADD_SUCCESS,
  REQUEST_DETAIL_ADD_ERROR,
  REQUEST_DETAIL_ADD_CHANGE,
  REQUEST_DETAIL_DETAIL,
  REQUEST_DETAIL_DETAIL_SUCCESS,
  REQUEST_DETAIL_DETAIL_ERROR,
  REQUEST_DETAIL_VIEW_MODAL_SET,
  REQUEST_DETAIL_EDIT_CHANGE,
  REQUEST_DETAIL_EDIT_MODAL_SET,
  REQUEST_DETAIL_UPDATE,
  REQUEST_DETAIL_UPDATE_SUCCESS,
  REQUEST_DETAIL_UPDATE_ERROR,
  REQUEST_DETAIL_VIEW_TAB_SET,
  REQUEST_DETAIL_SEARCH_ARTICLE_SET
} from '../actions';

export const NAME = 'request_detail';

export const RequestDetailList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_DETAIL_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestDetailListSuccess = (data) => ({
  type: REQUEST_DETAIL_LIST_SUCCESS,
  payload: data
});

export const RequestDetailListError = (error) => ({
  type: REQUEST_DETAIL_LIST_ERROR,
  payload: error
});

export const RequestDetailSearchArticleSet = (data) => ({
  type: REQUEST_DETAIL_SEARCH_ARTICLE_SET,
  payload: data
});

export const RequestDetailPageSet = (data) => ({
  type: REQUEST_DETAIL_PAGE_SET,
  payload: data
});

export const RequestDetailSearchSet = (data) => ({
  type: REQUEST_DETAIL_SEARCH_SET,
  payload: data
});

export const RequestDetailSearchChangeSet = (data) => ({
  type: REQUEST_DETAIL_SEARCH_CHANGE,
  payload: data
});

export const RequestDetailSearchArticleList = (data) => ({
  type: REQUEST_DETAIL_SEARCH_ARTICLE_LIST,
  payload: data
});

export const RequestDetailSearchArticleListSuccess = (message) => ({
  type: REQUEST_DETAIL_SEARCH_ARTICLE_LIST_SUCCESS,
  payload: message
});

export const RequestDetailSearchArticleListError = (error) => ({
  type: REQUEST_DETAIL_SEARCH_ARTICLE_LIST_ERROR,
  payload: error
}); 

export const RequestDetailSearchRequestList = (data) => ({
  type: REQUEST_DETAIL_SEARCH_REQUEST_LIST,
  payload: data
});

export const RequestDetailSearchRequestListSuccess = (message) => ({
  type: REQUEST_DETAIL_SEARCH_REQUEST_LIST_SUCCESS,
  payload: message
});

export const RequestDetailSearchRequestListError = (error) => ({
  type: REQUEST_DETAIL_SEARCH_REQUEST_LIST_ERROR,
  payload: error
}); 

export const RequestDetailSearchGeoCountryList = (data) => ({
  type: REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestDetailSearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailSearchGeoCountryListError = (error) => ({
  type: REQUEST_DETAIL_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
}); 
export const RequestDetailSearchGeoRegionList = (data) => ({
  type: REQUEST_DETAIL_SEARCH_GEO_REGION_LIST,
  payload: data
});

export const RequestDetailSearchGeoRegionListSuccess = (message) => ({
  type: REQUEST_DETAIL_SEARCH_GEO_REGION_LIST_SUCCESS,
  payload: message
});

export const RequestDetailSearchGeoRegionListError = (error) => ({
  type: REQUEST_DETAIL_SEARCH_GEO_REGION_LIST_ERROR,
  payload: error
}); 


export const RequestDetailAddChangeSet = (data) => ({
  type: REQUEST_DETAIL_ADD_CHANGE,
  payload: data
});

export const RequestDetailAddSet = (data) => ({
  type: REQUEST_DETAIL_ADD_SET,
  payload: data
});

export const RequestDetailAddModalSet = (data) => ({
  type: REQUEST_DETAIL_ADD_MODAL_SET,
  payload: data
});

export const RequestDetailAdd = (data) => ({
  type: REQUEST_DETAIL_ADD,
  payload: data
});

export const RequestDetailAddSuccess = (message) => ({
  type: REQUEST_DETAIL_ADD_SUCCESS,
  payload: message
});

export const RequestDetailAddError = (error) => ({
  type: REQUEST_DETAIL_ADD_ERROR,
  payload: error
});

export const RequestDetailDetail = (data) => ({
  type: REQUEST_DETAIL_DETAIL,
  payload: data
});

export const RequestDetailDetailSuccess = (data) => ({
  type: REQUEST_DETAIL_DETAIL_SUCCESS,
  payload: data
});

export const RequestDetailDetailError = (error) => ({
  type: REQUEST_DETAIL_DETAIL_ERROR,
  payload: error
});

export const RequestDetailViewModalSet = (data) => ({
  type: REQUEST_DETAIL_VIEW_MODAL_SET,
  payload: data
});

export const RequestDetailEditModalSet = (data) => ({
  type: REQUEST_DETAIL_EDIT_MODAL_SET,
  payload: data
});

export const RequestDetailEditChangeSet = (data) => ({
  type: REQUEST_DETAIL_EDIT_CHANGE,
  payload: data
});

export const RequestDetailUpdate = (data) => ({
  type: REQUEST_DETAIL_UPDATE,
  payload: data
});

export const RequestDetailUpdateSuccess = (message) => ({
  type: REQUEST_DETAIL_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestDetailUpdateError = (error) => ({
  type: REQUEST_DETAIL_UPDATE_ERROR,
  payload: error
});

export const RequestDetailViewTabSet = (data) => ({
  type: REQUEST_DETAIL_VIEW_TAB_SET,
  payload: data
});

const request_detail = (state) => state[NAME];

export const RequestDetailSelector = createStructuredSelector({
  request_detail
})
